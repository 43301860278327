import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TeamItemCard } from 'components';

import { theme } from 'theme';
import Box from '@material-ui/core/Box';

import { generateKey } from 'shared/functions/generateKey';

const TeamItem = ({ teamItemData, cardWidth }) => {
  const { t } = useTranslation();

  return (
    <Box
      bgcolor={theme.palette.common.lightGrayishViolet}
      mb={12}
      display="flex"
      justifyContent="center"
    >
      {teamItemData.map(({ image, name, title, phone, email, linkedin }, index) => (
        <TeamItemCard
          key={generateKey(index, 'team_item_card')}
          image={image}
          name={name}
          phone={phone}
          title={t(title)}
          email={email}
          linkedin={linkedin}
          width={cardWidth}
        />
      ))}
    </Box>
  );
};

TeamItem.defaultProps = {
  cardWidth: undefined,
};

TeamItem.propTypes = {
  teamItemData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string,
      linkedin: PropTypes.string,
    }),
  ).isRequired,
  cardWidth: PropTypes.number,
};

export { TeamItem };

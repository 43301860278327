import React, { ReactElement } from 'react';
import { theme } from 'theme';

export const PlusIcon = ({
  width = 12,
  height = 12,
  fill = theme.palette.common.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.32812 1.32812V10.6719C5.33854 10.8594 5.40625 11.0156 5.53125 11.1406C5.65625 11.2656 5.8125 11.3281 6 11.3281C6.1875 11.3281 6.34375 11.2656 6.46875 11.1406C6.59375 11.0156 6.66146 10.8594 6.67188 10.6719V1.32812C6.66146 1.14062 6.59375 0.984375 6.46875 0.859375C6.34375 0.734375 6.1875 0.671875 6 0.671875C5.8125 0.671875 5.65625 0.734375 5.53125 0.859375C5.40625 0.984375 5.33854 1.14062 5.32812 1.32812ZM1.32812 6.67188H10.6719C10.8594 6.66146 11.0156 6.59375 11.1406 6.46875C11.2656 6.34375 11.3281 6.1875 11.3281 6C11.3281 5.8125 11.2656 5.65625 11.1406 5.53125C11.0156 5.40625 10.8594 5.33854 10.6719 5.32812H1.32812C1.14062 5.33854 0.984375 5.40625 0.859375 5.53125C0.734375 5.65625 0.671875 5.8125 0.671875 6C0.671875 6.1875 0.734375 6.34375 0.859375 6.46875C0.984375 6.59375 1.14062 6.66146 1.32812 6.67188Z"
        fill={fill}
      />
    </svg>
  );
};

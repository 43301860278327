export const ShipmentItemType = Object.freeze({
  Pallet: 'Pallet',
  Box: 'Box',
  Truck: 'Truck',
  Other: 'Other',
  Container: 'Container',
});

export const ShipmentItemSubType = Object.freeze({
  EurPallet: 1,
  FinPallet: 2,
  Pallet: 3,
  Package: 7,
  FullTruckLoad: 10,
  LongPallet: 11,
  HalfPallet: 12,
  LDM: 13,
  Container20ftDC: 14,
  Container40ftDC: 15,
  Container40ftHC: 16,
  ContainerOther: 17,
});

export const defaultLengths = {
  [ShipmentItemSubType.EurPallet]: 120,
  [ShipmentItemSubType.FinPallet]: 120,
  [ShipmentItemSubType.LongPallet]: 240,
  [ShipmentItemSubType.HalfPallet]: 80,
  [ShipmentItemSubType.Container20ftDC]: 589.5,
  [ShipmentItemSubType.Container40ftHC]: 1202.4,
  [ShipmentItemSubType.Container40ftDC]: 1202.9,
};

export const defaultWidths = {
  [ShipmentItemSubType.EurPallet]: 80,
  [ShipmentItemSubType.FinPallet]: 100,
  [ShipmentItemSubType.LongPallet]: 80,
  [ShipmentItemSubType.HalfPallet]: 60,
  [ShipmentItemSubType.Container20ftDC]: 235,
  [ShipmentItemSubType.Container40ftHC]: 235,
  [ShipmentItemSubType.Container40ftDC]: 235,
};

export const defaultHeights = {
  [ShipmentItemSubType.Container20ftDC]: 239.2,
  [ShipmentItemSubType.Container40ftHC]: 269.7,
  [ShipmentItemSubType.Container40ftDC]: 239.2,
};

export const volumeIsEditable = {
  [ShipmentItemSubType.Container20ftDC]: true,
  [ShipmentItemSubType.Container40ftHC]: true,
  [ShipmentItemSubType.Container40ftDC]: true,
  [ShipmentItemSubType.ContainerOther]: true,
};

export const shipmentItemSubTypes = Object.keys(ShipmentItemSubType)
  .map((x) => parseInt(x, 10))
  .filter((x) => !Number.isNaN(x));

export const uploadShipmentFilesSupportedExt = [
  '.jpg',
  '.jpeg',
  '.png',
  '.tif',
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ods',
  '.rtf',
  '.txt',
];

import React, { lazy } from 'react';

const Main = lazy(() => import('./scenes/Main/Main'));
const NotFound = lazy(() => import('./scenes/NotFound/NotFound'));
//const Blog = lazy(() => import('./scenes/Blog/Blog'));
const Team = lazy(() => import('./scenes/Team/Team'));
const Careers = lazy(() => import('./scenes/Careers/Careers'));
const Offices = lazy(() => import('./scenes/Offices/Offices'));
const CookieNotice = lazy(() => import('./scenes/CookieNotice'));
const Order = lazy(() => import('./scenes/Order/Order'));
const Transport = lazy(() => import('./scenes/Transport/Transport'));

const routes = [
  {
    name: 'main',
    path: [
      '/',
      '/hero',
      '/why-laasone',
      '/our-offer',
      '/how-it-works',
      '/case-studies',
      '/demo-request',
      '/latest-posts',
    ],
    exact: true,
    component: ({ props, viewLoadedCallback }) => (
      <Main viewLoadedCallback={viewLoadedCallback} {...props} />
    ),
  },
  /*{
    name: 'blog',
    path: '/blog',
    exact: true,
    component: Blog,
  },
  {
    name: 'blog-post',
    path: '/blog/:postSlug',
    exact: true,
    component: Blog,
  },*/
  {
    name: 'our-people',
    path: '/our-people',
    exact: true,
    component: Team,
  },
  {
    name: 'careers',
    path: '/careers',
    exact: true,
    component: Careers,
  },
  {
    name: 'offices',
    path: '/offices',
    exact: true,
    component: Offices,
  },
  {
    name: 'order',
    path: '/order',
    exact: true,
    component: Order,
  },
  {
    name: 'transport',
    path: '/transport',
    exact: true,
    component: Transport,
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: NotFound,
  },
  {
    name: 'cookie-notice',
    path: '/cookie-notice',
    component: CookieNotice,
  },
];

export { routes };

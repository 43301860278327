const dimensionsRegExp = /^\d+\.?,?(\d+)?$/i;
const temperatureRegExp = /^([-?(\d+)?]){0,4}$/i;
// TODO: unify, phoneRegExp is already used for Formik validation but does not work properly on live (onChange) validation
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
const simplePhoneRegExp = /^(\+?\d*)[-? \d]*$/;
const nameRegExp = /^[\p{L} ,.'-]+$/u;
const emailRegExp = /^\S*@?\S+$/;
const cityRegExp = /^[A-zÀ-ž ]+$/;
const countPrefix = /^\([0-9]+\)/; // matches '{(number)} anything'

export {
  dimensionsRegExp,
  temperatureRegExp,
  phoneRegExp,
  simplePhoneRegExp,
  nameRegExp,
  emailRegExp,
  cityRegExp,
  countPrefix,
};

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { formStates } from 'shared/constants/formStates';
import * as Yup from 'yup';
import { applyForPrices } from 'shared/services';
import OrderFormFields from './OrderFormFields';
import { Loader } from '../Loader/Loader';
import { OrderQuotationModal } from './OrderQuotationModal';

const useStyles = makeStyles((theme) => ({
  rest: {
    padding: theme.spacing(12, 8, 8, 8),
  },
  label: {
    position: 'relative',
  },
  wrapper: {
    borderRadius: 15,
    backgroundColor: '#2A3150',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4, 12, 6, 12),
    position: 'relative',
  },
  heading: {
    marginTop: theme.spacing(8),
    color: '#FFFFFF',
    paddingLeft: theme.spacing(4),
    textTransform: 'uppercase',
  },
}));

const initialValues = {
  from: '',
  fromPlaceId: '',
  to: '',
  toPlaceId: '',
  weight: '',
  amount: 1,
  type: '7',
  length: '',
  width: '',
  height: '',
  fromCountry: '',
  toCountry: '',
  fromZip: '',
  toZip: '',
};

const OrderForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [offeredPrices, setOfferedPrices] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [showQuotationModal, setShowQuotationModal] = useState(false);

  const validationSchema = Yup.object().shape({
    fromZip: Yup.string().min(2, t('INVALID_NAME')).required(t('VALIDATION.POSTCODE_IS_REQUIRED')),
    toZip: Yup.string().min(2, t('INVALID_NAME')).required(t('VALIDATION.POSTCODE_IS_REQUIRED')),
    length: Yup.string().min(2, t('INVALID_MEASURE')).required(),
    width: Yup.string().min(2, t('INVALID_MEASURE')).required(),
    height: Yup.string().min(1, t('INVALID_MEASURE')).required(),
    type: Yup.string().min(1, t('INVALID_TYPE')).required(),
    weight: Yup.string().required(),
  });

  const handleSubmit = (formData, { setSubmitting }) => {
    setIsPending(true);
    applyForPrices(formData)
      .then((response) => {
        setFormState(response.ok ? formStates.SUCCESS : formStates.ERROR);
        return response.json();
      })
      .then((data) => {
        if (data?.payload?.offeredPrices) {
          setOfferedPrices(data?.payload?.offeredPrices);
        } else {
          setOfferedPrices([]);
        }
      })
      .catch(() => {
        setFormValues(initialValues);
        setFormState(formStates.ERROR);
      })
      .finally(() => {
        setFormValues(formData);
        setIsFormSubmitted(true);
        setSubmitting(false);
        setIsPending(false);
      });
  };

  return (
    <>
      {showQuotationModal && (
        <OrderQuotationModal
          formValues={formValues}
          handleClose={() => setShowQuotationModal(false)}
          open={showQuotationModal}
        />
      )}
      <Typography variant="h5" className={classes.heading}>
        {t('ORDER_FORM.TITLE')}
      </Typography>
      <Box className={classes.wrapper}>
        {isPending && <Loader cover />}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              {formState === formStates.ERROR && (
                <Typography variant="h5">{t('FORM_SOMETHING_WENT_WRONG')}</Typography>
              )}
              <OrderFormFields
                setShowQuotationModal={setShowQuotationModal}
                setIsFormSubmitted={setIsFormSubmitted}
                isFormSubmitted={isFormSubmitted}
                offeredPrices={offeredPrices}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                values={values}
                isPending={isPending}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

OrderForm.defaultProps = {};

OrderForm.propTypes = {};

export { OrderForm };

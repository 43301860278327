import { API_URL } from '../constants/apiUrl';

const URL = API_URL;

export const applyPriceQuotation = ({
  from,
  to,
  weight,
  amount,
  type,
  length,
  width,
  height,
  fromCountry,
  toCountry,
  fromZip,
  toZip,
  name,
  email,
  phone,
  companyName,
  language,
}) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify({
      from,
      to,
      weight,
      amount,
      type,
      length,
      width,
      height,
      fromCountry,
      toCountry,
      fromZip,
      toZip,
      name,
      email,
      phone,
      companyName,
      language,
    }),
  };

  return fetch(`${URL}/api/prices/public-quotation`, params);
};

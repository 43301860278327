import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';

import { generateKey } from 'shared/functions/generateKey';
import { useTranslation } from 'react-i18next';

import { BulletPoint, Index } from 'components';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
  },
  description: {
    marginLeft: theme.spacing(4),
    lineHeight: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.875rem',
    },
  },
}));

const OfferItem = ({ index, title, descriptions, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={className}>
      <Box display="flex" alignItems="center" flexDirection={isSmDown ? 'column' : 'row'}>
        <Index value={index} />
        <Typography variant="h5" className={classes.title}>
          {t(title)}
        </Typography>
      </Box>
      <Box marginLeft={isSmDown ? 0 : 26.5}>
        {descriptions.map((description, i) => (
          <Box
            key={generateKey(index, `offer_description_${i}`)}
            display="flex"
            alignItems="flex-start"
          >
            <BulletPoint />
            <Typography variant="body1" className={classes.description}>
              {t(description)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

OfferItem.defaultProps = {
  className: undefined,
};

OfferItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export { OfferItem };

export default {
  WHY_LAASONE: 'Digitalisoi logistiikkasi',
  OUR_OFFER: 'Me tarjoamme',
  DATEPICKER_PLACEHOLDER: 'dd.mm.yyyy',
  HOW_IT_WORKS: 'Kuinka se toimii?',
  CASE_STUDIES: 'Asiakkaamme suosittelevat',
  OUR_PEOPLE: 'Meidän tiimimme',
  CONTACT: 'Ota yhteyttä',
  CAREERS: 'Töihin meille',
  BLOG: 'Blogi',
  WATCH_HOW_IT_WORKS: 'Katso, kuinka se toimii',
  BOOK_A_DEMO: 'Varaa demo',
  BOOK_A_DEMO_DESC:
    'Varaa esittely, jotta näet, kuinka LaasOne-alusta voi auttaa sinua hallitsemaan logistiikkaa ja kuljetuksia.',
  HERO_TITLE: 'Sinun digitaalinen logistiikkaosasto',
  HERO_DESC:
    'LaasOne automatisoi pienten ja keskisuurten teollisuusyritysten logistiikan hallinnan ja hinnoittelun.',
  READ_MORE: 'Lue lisää',
  IMPROVED_EFFICIENCY: 'Tehokkaampi',
  IMPROVED_EFFICIENCY_DESC:
    'LaasOne automatisoi logistiikan hinnoittelun ja \nhallinnan, mikä vähentää aikaa, jonka tiimisi \nkuluttaa logistiikkaan.',
  LOWER_TRANSPORT_COST: 'Asiakkaista säästää',
  LOWER_TRANSPORT_COST_DESC:
    'Meillä on laajat volyymisopimukset rahdinkuljettajien kanssa, keskimäärin 90% asiakkaistamme säästää logistiikkakuluissaan avullamme!',
  SUCCESSFUL_SHIPMENTS: 'Onnistunutta lähetystä',
  SUCCESSFUL_SHIPMENTS_DESC:
    'LaasOne neuvottelee kuljetusyritysten kanssa \npuolestasi ja hoitaa kaiken toimitukseen liittyvän',
  FAST_PRICES_TITLE: 'Nopeat hintapyynnöt eri toimittajilta',
  FAST_PRICES_1: 'Miten se toimii?',
  FAST_PRICES_1_DESC:
    'Syötä kuljetustarpeesi online-alustallemme, lähetämme sinulle tarjouksen ja vahvistuksesi jälkeen tilaus toteutetaan.',
  FAST_PRICES_2: 'Ketkä ovat kumppanimme?',
  FAST_PRICES_2_DESC:
    'Teemme yhteistyötä satojen yritysten kanssa ja valitsemme parhaan kumppanin kuljetustarpeidesi mukaan varmistaen oikea-aikaisen toimituksen.',
  ALL_YOUR_SHIPMENTS_TITLE: 'Kaikki lähetyksesi yhdellä alustalla',
  ALL_YOUR_SHIPMENTS_1: 'Suunnittele, varaa ja seuraa yhdestä paikasta.',
  ALL_YOUR_SHIPMENTS_1_DESC:
    'Keskitetty hallinta takaa nopean ymmärryksen kustannuksista ja logistiikan nykytilasta. Saat aina tiedon lähetyksen noudosta, sijainnista ja saapumisajasta.',
  CREATING_A_SHIPMENT_TITLE: 'Lähetyksen luominen parissa minuutissa',
  CREATING_A_SHIPMENT_1: 'Tavaroiden lähettäminen on helppoa.',
  CREATING_A_SHIPMENT_1_DESC:
    'Syötä lähetyksen mitat ja paino. Lisää nouto- ja toimituspaikat ja -aika ja viimeistele tilaus.\n\nVoit tarvittaessa helposti lisätä asiakirjoja tai kommentteja tilaukseesi.',
  EASY_OVERVIEW_TITLE: 'Helppo yleiskatsaus lähetyksestäsi',
  EASY_OVERVIEW_1: 'Kaikki tiedot nopeasti saatavilla.',
  EASY_OVERVIEW_1_DESC:
    'Avaa yksityiskohtainen näkymä saadaksesi yleiskuvan lähetyksesi tilasta ja asiakirjoista. Voit myös lähettää viestin suoraan logistiikka-asiantuntijallemme.\n\nToimitustiedot ovat kaikkien työkavereidesi saatavilla tarvittaessa',
  WAREHOUSE_OVERVIEW_TITLE: 'Ajankohtainen näkymä varastossa',
  WAREHOUSE_OVERVIEW_1:
    'Parempi logistiikka tarvitsee paremman yleiskuvan saapuvista ja lähtevistä tavaroista',
  WAREHOUSE_OVERVIEW_1_DESC:
    'Varastonäkymän avulla tiedot saavuttavat myös varaston muutamassa sekunnissa. ',
  SUPPORT_BY_LOGISTICS_TITLE: 'Logistiikan ammattilaiset tukenasi',
  SUPPORT_BY_LOGISTICS_1: 'LaasOne huolehtii poikkeamien hallinnasta.',
  SUPPORT_BY_LOGISTICS_1_DESC:
    'Käymme puolestasi neuvottelut kuljetusyritysten kanssa ja ratkaisemme kaikki toimitukseen liittyvät asiat.\n\nVoit keskittyä liiketoimintaasi ja kaikki tukitehtävät, kuten logistiikka, hoidetaan alan ammattilaisten toimesta.',
  EAST_SOFTWARE_IMPLEMENTATION_TITLE: 'Palvelu on helppo ottaa käyttöön',
  EAST_SOFTWARE_IMPLEMENTATION_1: 'Siirtyminen LaasOne-logistiikkaalustalle on nopeaa ja helppoa.',
  EAST_SOFTWARE_IMPLEMENTATION_1_DESC:
    'Logistiikka-asiantuntijamme järjestää tiimillesi ilmaisen 45 minuutin koulutuksen ja selittää perusteellisesti pienetkin yksityiskohdat. Jos jokin kaipaa lisäselvitystä, olemme aina valmiina auttamaan sinua.\n\nLaasOne-logistiikkapalvelun käyttö on ilmaista.',
  WHAT_DO_WE_OFFER: 'Mitä tarjoamme?',
  OFFER_1: 'Logistiikan ja kuljetusten hallinta',
  OFFER_1_DESC_1: 'Valitsemme parhaat kumppanit ja varmistamme oikea-aikaiset toimitukset',
  OFFER_1_DESC_2: 'Rajaton määrä kumppaneita maailmanlaajuisesti, saatavilla 24/7',
  OFFER_2: 'Kaikki kuljetustiedot yhdellä alustalla',
  OFFER_2_DESC_1: 'Yksi tehokas toimitustietojen lähde yhdellä näytöllä',
  OFFER_2_DESC_2: 'Mahdollisuus integroida ERP- tai muuhun järjestelmääsi',
  OFFER_3: 'Skaalautuva ratkaisu asiakkaalle',
  OFFER_3_DESC_1: 'Sopeudu markkinoiden nousuihin ja laskuihin',
  OFFER_3_DESC_2: 'Sopiva ratkaisu etäisyydestä riippumatta',
  HOW_LAASONE_WORKS: 'Kuinka LaasOne toimii?',
  HOW_LAASONE_WORKS_DESC:
    'LaasOne-palvelu yhdistää teknologian, infrastruktuurin ja\n asiantuntemuksen reaaliaikaisen näkyvyyden ja hallinnan tarjoamiseksi sekä paljon paremman\n logistiikan hallintakokemuksen.',
  DID_YOU_KNOW:
    'Tiesitkö, että 45 % toimistotyöstä voidaan automatisoida teknologian avulla? Automatisoi logistiikkatoimintasi LaasOnella.',
  LETS_DO_IT: 'Tehdään se!',
  PEOPLE_BEHIND_THE_STORY: 'Ihmiset tarinan takana',
  WE_ARE_AROUND_EUROPE: 'Olemme lähellä sinua',
  INVALID_NAME: 'Virheellinen nimi',
  INVALID_EMAIL: 'Virheellinen sähköpostiosoite',
  INVALID_PHONE: 'Virheellinen puhelinnumero',
  INVALID_LINKEDIN: 'Virheellinen LinkedIn-profiili linkki',
  TOO_LONG_MESSAGE: 'Viesti liian pitkä',
  GET_STARTED: 'Aloita',
  I_AGREE_WITH: 'Olen samaa mieltä',
  TERMS_AND_CONDITIONS: 'Käyttöehdot',
  CAREER_AT_LAASONE: 'Töihin LaasOneiin',
  CAREERS_DESC:
    'LaasOne uudistaa yli 1 triljoonan dollarin rahdinhuolintateollisuuden tehdäksemme maailmanlaajuisesta kaupasta helpompaa kaikille. Se on iso urakka, jossa on valtavat plussat. Kuulostaako sinulta?',
  CAREERS_NO_POSITIONS_DESC_1:
    'Pahoittelut, meillä ei ole tällä hetkellä vapaita paikkoja, mutta seuraa tilannetta, sillä etsimme jatkuvasti lisää ihmisiä mahtavaan tiimiimme!',
  CAREERS_NO_POSITIONS_DESC_2:
    'Jos sinusta tuntuu, että voisimme käyttää kykyjäsi tänään, ota meihin yhteyttä',
  MORE_INFORMATION_ABOUT_POSITION: 'Lisätietoja paikasta',
  SOUNDS_LIKE_YOU: 'Kuulostaako sinulta?',
  YOUR_NAME: 'Nimesi',
  YOUR_EMAIL: 'Sähköpostiosoitteesi',
  YOUR_LINKEDIN: 'LinkedIn-URL-osoitteesi',
  WHY_DO_YOU_WANT_TO_JOIN_US: 'Miksi haluat töihin meille?',
  APPLY_NOW: 'Hae nyt',
  LATEST_POSTS: 'Viimeisimmät blogitekstit',
  ABOUT_US: 'Tietoja meistä',
  OUR_PARTNERS: 'Kumppanimme',
  CONTACT_US: 'Ota yhteyttä',
  ANY_QUESTIONS: 'Onko sinulla kysyttävää?',
  NICE: 'Kiva!',
  YOU_ARE_ALL_SIGNED_UP: 'Olet rekisteröitynyt demoon!',
  WE_WILL_CONTACT_YOU:
    'Otamme sinuun yhteyttä mahdollisimman pian sopiaksemme demon päivämäärästä ja kellonajasta.',
  LOOKING_FORWARD_MEETING_YOU: 'Odotamme tapaamistamme!',
  WE_GOT_YOUR_INFORMATION: 'Saimme tietosi!',
  WE_ARE_HAPPY_THAT_YOU_WROTE:
    'Olemme iloisia, että kirjoitit meille! Otamme sinuun yhteyttä mahdollisimman nopeasti.',
  THIS_WEBSITE_USES_COOKIES: 'Tämä verkkosivusto käyttää evästeitä',
  WE_USE_COOKIES:
    'Käytämme evästeitä. Voit poistua näistä evästeistä milloin tahansa muuttamalla käyttämäsi laitteen selaimen asetuksia ja poistamalla tallennetut evästeet.',
  REJECT_COOKIES: 'Hylkää evästeet',
  ALLOW_COOKIES: 'Salli evästeet',
  I_WOULD_LIKE_TO_RECEIVE_NEWSLETTER: 'Haluan saada LaasOne-uutiskirjeen',
  SO_FAR: 'Toistaiseksi',
  COST_REDUCTION: 'kustannusten pienentäminen',
  PRIVACY_POLICY: 'Tietosuojakäytäntö',
  COOKIE_POLICY_FILE_NAME: 'Tietoa evästeistä',
  COOKIE_NOTICE: 'Evästeilmoitus',
  COOKIE_PRE_TEXT: 'Lupasi koskee seuraavia toimialueita:',
  COOKIE_UPDATED: 'Evästeilmoitus päivitetty viimeksi: ',
  WHAT_ARE_COOKIES_HEADING: 'Mikä eväste on?',
  WHAT_ARE_COOKIES_TEXT:
    'Eväste on tietokoneellesi, tabletillesi tai matkapuhelimeesi tallennettu pieni datatiedosto. Eväste ei ole ohjelma, joka voisi sisältää haittaohjelmia tai viruksia.',
  COOKIES_ON_THIS_WEBSITE_HEADING: 'Miten ja miksi tämä verkkosivusto käyttää evästeitä',
  COOKIES_ON_THIS_WEBSITE_TEXT:
    'Evästeitä tarvitaan tämän verkkosivuston toimimiseen asianmukaisella tavalla. Evästeet auttavat meitä saamaan käynnistäsi yleiskuvan, jonka perusteella pystymme optimoimaan sivustoa ja muokkaamaan sitä tarpeidesi ja toiveidesi mukaan. Evästeet muistavat esimerkiksi, mitä tuotteita olet lisännyt ostoskoriisi, oletko käynyt jollain tietyllä sivulla aiemmin, oletko kirjautunut sisään, mitä kieliversiota käytät mieluiten ja missä valuutassa haluat nähdä tuotteiden hinnat. Lisäksi käytämme evästeitä kohdennettuun mainontaan muilla verkkosivustoilla. Yleisesti ottaen käytämme evästeitä osana palveluamme, koska tahdomme tarjota sinulle mahdollisimman kiinnostavaa sisältöä.',
  COOKIES_HOW_LONG_HEADING: 'Kuinka pitkään evästeitä säilytetään?',
  COOKIES_HOW_LONG_TEXT:
    'Evästeiden tallennusaika laitteellasi vaihtelee. Poistoajankohta lasketaan viimeisimmän vierailusi perusteella. Kun eväste vanhenee, se häviää laitteeltasi automaattisesti.',
  COOKIES_DECLINE_HEADING: 'Evästeiden estäminen ja poistaminen',
  COOKIES_DECLINE_TEXT:
    'Voit milloin tahansa estää evästeiden käytön tietokoneesi, tablettisi tai matkapuhelimesi selainasetuksissa. Asetusten tarkka sijainti riippuu käyttämäsi selaimen tyypistä. Jos muutat asetuksia, et välttämättä pysty käyttämään kaikkia toimintoja ja palveluita, koska ne edellyttävät, että verkkosivusto muistaa aiemmin tekemäsi valinnat.',
  COOKIES_DELETE_HEADING: 'Evästeiden poistaminen',
  COOKIES_DELETE_TEXT:
    'Halutessasi voit poistaa hyväksymäsi evästeet jälkikäteen. Jos käytössäsi on PC-tietokone ja tuore selainversio, voit poistaa evästeet näppäinkomennolla CTRL + SHIFT + Delete.',
  COOKIES_DELETE_TEXT2:
    'Jos näppäinkomento ei toimi ja/tai jos käytät Apple-tietokonetta, ota käyttämäsi selain selville ja napsauta sen jälkeen asianmukaista linkkiä:',
  COOKIES_DELETE_TEXT3:
    'Muista: Jos käytät useita eri selaimia, evästeet on poistettava kustakin erikseen.',
  COOKIES_QUESTIONS_HEADING: 'Onko sinulla kysyttävää?',
  COOKIES_QUESTIONS_TEXT:
    'Jos mielessäsi on näitä tietoja ja/tai henkilötietojesi käsittelyä koskevia kysymyksiä tai kommentteja, voit ottaa meihin yhteyttä.',
  NOT_FOUND_HEADING: 'Voi ei!',
  NOT_FOUND_DESC: 'Etsimääsi sivua ei ole olemassa.',
  NOT_FOUND_DESC_2: 'Virhekoodi: 404',
  GO_TO_HOMEPAGE: 'Siirry etusivulle',
  FORM_SOMETHING_WENT_WRONG: 'Jokin meni pieleen! Yritä uudelleen.',
  COUNTRY: 'Maa',
  PHONE_NUMBER: 'Puhelinnumero',
  ANNUAL_FREIGHT_SHIPMENTS: 'Vuotuiset rahtitilaukset',
  EMAIL: 'Sähköposti',
  COMPANY_NAME: 'Yrityksen nimi',
  FIRST_NAME: 'Etunimi',
  LAST_NAME: 'Sukunimi',
  LOGIN: 'Kirjaudu',

  // BLOG

  PUBLISHED: 'Julkaistu',
  AUTHOR: 'Kirjoittaja',
  TAGS: 'Tagit',
  READ_MORE_ABOUT_LAASONE: 'Lue lisää LaasOnesta',
  LOAD_MORE_POSTS: 'Lataa lisää viestejä',
  GO_TO_LAASONE_HOMEPAGE: 'Siirry LaasOnen kotisivulle',

  // CASE STUDIES

  READ_MORE_ABOUT: 'Lue lisää',

  CRYSTALSPACE_TEXT:
    'LaasOne auttoi meitä huipputeknisten kameroiden logistiikassa Virosta Yhdysvaltoihin. He järjestivät myös kaikki paperityöt vientiä varten, mikä on avaruusteknologian logistiikan monimutkaisin osa.',
  CRYSTALSPACE_READ_MORE_TEXT: '',
  CRYSTALSPACE_AUTHOR: 'Jaan Viru, Crystalspace OÜ:n perustaja ja teknologiajohtaja',

  RADIUS_TEXT:
    'Toimitusjohtajana logistiikan keskitetty hallinta on minulle korvaamatonta. Saan nopean yleiskatsauksen ja analyysin kuljetuksiemme tilasta ja kustannuksista. Voimme keskittyä liiketoimintaamme ja tukitehtäviä, kuten logistiikkaa, hoitavat LaasOne-tiimin ammattilaiset.',
  RADIUS_READ_MORE_TEXT: '',
  RADIUS_AUTHOR: 'Ahti Talts, Radius Machiningin toimitusjohtaja',

  POHJALA_TEXT:
    'Pidimme heti LaasOne-logistiikkaalustan yksinkertaisuudesta. Sen avulla kaikkien osapuolten on erittäin helppo seurata tavaroiden sijaintia, saapumisaikaa jne. Tämän lisäksi arvostan suuresti heidän henkilökohtaista asiakaspalveluaan, jota suuremmat yritykset eivät usein tarjoa.',
  POHJALA_READ_MORE_TEXT: '',
  POHJALA_AUTHOR: 'Rait Kulli, Põhjala Breweryn tuotantopäällikkö',

  FINNROTOR_TEXT:
    'Finn-Rotor Estonia Oü kuljettaa tavaroita ympäri maailmaa. LaasOnen avulla voimme hoitaa kaikki lähetykset yhdessä yksinkertaisessa järjestelmässä. Tämä on vapauttanut aikaa tiimiltämme ja säästänyt kuljetuskustannuksissa. Erityistarpeisiin LaasOne-tiimi löytää meille parhaan ratkaisun',
  FINNROTOR_READ_MORE_TEXT: '',
  FINNROTOR_AUTHOR: 'Matti Övermark, CEO',

  MERREM_TEXT:
    'Yrityksemme kasvaa jatkuvasti ja logistiikan merkitys kasvaa meille. LaasOne on tehnyt logistiikasta helposti hallittavaa ja kustannustehokasta. LaasOne on pitänyt kustannukset kurissa, sillä olemme kasvaneet vuosittain lähes 20 %',
  MERREM_READ_MORE_TEXT: '',
  MERREM_AUTHOR: 'Alar Künnapuu, CEO',

  // TEAM DATA
  VILLE_PELTOLA_TITLE: 'Toimitusjohtaja',
  JUSSI_PELTOLA_TITLE: 'Liiketoiminnan kehitysjohtaja',
  LAAS_KASK_TITLE: 'Operatiivinen johtaja',
  TALVAR_NURK_TITLE: 'Ohjelmistokehitys',
  MARGUS_MAJOROV_TITLE: 'Logistiikan asiantuntija',
  LAURA_MIKK_TITLE: 'Logistiikan asiantuntija',
  INDREK_RAHULA_TITLE: 'Logistiikan asiantuntija',
  VAINO_MIIL_TITLE: 'Myynti',

  // METADATA

  PAGE_TITLE: 'LaasOne – sinun digitaalinen logistiikkaosastosi',
  PAGE_DESCRIPTION:
    'LaasOne automatisoi pienten ja keskisuurten teollisuusyritysten logistiikan hallinnan ja hinnoittelun. 50 % tehokkaampi, 10 % pienemmät kuljetuskustannukset, logistiikka-alan ammattilaisten tuki.',

  // ELEMENTS IDS

  ID_BOOK_A_DEMO_BTN: 'bookademo_en_button',
  ID_BOOK_A_DEMO_BTN_LABEL: 'bookademo_en_label',

  ID_LOGIN_BTN: 'login_en_button',
  ID_LOGIN_BTN_LABEL: 'login_en_label',

  //ORDER FORMS
  ORDER_FORM: {
    TITLE: 'Instant quotation & order for companies',
    FROM: 'From',
    TO: 'To',
    FORM_TO_PLACEHOLDER: 'Country, city and zip',
    SUBMIT: 'Search prices',
    CARGO: 'Cargo',
    WEIGHT: 'Weight',
    WEIGHT_PLACEHOLDER: 'KG',
    AMOUNT: 'Amount',
    PACKAGE: 'Package',
    PALLET: 'Pallet',
    MEASURES: 'Measures (cm)',
    LENGTH_PLACEHOLDER: 'Length',
    WIDTH_PLACEHOLDER: 'Width',
    HEIGHT_PLACEHOLDER: 'Height',
    NO_PRICES_FOUND:
      'We could not generate instant prices for requested route. \n Please send price request and we will replay within 24 hours!',
    SEND_QUOTE: 'CLICK TO MAKE PRICE REQUEST',
    ORDER: 'CLICK TO ORDER',
    PRICE: 'PRICE',
    EXCL_VAT: 'excl. vat',
    SEND_QUOTATION_TITLE: 'Send a price quotation',
    CANCEL_QUOTATION: 'Cancel',
    SEND_QUOTATION: 'SEND',
    SEND_QUOTATION_INFORMATION:
      'Leave us your contacts and we will find a proper solution and a price and get back to you.',
    CONTACT_COMPANY: 'Company name',
    CONTACT_NAME: 'Contact',
    CONTACT_EMAIL: 'Email',
    CONTACT_PHONE: 'Phone',
    YOUR_CONTACT: 'Your contact',
    CLOSE: 'Close',
    QUOTATION_SENT: 'Quotation email sent successfully',
  },
  SELECT_COUNTRY: 'Select country',
  TRANSPORT: {
    TITLE: 'Syötä asiakkaasi antama asiakasnumero',
    CUSTOMER_NOT_FOUND: 'Yritystä ei löydy',
    REG_CODE: 'Rekisterikoodi',
    ADDRESSES: 'Osoite',
    BTN_ORDER: 'Tilaa kuljetus',
  },
  ORDER: {
    TITLE: 'Tilaa kuljetus kauttamme',
    ITEMS: 'Lisää tuotteita lähetykseesi',
    ADDRESSES: 'Lisää reittiosoitteet',
    DATES: 'Lisää nouto- ja toimituspäivämäärät',
    PRICE: 'Lähetyksen hinta',
    SUBMIT: 'Tilaa',
    YOUR_INFORMATION: 'Fill in your company information',
    ADDRESS: {
      COMPANY_INFO: 'Address and contact',
      COMPANY_REG: 'Reg nr.',
    },
    SEND_MODAL_TITLE: 'Viimeistele tilauksesi ...',
    CANCEL: 'Peruuttaa',
    CLOSE: 'Kiinni',
    SEND_MODAL_TEXT: 'Oletko varma, että olet syöttänyt oikeat lähetystiedot?',
    ORDER_SENT: 'Tilauksesi lähetettiin onnistuneesti! Olemme sinuun pian yhteydessä!',
    ADD_REFERENCE: 'Lisää viitenumero',
    REFERENCE: 'Viitenumero',
    ADDRESS_TOOLTIP: 'Syötä oikea sähköpostiosoite, jotta saat tiedotteita lähetykseesi liittyen',
  },
  PRICE_REQUEST: {
    DAYS_DELIVERY: 'days delivery',
    SAME_DAY_DELIVERY: 'Same day delivery',
    ON_TIME_DELIVERY: 'On time delivery',
    THIS_OPTION_IS_CHOSEN: 'This option is chosen',
  },
  SHIPMENT: {
    PICK_ONE_PRICE_ERROR: 'Valitse yksi hinta hyväksyntää varten',
    SEND: 'Lähetä',
    SEND_MESSAGE: 'Lähetä viesti',
    HAS_FILES: 'Lähetys sisältää tiedostoja',
    COMMENTS: 'Viestit',
    HAS_COMMENT: 'Sisältää viestejä',
    NEW_COMMENT: '1 uusi viesti',
    NEW_COMMENTS: '{0} uusia viestejä',
    NO_NEW_COMMENTS: 'Ei uusia viestejä',
    ADD_A_COMMENT: 'Lisää viesti',
    CLEAR_THE_FIELD: 'Tyhjennä kenttä',
    SHIPMENT_TYPE: 'Lähetä/vastaanota tuotteita',
    TYPE: {
      SENDER: 'Lähetän tuotteita',
      RECEIVER: 'Vastaanotan tuotteita',
    },
    YOU_HAVE_UNSAVED_CHANGES: 'Sinulla on tallentamattomia muutoksia.',
    DISCARD_SHIPMENT: 'Hylkää lähetys',
    TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Lisää uusi lähetys mallista',
    BTN_ADD_SHIPMENT: 'Lisää lähetys',
    FROM_TEMPLATE_DESCRIPTION:
      'Voit luoda ja hallita malleja avaamalla ”Mallit” otsikon käyttäjävalikosta.',
    BASED_ON_SHIPMENT: 'Perustuu lähetyksen {0}. ',
    SHIPMENT_CREATED: 'Lähetys luotu {0}',
    TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Kuljetus perävaununostimella varustetulla kuorma-autolla',
    TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY:
      'Toimitus perävaununostimella varustetulla kuorma-autolla',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP:
      'Kuorma-auto, jonka takaosaan on pysyvästi asennettu mekaaninen laite. Se on suunniteltu tavaroiden lastaamiseen maanpinnan tasolta tai lastauslaiturilta ajoneuvon alustan tasolle tai päinvastoin.',
    SAVE_SHIPMENT_AS_A_TEMPLATE: 'Tallenna lähetys {0} mallina',
    THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'Tätä lähetystä ei ole tallennettu mallina',
    THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'Tämä lähetys perustuu malliin',
    REMOVE_TEMPLATE_CONFIRM_TEXT: 'Haluatko varmasti poistaa "{0}" malleista?',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Tallenna tämä lähetys mallina',
    CANCEL_CONFIRMATION_DIALOG_TEXT:
      'Haluatko varmasti peruuttaa? Lähetysluonnoksen muutoksia ei tallenneta.',
    REMOVE_TEMPLATE_BUTTON: 'Poista malli',
    REMOVE_FROM_TEMPLATES: 'Mallien hallinta',
    NAME_YOUR_TEMPLATE: 'Nimeä mallisi',
    MANAGE_TEMPLATES: 'Mallien hallinta',
    SAVE_TEMPLATE: 'Tallenna malli',
    TEMPLATE_NAME: 'Mallin nimi',
    NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Tähän lähetykseen liittyvät ilmoitukset',
    SEND_SHIPMENT: 'Lähetä lähetys',
    SAVE_AS_DRAFT: 'Tallenna luonnoksena',
    SAVE_AS_DRAFT_SUCCESS: 'Lähetys on tallennettu luonnoksena',
    DELETE_DRAFT: 'Poista luonnos',
    DELETE_DRAFT_CONFIRMATION: 'Haluatko varmasti poistaa lähetysluonnoksen numero {0}?',
    DELETE_DRAFT_SUCCESS: 'Lähetysluonnos poistettu onnistuneesti',
    SHIPMENTS: 'Lähetykset',
    SHIPMENT: 'Lähetys',
    SHIPMENT_NR: 'Lähetysnro',
    REF_NR: 'Viitenro',
    REF_MISSING: 'Viite puuttuu',
    CARRIER_MISSING: 'Kuljetusliike puuttuu',
    PICKUP_MISSING: 'Nouto-osoite puuttuu',
    DELIVERY_MISSING: 'Toimitusosoite puuttuu',
    DATE_MISSING: 'Päivämäärä puuttuu',
    ADD_REFERENCE_NUMBER: 'Lisää viitenumero',
    YOUR_SHIPMENTS_PRICE: 'Lähetyksen hinta',
    YOUR_SHIPMENTS_PRICE_DESC:
      'Näytetyt hinnat ovat suuntaa-antavia ja voivat muuttua, jos todelliset tuotteet eivät vastaa syötettyjä arvoja',
    NO_PRICES: 'Jos haluat nähdä lähetyksen hinnat, täytä tiedot tuotteista ja osoitteista.',
    NO_AUTOMATIC_PRICES:
      'Valitettavasti emme tue automaattisia hintoja lähetyksellesi. Operaattori tarkastaa sen lähettämisen jälkeen ja ilmoittaa, mitä voimme tarjota.',
    AUTOMATIC_PRICES_IN_PROGRESS:
      'Pyrimme löytämään parhaat hinnat valitsemillesi tuotteille ja reiteille!',
    REFERENCE_NUMBER: 'Viitenumero',
    REFERENCE_NUMBER_TOOLTIP: 'Tämä numero tulee laskulle lähetyksen viitteeksi.',
    NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Ilmoita minulle tästä lähetyksestä',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Tallenna tämä lähetys mallina',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP:
      'Mahdollisuus tallentaa lähetys mallina, jotta samoja tietoja voidaan käyttää tulevaisuudessa uutta lähetystä tehtäessä.',
    EARLIEST_PICKUP_DATE: 'Aikaisin noutopäivämäärä',
    LATEST_PICKUP_DATE: 'Viimeisin noutopäivämäärä',
    EARLIEST_DELIVERY_DATE: 'Aikaisin toimituspäivämäärä',
    LATEST_DELIVERY_DATE: 'Viimeisin toimituspäivämäärä',
    FIXED_PICKUP_DATE: 'Kiinteä noutopäivämäärä',
    FIXED_DELIVERY_DATE: 'Kiinteä toimituspäivämäärä',
    EARLIEST_PICKUP_TIME: 'Aikaisin noutoaika',
    LATEST_PICKUP_TIME: 'Viimeisin noutoaika',
    EARLIEST_DELIVERY_TIME: 'Aikaisin toimitusaika',
    LATEST_DELIVERY_TIME: 'Viimeisin toimitusaika',
    FIXED_PICKUP_TIME: 'Kiinteä noutoaika',
    FIXED_DELIVERY_TIME: 'Kiinteä noutoaika',
    PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING:
      'Tarkista lähetystietosi ennen vahvistamista',
    SIDEBAR: {
      ACTIONS: 'Kopioi lähetys',
    },
    DATES: {
      ADD_PICKUP_DELIVERY_DATES: 'Lisää nouto- ja toimituspäivämäärät',
      PICKUP_DATES: 'Noutopäivämäärät',
      DELIVERY_DATES: 'Toimituspäivämäärät',
      PICK_PICKUP_DATES: 'Lisää aikaisimmat ja viimeisimmät noutopäivämäärät',
      PICK_DELIVERY_DATES: 'Lisää aikaisimmat ja viimeisimmät toimituspäivämäärät',
      PICK_PICKUP_FIXED_DATE: 'Lisää kiinteä noutopäivämäärä',
      PICK_DELIVERY_FIXED_DATE: 'Lisää kiinteä toimituspäivämäärä',
    },
    NAME_THIS_SHIPMENT_TEMPLATE: 'Nimeä tämä lähetysmalli',
    ADDRESSES: {
      CLIENT_CODE: 'Asiakasnumero',
      ADD_ADDRESS: 'Lisää osoite',
      UPDATE_ADDRESS: 'Päivitä osoite',
      ADD_ROUTE_ADDRESSES: 'Lisää reittiosoitteet',
      REQUIRED_FIELDS_ARE_MARKED_WITH: 'Pakolliset kentät on merkitty',
      WHERE_TO_PICK_UP_FROM: 'Mistä noudetaan?',
      WHERE_TO_DELIVER: 'Minne toimitetaan?',
      RECENTLY_USED_ADDRESSES: 'Viime aikoina käytetyt osoitteet',
      CHOOSE_FROM_ADDRESS_BOOK: 'Valitse osoitekirjasta',
      ADD_MANUALLY: 'Lisää manuaalisesti',
      CLEAR_ALL_FIELDS: 'Tyhjennä kaikki kentät',
      SAVE_THIS_ADDRESS: 'Tallenna tämä osoite: ',
      NAME_THIS_ADDRESS: 'Nimeä tämä osoite',
      SENDER_AND_DELIVERY_ADDRESSES: 'Lähettäjän ja toimituksen osoitteet',
      TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK:
        'Kirjoita tähän osoite ja valitse se Google-tuloksista tai osoitekirjasta',
      COUNTRY: 'Maa',
      CITY: 'Kaupunki',
      POSTCODE: 'Postinumero',
      COMPANY_NAME: 'Yrityksen nimi',
      CONTACT_PERSON: 'Yhteyshenkilö',
      CONTACT_NUMBER: 'Yhteysnumero',
      EMAIL: 'Sähköposti',
      REFERENCE: 'Viite',
      LOADING_REFERENCE: 'Noutoviite',
      LOADING_REFERENCE_TOOLTIP: 'Noutopaikkaan/rakennukseen jne. liittyvä numero.',
      UNLOADING_REFERENCE: 'Purkausviite',
      UNLOADING_REFERENCE_TOOLTIP: 'Toimituksen lastauspaikan/rakennuksen jne. numero',
      ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoinen osoite',
      ADDRESS_DETAILS: 'Osoitteen tiedot',
      ADDRESS: 'Osoite',
      ADD_MORE_INFORMATION: 'Lisää lisätietoja',
      CLOSE_MORE_INFORMATION: 'Sulje lisätiedot',
      SENDER: {
        INFORMATION: 'Lähettäjän tiedot',
        ADDRESS: 'Lähettäjän osoite',
        CONTACT_PERSON: 'Lähettäjän yhteyshenkilö',
        ADDRESS_DETAILS: 'Lähettäjän osoitetiedot',
        ADDRESS_DETAILS_TOOLTIP:
          'Osoitteeseen liittyvät tiedot, joilla osoitetaan tietty paikka tavaroiden noutoa varten (rakennuksen puoli, ovi, terminaali jne.)',
        ALTERNATIVE_ADDRESS: 'Vaihtoehtoinen lähettäjän osoite',
        ALTERNATIVE_CONTACT_PERSON: 'Vaihtoehtoinen lähettäjän yhteyshenkilö',
        ALTERNATIVE_ADDRESS_DETAILS: 'Vaihtoehtoiset lähettäjän osoitetiedot',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoinen lähettäjän osoite',
      },
      RECEIVER: {
        INFORMATION: 'Vastaanottajan tiedot',
        ADDRESS: 'Vastaanottajan osoite',
        CONTACT_PERSON: 'Vastaanottajan yhteyshenkilö',
        ADDRESS_DETAILS: 'Vastaanottajan osoitetiedot',
        ADDRESS_DETAILS_TOOLTIP:
          'Osoitteeseen liittyvät tiedot, joilla osoitetaan tietty tavaroiden toimituspaikka (rakennuksen puoli, ovi, terminaali jne.)',
        ALTERNATIVE_ADDRESS: 'Vaihtoehtoinen vastaanottajan osoite',
        ALTERNATIVE_CONTACT_PERSON: 'Vaihtoehtoinen vastaanottajan yhteyshenkilö',
        ALTERNATIVE_ADDRESS_DETAILS: 'Vaihtoehtoisen vastaanottajan osoitetiedot',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Lisää vaihtoehtoisen vastaanottajan osoite',
      },
    },
    ADD_ITEMS_TO_YOUR_SHIPMENT: 'Lisää tuotteita lähetykseesi',
    CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Napsauta tuotetta, jonka haluat lisätä',
    ITEMS_ADDED: 'Tuotteita lisätty',
    CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT:
      'Napsauta yhtä tai useampaa tuotetta yläpuolella aloittaaksesi niiden lisäämisen lähetykseesi',
    I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK:
      'Tarvitsen noudon takalaitanostimella varustettuun kuorma-autoon',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK:
      'Tarvitsen toimituksen perälautanostimella varustetulla kuorma-autolla',
    ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Lisää kuljetukseen liittyvä kommentti',
    DOCUMENTS: {
      DELETE_TOOLTIP: 'Tätä asiakirjaa ei voida poistaa, koska se on operaattorin luoma',
    },
    CMR_NOT_AVAILABLE: 'Rahtikirjaa ei voida vielä ladata, koska kuljetusyhtiötä ei ole valittu',
    POD_NOT_AVAILABLE: 'Tätä asiakirjaa ei voida vielä ladata, koska POD-tiedostoja puuttuu',
    ITEM: {
      SELECT_TYPE: 'Valitse tyyppi',
      TOTAL: 'Yhteensä',
      DETAILS: {
        PC: 'kpl',
        TYPE: 'Tyyppi',
        LENGTH: 'Pituus',
        LENGTH_TOOLTIP: 'Pituus on kiinteä valitulle tuotetyypille',
        WIDTH: 'Leveys',
        WIDTH_TOOLTIP: 'Leveys on kiinteä valitulle tuotetyypille',
        HEIGHT: 'Korkeus',
        VOLUME: 'Tilavuus',
        WEIGHT_PC: 'Paino/kpl',
        TOTAL_WEIGHT: 'Kokonaispaino',
        LDM: 'LDM',
        LDM_TOOLTIP:
          'Kuormametri (perävaunun pituus juoksumetreinä täydessä leveydessä ja korkeudessa). Tämä lasketaan automaattisesti pituuden, leveyden ja määrän perusteella, mutta voit tarvittaessa kirjoittaa eri arvon.',
        REFERENCE_NUMBER: 'Viitenumero',
        PRODUCT_DESCRIPTION: 'Tuotekuvaus',
        UN_NO: 'UN-nro',
        UN_NO_TOOLTIP: 'Numero, joka yksilöi vaaralliset aineet, vaaralliset aineet ja esineet',
        UN: 'UN',
        UN_CODE: 'UN-koodi',
        PG: 'PG',
        PG_TOOLTIP:
          'Vaaralliset aineet luokitellaan kolmeen pakkausryhmään niiden aiheuttaman vaaran mukaan',
        CLASS: 'Luokka',
        CLASS_TOOLTIP: 'Vaarallisten aineiden luokitusnumerot',
        DANGEROUS_GOODS: 'Vaaralliset tuotteet',
        COLD_FROZEN: 'Kylmä/pakaste',
        STACKABLE: 'Pinottavissa',
        DELICATE: 'Särkyvää',
        MIN_TEMP: 'Min. lämpötila',
        MIN_TEMP_TOOLTIP: 'Kuljetuksen aikana edellytetty tuotteiden vähimmäislämpötila',
        MAX_TEMP: 'Maks. lämpötila',
        MAX_TEMP_TOOLTIP: 'Kuljetuksen aikana edellytetty tuotteiden enimmäislämpötila',
        PROPER_SHIPPING_NAME: 'Asianmukainen laivausnimi',
        PROPER_SHIPPING_NAME_TOOLTIP:
          'Vaarallisten aineiden vaarallisia ominaisuuksia ja koostumusta kuvaava nimi',
        PROPER_SHIPPING_NAME_PLACEHOLDER: 'Mustaruuti, tiivistetty tai aseruuti',
        PACKAGE_INFO:
          'Merkitse pisimmän sivun mitta kenttään ”Pituus”, kun pakkaus on pinottavissa.',
      },
      TYPE: {
        PALLET: 'Lava',
        PACKAGE: 'Paketti',
        TRUCK: 'Kuorma',
        OTHER: 'LVM',
        CONTAINER: 'Kontti',
      },
      SUBTYPE: {
        EUR_PALLET: 'EURO-lava',
        FIN_PALLET: 'FIN-lava',
        PALLET: 'Lava',
        PACKAGE: 'Paketti',
        BOX: 'Laatikko',
        FULL_TRUCK_LOAD: 'Täysi kuorma',
        LONG_PALLET: 'Pitkä lava',
        HALF_PALLET: 'Puolikas lava',
        LDM: 'LVM',
        CONTAINER_20_DC: '20’DC',
        CONTAINER_40_DC: '40’DC',
        CONTAINER_40_HC: '40’HC',
        CONTAINER_OTHER: 'Muu',
      },
      CLASS: {
        EXPLOSIVES: 'Räjähteitä',
        GASES: 'Kaasuja',
        FLAMMABLE_LIQUIDS: 'Syttyvät nesteet',
        FLAMMABLE_SOLIDS: 'Syttyvät kiinteät aineet',
        OXIDIZERS: 'Hapettimet',
        TOXIC_SUBSTANCES: 'Myrkylliset aineet',
        RADIOACTIVE: 'Radioaktiivinen',
        CORROSIVES: 'Syövyttävät aineet',
        MISCELLANOUS: 'Sekalaista',
      },
      PG: {
        PG1: 'Pakkausryhmä I: suuri vaara',
        PG2: 'Pakkausryhmä II: keskivaarallinen',
        PG3: 'Pakkausryhmä III: vähäinen vaara',
      },
    },
    DELETE_DOCUMENT: 'Poista asiakirja',
    DELETE_CONFIRM_TEXT: 'Haluatko varmasti poistaa asiakirjan {0}"?',
    ADD_DOCUMENT_MODAL_TITLE: 'Lisää uusi asiakirja',
    ADD_DOCUMENT_BUTTON: 'Lisää asiakirja',
    CUSTOMER_CONFIRMED: 'Asiakas vahvistanut: {0}',
    PICKUP_ON: 'Noutoajankohta: {0}',
    PICKUP_FROM: 'Noutokohde: {0}',
    PICKUP_BETWEEN: 'Noutoaika välillä: {0} ja {1}',
    DELIVERY_ON: 'Toimitusajankohta: {0}',
    DELIVERY_FROM: 'Toimitus alkaen: {0}',
    DELIVERY_BETWEEN: 'Toimitus välillä: {0} ja {1}',
    IMPORT_MODAL_TITLE: 'Tuo lähetyksiä Excel-tiedostosta',
    IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} lähetystä tuotu onnistuneesti',
    IMPORT_MODAL_TITLE_INCORRECT_ROWS: 'Lähetyksiä ei tuotu Excel-tiedostosta',
    IMPORT_MODAL_TITLE_SMALL: 'Vain yksi Excel-tiedosto voidaan tuoda kerralla.',
    BTN_IMPORT: 'Tuo lähetykset',
    BTN_IMPORT_FINISH: 'Ok, ymmärretty.',
    BTN_IMPORT_CORRECT_ROWS: 'Ok, ymmärretty. Ladataan uusi tiedosto',
    BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Lataa tiedosto, jossa on virherivejä (.xlsx)',
    ERROR_MALFORMED_ROWS_TEXT:
      'Joillakin alla olevilla riveillä on validointivirheitä, joten niitä ei voida tuoda',
    ERROR_DUPLICATED_ROWS_TEXT:
      'Jotkin alla näkyvät lähetykset on jo tallennettu, joten niitä ei voida tuoda',
    EXCEL_ROW_NUMBER: 'Excel-rivin numero',
    EXCEL_ROW_MISSING_WRONG_INFO: 'Puuttuvat tai väärät tiedot',
    EXCEL_ROW_EXISTS: 'Lähetys, joka on olemassa samoilla tiedoilla',
    EXCEL_IMPORT_ERRORS: {
      SENDER_REQUIRED: 'Lähettäjä puuttuu',
      SENDER_ADDRESS_REQUIRED: 'Lähettäjän osoite puuttuu',
      SENDER_CITY_REQUIRED: 'Lähettäjän kaupunki puuttuu',
      SENDER_POST_CODE_REQUIRED: 'Lähettäjän postinumero puuttuu',
      SENDER_COUNTRY_REQUIRED: 'Lähettäjän maa puuttuu',
      SENDER_CONTACT_PERSON_REQUIRED: 'Lähettäjän yhteyshenkilön nimi puuttuu',
      SENDER_CONTACT_PHONE_REQUIRED: 'Lähettäjän yhteyshenkilön puhelin puuttuu',
      RECEIVER_REQUIRED: 'Vastaanottaja puuttuu',
      RECEIVER_ADDRESS_REQUIRED: 'Vastaanottajan osoite puuttuu',
      RECEIVER_CITY_REQUIRED: 'Vastaanottajan kaupunki puuttuu',
      RECEIVER_POST_CODE_REQUIRED: 'Vastaanottajan postinumero puuttuu',
      RECEIVER_COUNTRY_REQUIRED: 'Vastaanottajan maa puuttuu',
      RECEIVER_CONTACT_PERSON_REQUIRED: 'Vastaanottajan yhteyshenkilön nimi puuttuu',
      RECEIVER_CONTACT_PHONE_REQUIRED: 'Vastaanottajan yhteyshenkilön puhelin puuttuu',
      PICKUP_DATE_ERROR: 'Noutopäivämäärä puuttuu',
      DELIVERY_DATE_ERROR: 'Toimituspäivä puuttuu',
      DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR:
        'Tämä päivämäärä on aikaisempi kuin korjattu päivämäärä',
      QUANTITY_ERROR: 'Määrä puuttuu',
      PACKAGE_ERROR: 'Paketin tyyppi puuttuu',
      WEIGHT_ERROR: 'Paino puuttuu',
      WIDTH_ERROR: 'Leveys puuttuu',
      LENGTH_ERROR: 'Pituus puuttuu',
      HEIGHT_ERROR: 'Korkeus puuttuu',
      PICKUP_DATE_IN_PAST: 'Noutopäivä on menneisyydessä',
      LDM_ERROR: 'LDM puuttuu',
    },
    BTN_DOWNLOAD_XLSX_TEMPLATE: 'Lataa malli (.xlsx)',
  },
  VALIDATION: {
    POSTCODE_IS_REQUIRED: 'Postcode is missing',
  },
  ADD_DOCUMENTS_TITLE: 'Lisää asiakirjoja',
  DROPZONE: {
    DRAG_AND_DROP: 'Vedä ja pudota tiedosto tähän tai',
    CHOOSE_FROM_COMPUTER: 'valitse tietokoneeltasi',
    SUPPORTED_FILES: 'Tuetut tiedostotyypit: PDF, PNG, JPG, JPEG',
    SUPPORTED_FILES_XLS: 'Tuetut tiedostotyypit: XLSX',
    ERROR: 'Tiedoston “{0}” tiedostotyyppiä ei tueta',
  },
  SALES_EST: 'Myynti Baltiassa',
  SALES_FI: 'Myynti Pohjoismaat',
  TITLE_ADMINISTRATION: 'Taloushallinto',
  TITLE_GENERAL: 'Yleinen',
  TORMMETALL_TEXT:
    "Olen äärimmäisen tyytyväinen LaasOne'n ammattimaiseen asenteeseen ja palvelun laatuun. Heidän tiiminsä on ystävällinen, avulias ja ehkä kaikkein tärkeintä - nopea reagointikyky tekee yhteistyöstä heidän kanssaan sujuvaa ja luotettavaa. LaasOne on erinomainen esimerkki yrityksestä, joka on pysynyt täysin digitaalisessa kehityksessä mukana. Suosittelen ehdottomasti LaasOne'a kaikille, jotka etsivät tehokasta ja luotettavaa logistiikkapalvelua.",
  TORMMETALL_READ_MORE_TEXT: '',
  TORMMETALL_AUTHOR: 'Sander Poljakov, Logistik',
  KONETALO_READ_MORE_TEXT: '',
  KONETALO_TEXT:
    'LaasOne:n avulla saimme logistiikan paremmin hallintaan sekä kustannukset kuriin. Palvelu, jota todellakin uskaltaa suositella!',
  KONETALO_AUTHOR: 'Timo Peltola, CEO',
  HAPPY_BIRTHDAY: 'LaasOne on nyt LaasFifty.',
  HAPPY_BIRTHDAY2: 'Tämä vain juhlistaaksemme kollegamme Laas Kaskin 50-vuotispäivää!',
  BTN_CLOSE: 'Close',
};

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;

export const demoRequest = ({
  firstName,
  lastName,
  companyName,
  email,
  country,
  phone,
  shipments,
  newsletterAgreement,
}) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify({
      firstName,
      lastName,
      companyName,
      email,
      country,
      phone,
      shipments,
      newsletterAgreement,
    }),
  };

  return fetch(`${URL}/request-demo`, params);
};

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const AccentVariant2 = ({ className, color, opacity }) => (
  <svg
    className={className}
    width="591"
    height="274"
    viewBox="0 0 591 274"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M202.902 266.661L306.989 144.339C311.831 139.446 311.831 132.107 309.41 127.214L260.997 9.78571C258.576 2.44643 251.315 0 246.473 0H62.5042C50.401 0 43.1391 12.2321 47.9804 24.4643L91.552 127.214C93.9726 132.107 93.9726 139.446 89.1313 144.339L4.40877 244.643C-5.27381 256.875 1.98813 274 16.512 274H193.219C195.64 271.554 200.481 269.107 202.902 266.661Z"
      opacity={opacity}
      fill={color}
    />
    <path
      d="M482.902 266.661L586.989 144.339C591.831 139.446 591.831 132.107 589.41 127.214L540.997 9.78571C538.576 2.44643 531.315 0 526.473 0H342.504C330.401 0 323.139 12.2321 327.98 24.4643L371.552 127.214C373.973 132.107 373.973 139.446 369.131 144.339L284.409 244.643C274.726 256.875 281.988 274 296.512 274H473.219C475.64 271.554 480.481 269.107 482.902 266.661Z"
      opacity={opacity}
      fill={color}
    />
  </svg>
);

AccentVariant2.defaultProps = {
  className: undefined,
  color: theme.palette.common.grayishViolet,
  opacity: 0.1,
};

AccentVariant2.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { AccentVariant2 };

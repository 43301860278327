import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
//import { makeStyles } from '@material-ui/styles';

import { OfficesMapSvg } from './OfficesMapSvg';
//import { OfficesPinsSvg } from './OfficesPinsSvg';

/*const useStyles = makeStyles(() => ({
  pins: {
    position: 'absolute',
    top: 135,
    right: 165,
  },
}));*/

const OfficesMap = ({ className, opacity /*, pinsVisible*/ }) => {
  //const classes = useStyles();

  return (
    <Box position="relative" width="fit-content" className={className}>
      <OfficesMapSvg opacity={opacity} />
      {/*pinsVisible && <OfficesPinsSvg className={classes.pins} />*/}
    </Box>
  );
};

OfficesMap.defaultProps = {
  className: undefined,
  opacity: 1.0,
  pinsVisible: true,
};

OfficesMap.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  pinsVisible: PropTypes.bool,
};

export { OfficesMap };

import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ translucent, size, ...restProps }) => {
  const sizeToHeight = {
    small: 16,
    medium: 20,
    full: 'unset',
  };

  const height = sizeToHeight[size];

  return (
    <img
      alt="LaasOne"
      height={height}
      src={`/images/${translucent ? 'logo-translucent.svg' : 'logo.svg'}`}
      {...restProps}
    />
  );
};

Logo.defaultProps = {
  translucent: false,
  size: 'full',
};

Logo.propTypes = {
  translucent: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'full']),
};

export { Logo };

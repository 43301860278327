import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Loader, PriceOffer } from 'components';
import { useFormikContext } from 'formik';
import { theme } from 'theme';
import clsx from 'clsx';
import { NewShipmentContext } from 'scenes/Order/Order';
import Typography from '@material-ui/core/Typography';

const automaticPricesTexts = {
  Idle: 'SHIPMENT.NO_PRICES',
  CantCalculate: 'SHIPMENT.NO_AUTOMATIC_PRICES',
  InProgress: 'SHIPMENT.AUTOMATIC_PRICES_IN_PROGRESS',
};

export const PriceStep = (): ReactElement => {
  const { t } = useTranslation();

  const displayedOffersCount = 6;

  const { automaticPricesState } = useContext(NewShipmentContext);

  const formik = useFormikContext();
  const offeredPrices = formik.values.offeredPrices ?? [];

  const [selectedOffer, setSelectedOffer] = useState(
    formik.values.selectedPriceOffer ? +formik.values.selectedPriceOffer : null,
  );

  const onSelectedPriceOfferChanged = (id: number) => {
    formik.setErrors({ ...formik.errors, selectedPriceOffer: undefined });
    formik.setFieldValue('selectedPriceOffer', id);
  };

  useEffect(() => {
    setSelectedOffer(formik.values.selectedPriceOffer ? +formik.values.selectedPriceOffer : null);
  }, [formik.values.selectedPriceOffer]);

  const displayPrices = offeredPrices.length && automaticPricesState !== 'InProgress';

  return (
    <Box>
      <Typography variant="h5" marginBottom={1.5}>
        {t('SHIPMENT.YOUR_SHIPMENTS_PRICE')}
      </Typography>
      <Typography variant="body2" fontWeight="medium" marginBottom={4}>
        {t('SHIPMENT.YOUR_SHIPMENTS_PRICE_DESC')}
      </Typography>

      <Box
        width={1133}
        borderRadius={16}
        bgcolor={theme.palette.common.veryLightGrayAlt2}
        paddingX={displayPrices ? 6 : 32}
        paddingY={8}
        height={displayPrices ? undefined : 342}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={displayPrices ? 'left' : 'center'}
          alignItems="center"
          width={1}
          height={1}
          className={clsx(!!formik.errors.selectedPriceOffer && 'Mui-error')}
        >
          {displayPrices ? (
            offeredPrices.slice(0, displayedOffersCount).map((offeredPrice, index) => (
              <Box
                key={offeredPrice.id}
                marginRight={index < displayedOffersCount - 1 ? 3.75 : 0}
                marginTop={index > 2 ? 4 : 0}
              >
                <PriceOffer
                  offeredPrice={offeredPrice}
                  onSelected={onSelectedPriceOfferChanged}
                  showError={!!formik.errors.selectedPriceOffer}
                  selected={selectedOffer === offeredPrice.id && offeredPrice.id !== undefined}
                  width={346}
                />
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              {automaticPricesState === 'InProgress' && (
                <Box marginBottom={3}>
                  <Loader />
                </Box>
              )}
              <Typography variant="h6" textAlign="center">
                {t(automaticPricesTexts[automaticPricesState])}
              </Typography>
            </Box>
          )}
        </Box>
        {!!formik.errors.selectedPriceOffer && (
          <Typography variant="caption" marginTop={1} customColor={theme.palette.common.alertError}>
            {formik.errors.selectedPriceOffer}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

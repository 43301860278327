import { createMuiTheme } from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme();

const white = '#ffffffff';
const veryDarkViolet = '#1c2544'; //main color
const veryDarkDesaturatedViolet = '#2a314f'; //hover color
const veryLightGray = '#f4f3f6';
const veryLightGrayAlt = '#ebebeb';
const gray = '#858585';
const lightGrayishViolet = '#e9e6ed';
const lightGrayishVioletAlt = '#d4cedb';
const veryDarkGray = '#333333';
const veryDarkGrayAlt = '#3d3d3d';
const darkCyan = '#008094';
const mostlyDesaturatedDarkViolet = '#7d6b94';
const grayishViolet = '#a89cb8';
const strongViolet = '#364166';
const veryLightYellow = '#ffe178';
const vividYellow = '#fac91a';
const orderFormYellow = '#F1CC4E';
const veryDarkGrayAlt2 = '#5c5c5c';
const grayAlt = '#adadad';
const veryLightGrayAlt2 = '#f5f5f5';
const lightGray = '#d6d6d6';
const alertError = '#b80037';
const lightGrayishVioletAlt3 = '#f0eaf8';
const lightGrayishVioletAlt4 = '#f7f4fb';

const breakpoints = {
  iPhone5: 670,
  amazonFire: 1024,
  desktop: 1366,
};

export const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      contrastText: white,
      main: veryDarkViolet,
    },
    secondary: {
      contrastText: veryDarkViolet,
      main: vividYellow,
    },
    common: {
      white,
      veryDarkDesaturatedViolet,
      veryLightGray,
      veryLightGrayAlt,
      grayishViolet,
      lightGrayishViolet,
      lightGrayishVioletAlt,
      darkCyan,
      mostlyDesaturatedDarkViolet,
      veryDarkViolet,
      veryDarkGray,
      veryDarkGrayAlt,
      strongViolet,
      veryLightYellow,
      vividYellow,
      gray,
      orderFormYellow,
      veryDarkGrayAlt2,
      grayAlt,
      veryLightGrayAlt2,
      lightGray,
      alertError,
      lightGrayishVioletAlt3,
      lightGrayishVioletAlt4,
    },
    text: {
      primary: veryDarkGray,
      secondary: white,
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...breakpoints,
      xs: 0,
      sm: 600,
      iPhone5: 670,
      md: 960,
      lg: 1126,
      desktop: 1310,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial'].join(','),
    h1: {
      fontWeight: 900,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '1.625rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '0.875rem',
        textTransform: 'none',
        padding: defaultTheme.spacing(1.5, 3),
      },
      outlined: {
        padding: defaultTheme.spacing(1.5, 3),
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '80px',
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: defaultTheme.spacing(1),
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(1.5, 1.75),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        width: '2rem',
        height: '2rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          overflowX: 'hidden',
        },
        body: {
          overflowX: 'hidden',
        },
        a: {
          color: veryDarkViolet,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  },
});

import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
  const history = useHistory();
  const { search } = useLocation();

  const updateQueryParams = (path, newQueryParams) => {
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
    history.push({
      pathname: path,
      search: qs.stringify({ ...queryParams, ...newQueryParams }, { addQueryPrefix: true }),
    });
  };
  return {
    updateQueryParams,
  };
}

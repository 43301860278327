import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const variants = {
  xs: 32,
  sm: 48,
  md: 56,
  lg: 64,
  xl: 72,
  xxl: 80,
};

const Spacer = ({ variant }) => <Box w={1} height={variants[variant] || variants.xs} />;

Spacer.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)).isRequired,
};

export { Spacer };

import React, { useState, lazy, useEffect, useRef, useCallback } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  AddressesStep,
  Button,
  CompanyInfoStep,
  DateStep,
  ItemsStep,
  Loader,
  OrderStep,
  PriceStep,
} from '../../components';
import mapValues from 'lodash/mapValues';
import { performInNextCycle } from 'shared/functions/performInNextCycle';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { formStates } from 'shared/constants/formStates';
import { useDebouncedCallback } from 'use-debounce';
import { useClassifiers } from 'shared/services/classifier';
import { applyForPrices } from 'shared/services';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { usePlaces } from 'shared/services/fetchLoadingAddressSuggestionsFromGoogle';
import { useNewShipmentValidation } from 'shared/hooks/useNewShipmentValidation';
import { scrollToFirstInvalid } from 'shared/functions/scrollToFirstInvalid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import clsx from 'clsx';
import { applyOrder } from 'shared/services/applyOrder';

const Footer = lazy(() => import('components/Footer/Footer'));
const FooterExtra = lazy(() => import('components/Footer/FooterExtra'));

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  dialog: {
    '& .MuiPaper-rounded': {
      padding: '0px 40px 20px 40px',
      boxShadow: theme.shadows[1],
    },
    '& .MuiDialogTitle-root': {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 500,
    },
    '& .MuiDialog-paperWidthMd': {
      maxWidth: 700,
    },
    '& .MuiDialog-paperWidthLg': {
      maxWidth: 992,
    },
    '& .MuiDialog-scrollBody': {
      [theme.breakpoints.down(992)]: {
        overflowX: 'scroll',
      },
    },
  },
  heading: {
    marginBottom: theme.spacing(8),
  },
  description: {
    marginBottom: theme.spacing(12),
    whiteSpace: 'pre-line',
    lineHeight: '1.5rem',
  },

  bulbImage: {
    position: 'absolute',
    top: 64,
    left: 75,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      opacity: 0.1,
      top: -35,
      left: 25,
    },
  },
  noPositionsImage: {
    maxWidth: '100%',
  },
  accent: {
    position: 'absolute',
    top: -220,
    right: -200,
  },
  button: {
    backgroundColor: '#048295',
    marginLeft: theme.spacing(2),
  },
  success: {
    border: '1px solid',
    borderColor: theme.palette.common.darkCyan,
    padding: 12,
    backgroundColor: theme.palette.common.darkCyan,
    color: theme.palette.common.white,
  },
}));

const addressData = {
  addressLine1: '',
  city: '',
  companyName: '',
  contactPersonName: '',
  country: '',
  email: '',
  notes: '',
  phone: '',
  postCode: '',
  reference: '',
};

const initialValues = {
  company: {
    addressLine1: '',
    city: '',
    companyName: '',
    contactPersonName: '',
    country: '',
    email: '',
    notes: '',
    phone: '',
    postCode: '',
    reference: '',
    regNr: '',
  },
  receiver: {
    addressLine1: '',
    city: '',
    companyName: '',
    contactPersonName: '',
    country: '',
    email: '',
    notes: '',
    phone: '',
    postCode: '',
    reference: '',
  },
  sender: {
    addressLine1: '',
    city: '',
    companyName: '',
    contactPersonName: '',
    country: '',
    email: '',
    notes: '',
    phone: '',
    postCode: '',
    reference: '',
  },
  shipmentDetailsRows: [
    {
      coldFrozen: false,
      dangerousGoods: false,
      height: '',
      ldm: 0,
      length: '',
      loadingMeters: 0,
      packageId: '7',
      quantityId: 1,
      reference: '',
      remarks: '',
      volume: '',
      weight: '',
      width: '',
      typeId: 7,
    },
  ],
  type: 7,
  earliestPickupDate: '',
  latestPickupDate: '',
  earliestArrivalDate: '',
  latestArrivalDate: '',
  earliestArrivalTime: '',
  earliestPickupTime: '',
  latestArrivalTime: '',
  latestPickupTime: '',
  isFixedPickupDate: false,
  isFixedArrivalDate: false,
  selectedPriceOffer: null,
  offeredPrices: [],
};
const calculatePricesDelay = 3000;

const defaultNewShipmentData = {
  automaticPricesState: 'Idle',
  setAutomaticPricesState: () => {},
  countries: [],
};

export const NewShipmentContext = React.createContext(defaultNewShipmentData);

const Order = ({ ...restProps }) => {
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [loadingInitialForm, setLoadingInitialForm] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPriceRequest, setIsPriceRequest] = useState(false);
  const [isFormSubmitted] = useState(false);
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [filledFormValues, setFilledFormValues] = useState(initialValues);
  const { fetchLoadingContactByPlaceId } = usePlaces();
  const allSteps = 5;
  const [queryParamsLoading, setQueryParamsLoading] = useState(true);
  const [automaticPricesState, setAutomaticPricesState] = useState('Idle');
  const [initialShipmentData] = useState();
  const [newShipmentContextValue, setNewShipmentContextValue] = useState({
    automaticPricesState,
    setAutomaticPricesState,
  });
  const { confirmValidationSchema } = useNewShipmentValidation();
  const { fetchCountries } = useClassifiers();
  // Formik requires onSubmit function to be defined, however we don't use it,
  // hence we can define an empty one
  const emptyFunction = useCallback(() => {}, []);
  useEffect(() => {
    if (!countriesLoaded) {
      fetchCountries({ language: i18n.language }).then((countries) => {
        if (countries) {
          setNewShipmentContextValue((currentShipmentContextValue) => ({
            ...currentShipmentContextValue,
            countries,
          }));
        }
        setCountriesLoaded(true);
      });
    }
  });

  useEffect(() => {
    if (queryParamsLoading && search) {
      const params = qs.parse(search, { ignoreQueryPrefix: true });
      try {
        if (params) {
          setTimeout(async () => {
            setIsPending(true);
            setLoadingInitialForm(true);
            let itemWeight = 0;
            let pickup = addressData;
            let delivery = addressData;

            if (params.weight && params.amount) {
              itemWeight = (params.weight / params.amount).toFixed(1);
            }
            if (params.fromPlaceId) {
              const pickupPlace = await fetchLoadingContactByPlaceId({
                placeId: params.fromPlaceId,
                source: 'Google',
              });
              if (pickupPlace) {
                pickup = {
                  ...addressData,
                  addressLine1: pickupPlace.addressLine1,
                  country: pickupPlace.country,
                  city: pickupPlace.city,
                  postCode: pickupPlace.postCode,
                };
              }
            }
            if (params.toPlaceId) {
              const deliveryPlace = await fetchLoadingContactByPlaceId({
                placeId: params.toPlaceId,
                source: 'Google',
              });
              if (deliveryPlace) {
                delivery = {
                  ...addressData,
                  addressLine1: deliveryPlace.addressLine1,
                  country: deliveryPlace.country,
                  city: deliveryPlace.city,
                  postCode: deliveryPlace.postCode,
                };
              }
            }
            const rows = [
              {
                coldFrozen: false,
                dangerousGoods: false,
                height: params.height,
                ldm: 0,
                length: params.length,
                loadingMeters: 0,
                packageId: params.type.toString(),
                quantityId: params.amount ? parseInt(params.amount) : 1,
                quantity: params.amount ? parseInt(params.amount) : 1,
                reference: '',
                remarks: '',
                volume: '',
                weight: itemWeight,
                width: params.width,
                typeId: params.type,
              },
            ];
            setFormInitialValues({
              ...initialValues,
              type: params.type,
              shipmentDetailsRows: rows,
              sender: pickup,
              receiver: delivery,
              company: pickup,
            });
            setIsPending(false);
            setLoadingInitialForm(false);
          }, 900);
        }
      } catch (e) {}
    }
    setQueryParamsLoading(false);
  }, [search, queryParamsLoading, fetchLoadingContactByPlaceId]);

  const formRef = useRef(null);

  const saveData = async () => {
    let formData = filledFormValues;
    if (!formData.earliestArrivalDate) formData.earliestArrivalDate = null;
    if (!formData.earliestPickupDate) formData.earliestPickupDate = null;
    if (!formData.latestPickupDate) formData.latestPickupDate = null;
    if (!formData.latestArrivalDate) formData.latestArrivalDate = null;
    if (formData.shipmentDetailsRows && formData.shipmentDetailsRows.length > 0) {
      for (let i = 0; i < formData.shipmentDetailsRows.length; i++) {
        if (!formData.shipmentDetailsRows[i].volume) {
          formData.shipmentDetailsRows[i].volume = null;
        }
        if (!formData.shipmentDetailsRows[i].loadingMeters) {
          formData.shipmentDetailsRows[i].loadingMeters = null;
        }
      }
    }
    applyOrder(formData, isPriceRequest)
      .then((response) => {
        setFormState(response.ok ? formStates.SUCCESS : formStates.ERROR);
        return response.json();
      })
      .catch(() => {
        setFormState(formStates.ERROR);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleSubmit = async (formData, priceRequest) => {
    setIsPriceRequest(priceRequest);
    if (formRef.current) {
      try {
        await validateYupSchema(formRef.current.values ?? {}, confirmValidationSchema, true);

        // Make sure that no fields are marked as errors if the validation has passed
        formRef.current.setErrors({});
        setFilledFormValues(formData);
        setOpenDialog(true);
      } catch (err) {
        const formErrors = yupToFormErrors(err);
        const touchedValues = mapValues(formErrors, (v) => !!v);

        formRef.current.setErrors(formErrors);
        formRef.current.setTouched({ ...touchedValues });
        performInNextCycle(scrollToFirstInvalid);
      }
    }
  };

  const canCalculatePrices = () => {
    if (formRef.current) {
      const { shipmentDetailsRows = [], receiver, sender } = formRef.current.values;
      if (
        !shipmentDetailsRows.length ||
        !receiver?.country ||
        !receiver.postCode ||
        !sender?.country ||
        !sender.postCode
      ) {
        return false;
      }

      return shipmentDetailsRows.some((item) => item.height && item.weight && item.ldm);
    }
    return false;
  };

  const parseOfferedPrices = (offeredPrices, selectedPriceOffer) => {
    // If the price doesn't have id, fake it for selection needs
    let prices = offeredPrices?.map((price, index) => ({ ...price, id: price.id ?? index + 1 }));
    let selected;

    // Business logic described in SG-812
    if (prices?.length) {
      if (prices.length === 1) {
        selected = prices[0].id.toString();
      } else if (prices.length > 1) {
        if (selectedPriceOffer !== undefined && selectedPriceOffer !== null) {
          const selectedPrice = prices.find((price) => price.id === +selectedPriceOffer);
          if (selectedPrice) {
            selected = selectedPrice.id.toString();
            prices = [selectedPrice];
          }
        }
      }
    }

    return {
      offeredPrices: prices,
      selectedPriceOffer: selected,
    };
  };

  const calculatePrices = async () => {
    setNewShipmentContextValue((currentShipmentContextValue) => ({
      ...currentShipmentContextValue,
      automaticPricesState,
      setAutomaticPricesState,
    }));

    if (automaticPricesState === 'InProgress' && formRef.current) {
      try {
        if (canCalculatePrices()) {
          const { shipmentDetailsRows = [], receiver, sender } = formRef.current.values;
          let formData = {
            from: sender.country,
            to: receiver.country,
            weight: shipmentDetailsRows[0].totalWeight,
            amount: shipmentDetailsRows[0].quantityId,
            type: shipmentDetailsRows[0].typeId,
            length: shipmentDetailsRows[0].length,
            width: shipmentDetailsRows[0].width,
            height: shipmentDetailsRows[0].height,
            fromCountry: sender.country,
            toCountry: receiver.country,
            fromZip: sender.postCode,
            toZip: receiver.postCode,
          };
          formRef.current.setFieldValue('selectedPriceOffer', null);
          formRef.current.setFieldValue('offeredPrices', []);
          applyForPrices(formData)
            .then((response) => {
              setFormState(response.ok ? formStates.PRISTINE : formStates.ERROR);
              return response.json();
            })
            .then((data) => {
              if (data?.payload?.offeredPrices) {
                const { offeredPrices, selectedPriceOffer } =
                  parseOfferedPrices(
                    data.payload.offeredPrices,
                    formRef.current?.values.selectedPriceOffer,
                  ) ?? {};

                if (offeredPrices) {
                  formRef.current.setFieldValue('offeredPrices', offeredPrices);
                  formRef.current.setFieldValue('selectedPriceOffer', selectedPriceOffer);
                }
              } else {
                formRef.current.setFieldValue('offeredPrices', []);
                formRef.current.setFieldValue('selectedPriceOffer', null);
              }
              setAutomaticPricesState('Idle');
            })
            .catch(() => {
              setAutomaticPricesState('Idle');
            })
            .finally(() => {
              setIsPending(false);
            });
        }
      } catch (error) {
        setAutomaticPricesState('CantCalculate');
      }
    }
  };

  const onClickClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    if (formState !== formStates.SUCCESS) {
      setOpenDialog(false);
    } else {
      window.location.reload();
    }
  };

  const debouncedCalculatePrices = useDebouncedCallback(calculatePrices, calculatePricesDelay);
  useEffect(() => {
    debouncedCalculatePrices.callback();

    setNewShipmentContextValue((currentShipmentContextValue) => ({
      ...currentShipmentContextValue,
      automaticPricesState,
    }));
    // eslint-disable-next-line
  }, [automaticPricesState, setAutomaticPricesState, initialShipmentData]);

  return (
    <>
      {openDialog && (
        <Dialog
          scroll="body"
          maxWidth="md"
          open={openDialog}
          className={classes.dialog}
          onClose={onClickClose}
        >
          <DialogTitle>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('ORDER.SEND_MODAL_TITLE')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {formState === formStates.SUCCESS && (
              <Box className={classes.success} marginBottom={theme.spacing(1)}>
                <Typography variant="h4" align="center">
                  {t('ORDER.ORDER_SENT')}
                </Typography>
              </Box>
            )}
            {formState !== formStates.SUCCESS && (
              <Box>
                <Typography id="modal-modal-description">{t('ORDER.SEND_MODAL_TEXT')}</Typography>
              </Box>
            )}
            <Box textAlign="right" marginTop={theme.spacing(1)} width="99%">
              <Button
                type="button"
                onClick={() =>
                  formState !== formStates.SUCCESS
                    ? setOpenDialog(false)
                    : (window.location.href = '/')
                }
              >
                {formState !== formStates.SUCCESS ? t('ORDER.CANCEL') : t('ORDER.CLOSE')}
              </Button>
              {formState !== formStates.SUCCESS && (
                <Button
                  className={clsx(classes.button)}
                  variant="contained"
                  color="primary"
                  disabled={isPending}
                  onClick={saveData}
                  type="button"
                >
                  {!isPriceRequest ? t('ORDER.SUBMIT') : t('ORDER_FORM.SEND_QUOTATION')}
                </Button>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {/* https://developers.google.com/places/web-service/policies */}
      <div
        id="Logtech HoldingPoweredByGoogleDiv"
        className={classes.poweredByGoogle}
        image="/images/powered_by_google_on_white.png"
      />
      <Box
        bgcolor={theme.palette.common.white}
        width={1}
        pt={isLgUp ? 10 : 20}
        pb={isLgUp ? 10 : 16}
        overflow="hidden"
        {...restProps}
      >
        <Container maxWidth="lg" className={classes.container}>
          <Box px={isLgUp ? 0 : 6} py={0}>
            {isPending && <Loader cover />}
            <Formik
              initialValues={formInitialValues}
              onSubmit={emptyFunction}
              validationSchema={confirmValidationSchema}
              innerRef={formRef}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({ errors, touched, values }) => (
                <Form>
                  <NewShipmentContext.Provider value={newShipmentContextValue}>
                    {formState === formStates.ERROR && (
                      <Typography variant="h5">{t('FORM_SOMETHING_WENT_WRONG')}</Typography>
                    )}
                    <Typography variant="h1" align="left" className={classes.heading}>
                      {t('ORDER.TITLE')}
                    </Typography>
                    <OrderStep step={1} allSteps={allSteps}>
                      <Box width="100%">
                        <Typography variant="h5" marginBottom={4}>
                          {t('ORDER.YOUR_INFORMATION')}
                        </Typography>
                        <CompanyInfoStep />
                      </Box>
                    </OrderStep>
                    <OrderStep step={2} allSteps={allSteps}>
                      <Box width="100%">
                        <Typography variant="h5" marginBottom={4}>
                          {t('ORDER.ITEMS')}
                        </Typography>
                        {!loadingInitialForm && (
                          <ItemsStep errors={errors} values={values} touched={touched} />
                        )}
                      </Box>
                    </OrderStep>
                    <OrderStep step={3} allSteps={allSteps}>
                      <Box width="100%">
                        <Typography variant="h5" marginBottom={4}>
                          {t('ORDER.ADDRESSES')}
                        </Typography>
                      </Box>
                      <AddressesStep />
                    </OrderStep>
                    <OrderStep step={4} allSteps={allSteps}>
                      <Box width="100%">
                        <Typography variant="h5" marginBottom={4}>
                          {t('ORDER.DATES')}
                        </Typography>
                      </Box>
                      <DateStep />
                    </OrderStep>
                    <OrderStep step={5} allSteps={allSteps} hideBorder>
                      <Box width="100%">
                        <PriceStep />
                      </Box>
                      {!isFormSubmitted &&
                        values.offeredPrices &&
                        values.offeredPrices.length > 0 && (
                          <Box textAlign="right" marginTop={theme.spacing(1)}>
                            <Button
                              onClick={() => handleSubmit(values, false)}
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              type="button"
                              disabled={isPending}
                            >
                              {t('ORDER.SUBMIT')}
                            </Button>
                          </Box>
                        )}
                      {!isFormSubmitted &&
                        !isPending &&
                        (!values.offeredPrices || values.offeredPrices.length === 0) && (
                          <Box textAlign="right" marginTop={theme.spacing(1)}>
                            <Button
                              onClick={() => handleSubmit(values, true)}
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              type="button"
                              disabled={isPending}
                            >
                              {t('ORDER_FORM.SEND_QUOTE')}
                            </Button>
                          </Box>
                        )}
                    </OrderStep>
                  </NewShipmentContext.Provider>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
      <Footer id="footer" />
      <FooterExtra id="footer-extra" />
    </>
  );
};
export default Order;

import { useEffect, useState } from 'react';

export const useHover = ({ hoverStateChanged }) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  useEffect(() => {
    hoverStateChanged(hover);
  }, [hover, hoverStateChanged]);

  return { onMouseEnter, onMouseLeave };
};

import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { useFormikError } from 'shared/hooks/useFormikError';
import { emptyLoadingContact } from 'shared/constants/shipment/shipmentAddress';
import { CompanyAddressFields } from './CompanyAddressFields';
import { Typography } from '../../CustomMaterial/Typography/Typography';

const dummyFunction = () => {};

export const AddressAutocompleteContext = React.createContext(dummyFunction);

export const CompanyAddressEditable = ({ type, ...restProps }): ReactElement => {
  const formik = useFormikContext();
  const isError = useFormikError();
  const { t } = useTranslation();

  const name = type;

  const [showMoreInformation, setShowMoreInformation] = useState(false);

  const typeTranslation = type === 'company' ? 'COMPANY' : 'RECEIVER';

  const onAddressAutocomplete = useCallback(() => {
    setShowMoreInformation(true);
  }, [setShowMoreInformation]);

  const clearAllFields = () => {
    formik.setFieldValue(name, emptyLoadingContact);
  };

  useEffect(() => {
    if (isError(name) && !showMoreInformation) {
      setShowMoreInformation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik]);

  return (
    <Box
      width="100%"
      borderRadius={16}
      paddingTop={8}
      paddingX={6}
      paddingBottom={10}
      bgcolor={theme.palette.common.lightGrayishViolet}
      {...restProps}
    >
      <Typography variant="subtitle2" fontWeight="extraBold">
        {t('ORDER.ADDRESS.COMPANY_INFO')}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
        marginTop={6}
      >
        <Typography variant="h6">{t('SHIPMENT.ADDRESSES.ADD_MANUALLY')}:</Typography>
        <Typography link variant="caption" onClick={clearAllFields}>
          {t('SHIPMENT.ADDRESSES.CLEAR_ALL_FIELDS')}
        </Typography>
      </Box>

      <AddressAutocompleteContext.Provider value={onAddressAutocomplete}>
        <CompanyAddressFields
          name={name}
          type={type}
          typeTranslation={typeTranslation}
          showMoreInformation
        />
      </AddressAutocompleteContext.Provider>
    </Box>
  );
};

import React from 'react';

const StatsAccent = (props) => (
  <svg
    width="38"
    height="34"
    viewBox="0 0 38 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.5054 1.97168L35.5591 15.39M15.1671 12.0375L23.4379 22.6111M1.99902 28.4927L14.8505 32.3845"
      stroke="#008094"
      strokeWidth="3"
      strokeMiterlimit="2.6131"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { StatsAccent };

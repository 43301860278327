import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '../CustomMaterial/TextField/TextField';
import clsx from 'clsx';
import { theme } from '../../theme';
import { Select } from '../CustomMaterial/Select/Select';
import { MenuItem } from '@mui/material';
import { Button } from '../CustomMaterial/Button/Button';
import { makeStyles } from '@material-ui/styles';
import { useDebounce } from 'use-debounce';
import { usePlaces } from 'shared/services/fetchLoadingAddressSuggestionsFromGoogle';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useQuery } from 'shared/hooks/useQuery';

const useStyles = makeStyles((theme) => ({
  rest: {
    padding: theme.spacing(12, 8, 8, 8),
  },
  label: {
    position: 'relative',
  },
  text: {
    fontWeight: 500,
    whiteSpace: 'pre-line',
    color: theme.palette.common.white,
  },
  priceText: {
    marginLeft: theme.spacing(4),
  },
  wrapper: {
    borderRadius: 15,
    backgroundColor: '#2A3150',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4, 12, 6, 12),
  },
  heading: {
    marginTop: theme.spacing(8),
    color: '#FFFFFF',
    paddingLeft: theme.spacing(4),
    textTransform: 'uppercase',
  },
  input: {
    borderRadius: 10,
    border: '1px solid',
    backgroundColor: theme.palette.common.orderFormYellow,
    borderColor: theme.palette.common.orderFormYellow,
  },
  searchInput: {
    paddingLeft: theme.spacing(8),
  },
  searchIcon: {
    position: 'absolute',
    top: theme.spacing(9),
    left: theme.spacing(2),
    zIndex: 1,
    color: theme.palette.common.gray,
  },
  autoComplete: {
    '& .MuiOutlinedInput-root': {
      padding: theme.spacing(0.5, 3.5, 0.5, 3.5),
    },
    '& .MuiAutocomplete-clearIndicator': {
      padding: 0,
    },
  },
  autocompleteListbox: {},
  button: {
    backgroundColor: '#048295',
  },
  customSelect: {
    '& .MuiOutlinedInput-root': {
      display: 'flex',
      backgroundColor: theme.palette.common.orderFormYellow,
      borderColor: theme.palette.common.orderFormYellow,
    },
    '& .MuiSelect-select': {
      color: theme.palette.common.veryDarkGray,
    },
  },
  btnRequest: {
    display: 'block',
    width: '100%',
  },
  priceBox: {
    border: '1px solid',
    borderRadius: 10,
    borderColor: theme.palette.common.white,
    padding: theme.spacing(6, 4),
    color: theme.palette.common.white,
  },
  price: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    fontWeight: '700',
  },
}));

const OrderFormFields = ({
  errors,
  touched,
  values,
  setFieldValue,
  isPending,
  offeredPrices,
  isFormSubmitted,
  setIsFormSubmitted,
  setShowQuotationModal,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const { updateQueryParams } = useQuery();
  const classes = useStyles();
  const [googleInput, setGoogleInput] = useState('from');

  const { fetchLoadingAddressSuggestionsFromGoogle, fetchLoadingContactByPlaceId } = usePlaces();
  const [autoCompleteChoices, setAutoCompleteChoices] = useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState('');
  const [debouncedInput] = useDebounce(inputValue, 500);

  useEffect(() => {
    if (!inputValue) {
      setAutoCompleteChoices([]);
    } else {
      fetchLoadingAddressSuggestionsFromGoogle(inputValue, t('RESULT_FROM_GOOGLE'))
        .then((result) => setAutoCompleteChoices(result.sort((a, b) => a.source - b.source)))
        .catch(() => setAutoCompleteChoices([]));
    }
    // eslint-disable-next-line
  }, [debouncedInput]);

  const onAutoCompleteSelected = (choice) => {
    if (choice) {
      if (googleInput === 'from') {
        if (choice.country) formik.setFieldValue('fromCountry', choice.country);
        if (choice.postCode) formik.setFieldValue('fromZip', choice.postCode);
      }
      if (googleInput === 'to') {
        formik.setFieldValue('toCountry', choice.country);
        formik.setFieldValue('toZip', choice.postCode);
      }
    }
  };

  const startOrder = (formValues) => {
    const queryParams = {
      ...formValues,
      from: '',
      to: '',
    };
    updateQueryParams('/order', queryParams);
  };
  useEffect(() => {
    if (value && value.placeId) {
      if (googleInput === 'from') {
        formik.setFieldValue('fromPlaceId', value.placeId);
      }
      if (googleInput === 'to') {
        formik.setFieldValue('toPlaceId', value.placeId);
      }
      fetchLoadingContactByPlaceId(value).then((place) => {
        if (place) {
          onAutoCompleteSelected(place);
        }
      });
    }
    // eslint-disable-next-line
  }, [value]);

  const offeredPrice = offeredPrices && offeredPrices.length > 0 ? offeredPrices.slice(0, 1) : null;
  return (
    <>
      {/* https://developers.google.com/places/web-service/policies */}
      <div
        id="Logtech HoldingPoweredByGoogleDiv"
        className={classes.poweredByGoogle}
        image="/images/powered_by_google_on_white.png"
      />
      <Box width="40%" display="inline-flex" flexDirection={'column'}>
        <Field name="from">
          {({ field }) => (
            <>
              <InputLabel
                className={classes.label}
                id="name-label"
                error={errors.fromZip && touched.from}
              >
                <Box className={classes.searchIcon}>
                  <SearchIcon />
                </Box>
                {t('ORDER_FORM.FROM')}{' '}
                {errors.fromZip && touched.from ? ` (${errors.fromZip})` : ''}
              </InputLabel>
              <Autocomplete
                className={classes.autoComplete}
                freeSolo
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={autoCompleteChoices}
                groupBy={(option) => option.sourceTranslation}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={values.from}
                onChange={(_, newValue) => {
                  setGoogleInput('from');
                  setIsFormSubmitted(false);
                  if (typeof newValue === 'string') {
                    // User hasn't chosen any suggested options
                    setFieldValue('from', newValue);
                    setAutoCompleteChoices([]);
                  } else {
                    // User has chosen one of the suggested options
                    setValue(newValue);
                    if (!newValue) {
                      setFieldValue('from', '');
                      setFieldValue('fromCountry', '');
                      setFieldValue('fromZip', '');
                    }
                    if (newValue?.description) {
                      setFieldValue('from', newValue.description);
                    }
                  }
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    variant="variant4"
                    className={clsx(classes.input, classes.searchInput)}
                    type="text"
                    error={errors.fromZip && touched.from}
                    fullWidth
                    placeholder={t('ORDER_FORM.FORM_TO_PLACEHOLDER')}
                  />
                )}
                renderOption={(option) => option.description}
                classes={{
                  listbox: classes.autocompleteListbox,
                }}
              />
            </>
          )}
        </Field>
        <Field name="to">
          {({ field }) => (
            <>
              <InputLabel
                style={{ marginTop: theme.spacing(3) }}
                className={classes.label}
                id="name-to"
                error={errors.toZip && touched.to}
              >
                <Box className={classes.searchIcon}>
                  <SearchIcon />
                </Box>
                {t('ORDER_FORM.TO')} {errors.toZip && touched.to ? ` (${errors.toZip})` : ''}
              </InputLabel>
              <Autocomplete
                className={classes.autoComplete}
                freeSolo
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={autoCompleteChoices}
                groupBy={(option) => option.sourceTranslation}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={values.to}
                onChange={(_, newValue) => {
                  setIsFormSubmitted(false);
                  setGoogleInput('to');
                  if (typeof newValue === 'string') {
                    // User hasn't chosen any suggested options
                    setFieldValue('to', newValue);
                    setAutoCompleteChoices([]);
                  } else {
                    // User has chosen one of the suggested options
                    setValue(newValue);
                    if (!newValue) {
                      setFieldValue('to', '');
                      setFieldValue('toCountry', '');
                      setFieldValue('toZip', '');
                    }
                    if (newValue?.description) {
                      setFieldValue('to', newValue.description);
                    }
                  }
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    variant="variant4"
                    className={clsx(classes.input, classes.searchInput)}
                    type="text"
                    error={errors.toZip && touched.toZip}
                    fullWidth
                    placeholder={t('ORDER_FORM.FORM_TO_PLACEHOLDER')}
                  />
                )}
                renderOption={(option) => option.description}
                classes={{
                  listbox: classes.autocompleteListbox,
                }}
              />
            </>
          )}
        </Field>
      </Box>
      <Box
        width="40%"
        paddingLeft={theme.spacing(2)}
        display="inline-flex"
        flexDirection={'column'}
      >
        <Field name="type">
          {({ field }) => (
            <Box className={classes.customSelect}>
              <InputLabel
                className={classes.label}
                id="name-label"
                error={errors.type && touched.type}
              >
                {t('ORDER_FORM.CARGO')}
              </InputLabel>
              <Select
                {...field}
                onChange={(e) => {
                  setIsFormSubmitted(false);
                  setFieldValue('type', e.target.value);
                }}
              >
                <MenuItem value="7"> {t('ORDER_FORM.PACKAGE')}</MenuItem>
                <MenuItem value="3"> {t('ORDER_FORM.PALLET')}</MenuItem>
              </Select>
            </Box>
          )}
        </Field>
        <Box style={{ marginTop: theme.spacing(3) }}>
          <Box width="33%" display="inline-flex" flexDirection={'column'}>
            <Field name="length">
              {({ field }) => (
                <>
                  <InputLabel
                    className={classes.label}
                    id="name-label"
                    error={errors.length && touched.length}
                  >
                    {t('ORDER_FORM.MEASURES')}
                  </InputLabel>
                  <TextField
                    {...field}
                    variant={'variant4'}
                    className={classes.input}
                    type="text"
                    error={errors.length && touched.length}
                    fullWidth
                    onBlur={(e) => {
                      setIsFormSubmitted(false);
                    }}
                    placeholder={t('ORDER_FORM.LENGTH_PLACEHOLDER')}
                  />
                </>
              )}
            </Field>
          </Box>
          <Box
            width="33%"
            paddingLeft={theme.spacing(1)}
            display="inline-flex"
            flexDirection={'column'}
          >
            <Field name="width">
              {({ field }) => (
                <>
                  <InputLabel
                    className={classes.label}
                    id="name-label"
                    error={errors.width && touched.width}
                  >
                    &nbsp;
                  </InputLabel>
                  <TextField
                    {...field}
                    variant={'variant4'}
                    className={classes.input}
                    type="text"
                    error={errors.width && touched.width}
                    fullWidth
                    onBlur={(e) => {
                      setIsFormSubmitted(false);
                    }}
                    placeholder={t('ORDER_FORM.WIDTH_PLACEHOLDER')}
                  />
                </>
              )}
            </Field>
          </Box>
          <Box
            width="33%"
            paddingLeft={theme.spacing(1)}
            display="inline-flex"
            flexDirection={'column'}
          >
            <Field name="height">
              {({ field }) => (
                <>
                  <InputLabel
                    className={classes.label}
                    id="name-label"
                    error={errors.height && touched.height}
                  >
                    &nbsp;
                  </InputLabel>
                  <TextField
                    {...field}
                    variant={'variant4'}
                    className={classes.input}
                    type="text"
                    error={errors.height && touched.height}
                    fullWidth
                    onBlur={(e) => {
                      setIsFormSubmitted(false);
                    }}
                    placeholder={t('ORDER_FORM.HEIGHT_PLACEHOLDER')}
                  />
                </>
              )}
            </Field>
          </Box>
        </Box>
      </Box>
      <Box
        width="20%"
        paddingLeft={theme.spacing(2)}
        display="inline-flex"
        flexDirection={'column'}
      >
        <Field name="weight">
          {({ field }) => (
            <>
              <InputLabel
                className={classes.label}
                id="name-label"
                error={errors.weight && touched.weight}
              >
                {t('ORDER_FORM.WEIGHT')}
              </InputLabel>
              <TextField
                {...field}
                variant={'variant4'}
                className={classes.input}
                type="text"
                error={errors.weight && touched.weight}
                fullWidth
                onBlur={(e) => {
                  setIsFormSubmitted(false);
                }}
                placeholder={t('ORDER_FORM.WEIGHT_PLACEHOLDER')}
              />
            </>
          )}
        </Field>
        <Field name="amount">
          {({ field }) => (
            <>
              <InputLabel
                style={{ marginTop: theme.spacing(3) }}
                className={classes.label}
                id="name-label"
                error={errors.amount && touched.amount}
              >
                {t('ORDER_FORM.AMOUNT')}
              </InputLabel>
              <TextField
                {...field}
                variant={'variant4'}
                className={classes.input}
                type="number"
                error={errors.amount && touched.amount}
                fullWidth
                onChange={(e) => {
                  setIsFormSubmitted(false);
                  setFieldValue('amount', e.target.value);
                }}
              />
            </>
          )}
        </Field>
      </Box>
      {!isFormSubmitted && (
        <Box textAlign="right" marginTop={theme.spacing(1)}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isPending}
          >
            {t('ORDER_FORM.SUBMIT')}
          </Button>
        </Box>
      )}
      {isFormSubmitted && !offeredPrices.length && (
        <Box display="flex" alignContent="center" marginTop={theme.spacing(1)}>
          <Box display="inline-flex" justifyContent="center" alignItems="center" width="70%">
            <Typography variant="body2" className={classes.text}>
              {t('ORDER_FORM.NO_PRICES_FOUND')}
            </Typography>
          </Box>
          <Box display="inline-flex" width="30%">
            <Button
              onClick={() => setShowQuotationModal(true)}
              className={clsx(classes.button, classes.btnRequest)}
              variant="contained"
              color="primary"
              type="button"
              disabled={isPending}
            >
              {t('ORDER_FORM.SEND_QUOTE')}
            </Button>
          </Box>
        </Box>
      )}
      {isFormSubmitted && offeredPrice?.length > 0 && (
        <Box display="flex" alignContent="center" marginTop={theme.spacing(1)}>
          <Box display="inline-flex" alignItems="center" width="40%" className={classes.priceBox}>
            <LocalShippingOutlinedIcon fontSize="large" />
            <Typography variant="h5" className={clsx(classes.text, classes.priceText)}>
              {t('ORDER_FORM.PRICE')}
            </Typography>
            <Typography variant="h5" className={clsx(classes.text, classes.price)}>
              {offeredPrice[0].price.toString()} € ({t('ORDER_FORM.EXCL_VAT')})
            </Typography>
          </Box>
          <Box display="inline-flex" width="60%" justifyContent="end" alignSelf="center">
            <Button
              className={clsx(classes.button)}
              variant="contained"
              color="primary"
              type="button"
              disabled={isPending}
              onClick={() => startOrder(values)}
            >
              {t('ORDER_FORM.ORDER')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
export default OrderFormFields;

import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { Typography } from '../../CustomMaterial/Typography/Typography';
import { shipmentItemTypeLabels } from 'shared/constants/shipment/shipmentItemTypeLabels';
import { useCommonStyles } from 'shared/styles/common';
import { useHover } from 'shared/hooks/useHover';
import ShipmentItemIcon from './ShipmentItemIcon';

const ShipmentItemButton = ({ itemType, active, ...restProps }): ReactElement => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);

  const hoverMouseHandlers = useHover({ hoverStateChanged: setHover });

  return (
    <Box
      className={commonClasses.cursorPointer}
      bgcolor={active || hover ? theme.palette.common.grayAlt : 'transparent'}
      width={80}
      height={80}
      border={`1px solid ${theme.palette.common.lightGrayishViolet}`}
      borderRadius={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...hoverMouseHandlers}
      {...restProps}
    >
      <ShipmentItemIcon itemType={itemType} active={active || hover} />
      <Typography variant="caption" fontWeight="bold">
        {t(shipmentItemTypeLabels[itemType])}
      </Typography>
    </Box>
  );
};

export default ShipmentItemButton;

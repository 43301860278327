import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionSummary, CareersItem } from 'components';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { generateKey } from 'shared/functions/generateKey';
import { DropdownIcon } from 'components/Icons/DropdownIcon/DropdownIcon';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 700,
  },
  normal: {
    fontWeight: 400,
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
}));

const CareersListing = ({ className, careersData = [] }) => {
  const classes = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [expanded, setExpanded] = useState(careersData.length === 1 ? [careersData[0].id] : []);

  const handleChange = (id) => (_event, newExpanded) => {
    if (newExpanded && !expanded.includes(id)) {
      setExpanded([...expanded, id]);
    } else if (!newExpanded) {
      setExpanded(expanded.filter((item) => item !== id));
    }
  };

  return (
    <Box className={className}>
      {careersData.map((item, index) => (
        <Accordion
          key={generateKey(index, 'careers_data_item')}
          square
          expanded={expanded.includes(item.id)}
          onChange={handleChange(item.id)}
          TransitionProps={{ timeout: 200 }}
        >
          <AccordionSummary>
            <Box display="flex" justifyContent="space-between" alignItems="center" width={1}>
              {isLgUp ? (
                <Box display="flex">
                  <Typography variant="h5">{item.title}:&nbsp;</Typography>
                  <Typography
                    variant="h5"
                    className={classes.normal}
                  >{`${item.workingTime} / ${item.workingRemote} / ${item.location}`}</Typography>
                </Box>
              ) : (
                <Typography variant="subtitle1" className={classes.bold}>
                  {item.title}
                </Typography>
              )}
              <DropdownIcon
                variant="dark"
                width={20}
                height={10}
                className={expanded.includes(item.id) ? classes.rotated : undefined}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <CareersItem data={item} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

CareersListing.defaultProps = {
  className: undefined,
};

CareersListing.propTypes = {
  className: PropTypes.string,
  careersData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { CareersListing };

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';

import { AddressEditable } from 'components';
import { Typography } from '../../CustomMaterial/Typography/Typography';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.common.gray,
  },
});

export const AddressesStep = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const formik = useFormikContext();

  /* const swapAddresses = () => {
    const receiverAddressData = {
      address: formik.values.receiver ?? {
        addressLine1: '',
        city: '',
        companyName: '',
        contactPersonName: '',
        country: '',
        email: '',
        notes: '',
        phone: '',
        postCode: '',
        reference: '',
      },
      alternativeAddress: formik.values.receiverAlternative,
    };

    const senderAddressData = {
      address: formik.values.sender ?? {
        addressLine1: '',
        city: '',
        companyName: '',
        contactPersonName: '',
        country: '',
        email: '',
        notes: '',
        phone: '',
        postCode: '',
        reference: '',
      },
      alternativeAddress: formik.values.senderAlternative,
    };

    formik.setFieldValue('sender', { ...receiverAddressData.address });
    formik.setFieldValue(
      'senderAlternative',
      receiverAddressData.alternativeAddress
        ? { ...receiverAddressData.alternativeAddress }
        : undefined,
    );

    formik.setFieldValue('receiver', { ...senderAddressData.address });
    formik.setFieldValue(
      'receiverAlternative',
      senderAddressData.alternativeAddress
        ? { ...senderAddressData.alternativeAddress }
        : undefined,
    );
  }; */

  return (
    <Box>
      <Typography variant="body2">
        {t('SHIPMENT.ADDRESSES.REQUIRED_FIELDS_ARE_MARKED_WITH')}{' '}
        <span className={classes.asterisk}>*</span>
      </Typography>
      <Box width="100%" marginTop={3}>
        <Alert icon={false} color="info">
          {t('ORDER.ADDRESS_TOOLTIP')}
        </Alert>
      </Box>
      <Box width="100%" display="flex" marginTop={4.5} position="relative">
        <AddressEditable type="sender" marginRight={6} />

        <AddressEditable type="receiver" />
      </Box>
    </Box>
  );
};

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import { Field, Form, Formik } from 'formik';
import { formStates } from 'shared/constants/formStates';
import * as Yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '../CustomMaterial/TextField/TextField';
import { applyPriceQuotation } from 'shared/services/applyPriceQuotation';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-rounded': {
      padding: '0px 40px 20px 40px',
      boxShadow: theme.shadows[1],
    },
    '& .MuiDialogTitle-root': {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 500,
    },
    '& .MuiDialog-paperWidthMd': {
      maxWidth: 700,
    },
    '& .MuiDialog-paperWidthLg': {
      maxWidth: 992,
    },
    '& .MuiDialog-scrollBody': {
      [theme.breakpoints.down(992)]: {
        overflowX: 'scroll',
      },
    },
  },
  button: {
    backgroundColor: '#048295 !important',
  },
  input: {
    borderRadius: 10,
    border: '1px solid',
    borderColor: theme.palette.common.orderFormYellow,
  },
  label: {
    color: theme.palette.common.black,
  },
  success: {
    border: '1px solid',
    borderColor: theme.palette.common.darkCyan,
    padding: 12,
    backgroundColor: theme.palette.common.darkCyan,
    color: theme.palette.common.white,
  },
}));

const initialValues = {
  contactName: '',
  companyName: '',
  contactPhone: '',
  contactEmail: '',
};

const OrderQuotationModal = ({ open, handleClose, formValues }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [isPending, setIsPending] = useState(false);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().min(2, t('INVALID_NAME')).required(),
    contactName: Yup.string().min(2, t('INVALID_NAME')).required(),
    contactEmail: Yup.string().min(2, t('INVALID_MEASURE')).required().email(),
    contactPhone: Yup.string().min(2, t('INVALID_PHONE')).required(),
  });

  const handleSubmit = (formData, { setSubmitting }) => {
    setIsPending(true);
    let quotationData = formValues;
    quotationData.email = formData.contactEmail;
    quotationData.phone = formData.contactPhone;
    quotationData.name = formData.contactName;
    quotationData.companyName = formData.companyName;
    quotationData.language = i18n.language;
    applyPriceQuotation(quotationData)
      .then((response) => {
        setFormState(response.ok ? formStates.SUCCESS : formStates.ERROR);
        return response.json();
      })
      .catch(() => {
        setFormState(formStates.ERROR);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPending(false);
      });
  };

  const onClickClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    if (formState !== formStates.SUCCESS) {
      handleClose();
    } else {
      window.location.reload();
    }
  };
  return (
    <>
      <Dialog
        scroll="body"
        maxWidth="md"
        open={open}
        onClose={onClickClose}
        className={classes.dialog}
        disableBackdropClick
      >
        <DialogTitle>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('ORDER_FORM.SEND_QUOTATION_TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {formState === formStates.SUCCESS && (
            <Box className={classes.success} marginBottom={theme.spacing(1)}>
              <Typography variant="h4" align="center">
                {t('ORDER_FORM.QUOTATION_SENT')}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t('ORDER_FORM.SEND_QUOTATION_INFORMATION')}
            </Typography>
            <Box marginTop={theme.spacing(1)}>
              <strong>{t('ORDER_FORM.FROM')}:</strong> {formValues.from}
            </Box>
            <Box marginTop={theme.spacing(0.5)}>
              <strong>{t('ORDER_FORM.TO')}:</strong> {formValues.to}
            </Box>
            <Box marginTop={theme.spacing(0.5)}>
              <strong>{t('ORDER_FORM.CARGO')}:</strong> {formValues.amount} x{' '}
              {formValues.type === '7' ? t('ORDER_FORM.PACKAGE') : t('ORDER_FORM.PALLET')}
            </Box>
            <Box marginTop={theme.spacing(0.5)}>
              <strong>{t('ORDER_FORM.WEIGHT')}:</strong> {formValues.weight} kg
            </Box>
            <Box marginTop={theme.spacing(0.5)} marginBottom={theme.spacing(1)}>
              <strong>{t('ORDER_FORM.MEASURES')}:</strong> {formValues.length} cm x{' '}
              {formValues.width} cm x {formValues.height} cm
            </Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  {formState === formStates.ERROR && (
                    <Typography variant="h5">{t('FORM_SOMETHING_WENT_WRONG')}</Typography>
                  )}
                  <Typography variant="h6" component="h2">
                    {t('ORDER_FORM.YOUR_CONTACT')}
                  </Typography>
                  <Box width="99%" marginTop={theme.spacing(0.5)}>
                    <Field name="companyName">
                      {({ field }) => (
                        <>
                          <InputLabel
                            className={classes.label}
                            id="name-label"
                            error={errors.companyName && touched.companyName}
                          >
                            {t('ORDER_FORM.CONTACT_COMPANY')} *
                          </InputLabel>
                          <TextField
                            {...field}
                            variant={'variant4'}
                            className={classes.input}
                            type="text"
                            error={errors.companyName && touched.companyName}
                            fullWidth
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box width="99%" marginTop={theme.spacing(0.5)}>
                    <Field name="contactName">
                      {({ field }) => (
                        <>
                          <InputLabel
                            className={classes.label}
                            id="name-label"
                            error={errors.contactName && touched.contactName}
                          >
                            {t('ORDER_FORM.CONTACT_NAME')} *
                          </InputLabel>
                          <TextField
                            {...field}
                            variant={'variant4'}
                            className={classes.input}
                            type="text"
                            error={errors.contactName && touched.contactName}
                            fullWidth
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box
                    width="48%"
                    display="inline-flex"
                    flexDirection={'column'}
                    marginTop={theme.spacing(1)}
                  >
                    <Field name="contactEmail">
                      {({ field }) => (
                        <>
                          <InputLabel
                            className={classes.label}
                            id="name-label"
                            error={errors.contactEmail && touched.contactEmail}
                          >
                            {t('ORDER_FORM.CONTACT_EMAIL')} *
                          </InputLabel>
                          <TextField
                            {...field}
                            variant={'variant4'}
                            className={classes.input}
                            type="text"
                            error={errors.contactEmail && touched.contactEmail}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box
                    width="48%"
                    display="inline-flex"
                    flexDirection={'column'}
                    marginLeft={theme.spacing(1)}
                    marginTop={theme.spacing(1)}
                  >
                    <Field name="contactPhone">
                      {({ field }) => (
                        <>
                          <InputLabel
                            className={classes.label}
                            id="name-label"
                            error={errors.contactPhone && touched.contactPhone}
                          >
                            {t('ORDER_FORM.CONTACT_PHONE')} *
                          </InputLabel>
                          <TextField
                            {...field}
                            variant={'variant4'}
                            className={classes.input}
                            type="text"
                            error={errors.contactPhone && touched.contactPhone}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box textAlign="right" marginTop={theme.spacing(1)} width="99%">
                    <Button type="button" onClick={(event) => onClickClose(event, 'close')}>
                      {formState !== formStates.SUCCESS
                        ? t('ORDER_FORM.CANCEL_QUOTATION')
                        : t('ORDER_FORM.CLOSE')}
                    </Button>
                    {formState !== formStates.SUCCESS && (
                      <Button
                        className={clsx(classes.button)}
                        variant="contained"
                        color="primary"
                        disabled={isPending}
                        type="submit"
                      >
                        {t('ORDER_FORM.SEND_QUOTATION')}
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export { OrderQuotationModal };

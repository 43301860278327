import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { CompanyAddressEditable } from 'components';
import { Typography } from '../../CustomMaterial/Typography/Typography';

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.common.gray,
  },
});

export const CompanyInfoStep = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body2">
        {t('SHIPMENT.ADDRESSES.REQUIRED_FIELDS_ARE_MARKED_WITH')}{' '}
        <span className={classes.asterisk}>*</span>
      </Typography>

      <Box width="75%" display="flex" marginTop={4.5} position="relative">
        <CompanyAddressEditable type="company" marginRight={6} />
      </Box>
    </Box>
  );
};

import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import { generateKey } from 'shared/functions/generateKey';
import { ItemEditable } from '../ItemEditable';
import { shipmentItemTypesToSubTypes } from 'shared/functions/shipments/shipmentItemTypesMapping';
import { ShipmentItemSubType, ShipmentItemType } from 'shared/types/shipments/shipmentItem';
import ShipmentItemButton from './ShipmentItemButton';
import { theme } from 'theme';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ShipmentItemTotals from './ShipmentItemTotals';
import { Checkbox } from '../../CustomMaterial/Checkbox/Checkbox';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  checkbox: {
    color: theme.palette.common.lightGrayishVioletAlt,
    padding: 0,
    marginRight: theme.spacing(3),
  },
}));
const supportedItems = [
  ShipmentItemType.Pallet,
  ShipmentItemType.Box,
  ShipmentItemType.Truck,
  ShipmentItemType.Container,
  ShipmentItemType.Other,
];

export const ItemsStep = ({ values, errors, touched, allowMultiAdd = false }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const shipmentItems = formik.values.shipmentDetailsRows ?? [];
  const noItemsError =
    (!Array.isArray(formik.errors.shipmentDetailsRows) && formik.errors.shipmentDetailsRows) ??
    null;

  const shipmentItemButtonClicked = (itemType) => {
    if (shipmentItems.length < 1) {
      formik.setFieldError('shipmentDetailsRows', '');
    }

    if (itemType in shipmentItemTypesToSubTypes) {
      const newItem = {
        typeId: shipmentItemTypesToSubTypes[itemType][0],
        quantityId: 1,
      };

      if (newItem.typeId?.toString() === ShipmentItemSubType.FullTruckLoad.toString()) {
        newItem.ldm = 13.6;
      }

      formik.setFieldValue('shipmentDetailsRows', [...shipmentItems, newItem]);
    }
  };

  let totalWeight = 0;
  let totalLdm = 0;
  let totalVolume = 0;
  shipmentItems.forEach((item) => {
    if (item.totalWeight) totalWeight = totalWeight + parseFloat(item.totalWeight.toString()) ?? 0;
    if (item.volume) totalVolume = totalVolume + parseFloat(item.volume.toString()) ?? 0;
    if (item.ldm) totalLdm = totalLdm + parseFloat(item.ldm.toString()) ?? 0;
  });

  const content = (arrayHelpers) => (
    <Box width="100%">
      {allowMultiAdd && (
        <>
          {' '}
          <Typography variant="body2" fontWeight="semibold">
            {t('SHIPMENT.CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD')}:
          </Typography>
          <Box display="flex" marginTop={3} className={clsx(noItemsError && 'Mui-error')}>
            {supportedItems.map((itemType, index) => (
              <ShipmentItemButton
                key={generateKey(index, 'shipment_item_button')}
                itemType={itemType}
                marginRight={4}
                marginBottom={4}
                onClick={() => {
                  arrayHelpers.insert(shipmentItems.length, {});
                  shipmentItemButtonClicked(itemType);
                }}
                borderColor={noItemsError ? theme.palette.common.alertError : undefined}
              />
            ))}
          </Box>
        </>
      )}
      <Box display="flex" flexDirection="column-reverse">
        {shipmentItems.map((_, index) => (
          <Box key={generateKey(index, 'shipment_item_editable')}>
            <ItemEditable
              name={`shipmentDetailsRows[${index}]`}
              index={index}
              onDelete={allowMultiAdd ? () => arrayHelpers.remove(index) : undefined}
            />
          </Box>
        ))}
      </Box>
      {allowMultiAdd && shipmentItems.length > 0 && (
        <ShipmentItemTotals
          totalLdm={totalLdm}
          totalVolume={totalVolume}
          totalWeight={totalWeight}
        />
      )}
      {allowMultiAdd && (
        <>
          <Box display="flex" alignItems="center" marginBottom={6}>
            <Field name="tailLiftTruck">
              {({ field }) => (
                <Checkbox {...field} variant={'light'} classes={{ root: classes.checkbox }} />
              )}
            </Field>
            <Typography variant="body2">
              {t('SHIPMENT.I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK')}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );

  return <FieldArray name="shipmentDetailsRows" render={content} />;
};

import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: ({ bgcolorHover, iconFillHover }: StyleProps) => ({
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: bgcolorHover,
      '& svg path': {
        fill: iconFillHover,
      },
    },
  }),
});

export const IconWrapper: FC = ({
  width = 32,
  height = 32,
  bgcolorHover,
  iconFillHover,
  className,
  children,
  ...restProps
}): ReactElement => {
  const classes = useStyles({ bgcolorHover, iconFillHover });

  return (
    <Box
      width={width}
      minWidth={width}
      height={height}
      minHeight={height}
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.root, className)}
      {...restProps}
    >
      {children}
    </Box>
  );
};

import React, { Suspense, useState, lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import isFunction from 'lodash/isFunction';

import { generateKey } from 'shared/functions/generateKey';
import { theme } from 'theme';
import { routes } from 'routes';

import Box from '@material-ui/core/Box';

import CookieBanner from 'react-cookie-banner';

import { CookieConsent, Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { AppContent } from 'AppContent';
import { defaultLanguage } from 'shared/constants/languages';

const NotFound = lazy(() => import('./scenes/NotFound/NotFound'));

const RouteLoadingFallback = (
  <Box height="100vh" width="100vw" bgcolor={theme.palette.primary.main}>
    <Loader cover noBackground color="secondary" />
  </Box>
);

const App = () => {
  const [routeLoaded, setRouteLoaded] = useState(false);
  const { t, i18n } = useTranslation();

  const routeBase = i18n.language !== defaultLanguage ? '/:locale' : '';

  const routeMapper = ({ path, component, exact, environment, ...rest }, index) =>
    component && (
      <Route
        key={generateKey(index, (path.length ? path[0] : path).replace('/', '-'))}
        path={
          Array.isArray(path)
            ? path.map((pathItem) => `${routeBase}${pathItem}`)
            : `${routeBase}${path}`
        }
        component={!isFunction(component) ? component : undefined}
        render={
          isFunction(component)
            ? (props) => component({ props, viewLoadedCallback: setRouteLoaded })
            : undefined
        }
        exact={exact}
        environment={environment}
        {...rest}
      />
    );

  const renderRoutes = (
    <Suspense fallback={RouteLoadingFallback}>
      <Switch>
        {i18n.language !== defaultLanguage && <Redirect exact from="/" to={`/${i18n.language}`} />}
        {routes.map(routeMapper)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
      >
        <title>{t('PAGE_TITLE')}</title>
        <meta name="description" content={t('PAGE_DESCRIPTION')} />
      </Helmet>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppContent routes={renderRoutes} isRouteLoaded={routeLoaded} />
      </BrowserRouter>
      <CookieBanner cookie="user-has-accepted-cookies" dismissOnScroll={false}>
        {(onAccept) => <CookieConsent onAccept={onAccept} zIndex={10} />}
      </CookieBanner>
    </ThemeProvider>
  );
};

export default App;

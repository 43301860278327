import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';

import { Button } from 'components';

const cookieNoticeUrl = process.env.REACT_APP_COOKIE_NOTICE_URL || '/cookie-notice';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
  },
  rejectButton: {
    marginRight: theme.spacing(6),
    textDecoration: 'underline',
    padding: theme.spacing(0),
    '&:hover': {
      background: 'none',
    },
    visibility: 'hidden',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const CookieConsent = ({ onAccept, onReject, ...restProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      bgcolor={theme.palette.common.strongViolet}
      position="fixed"
      bottom={0}
      width={1}
      py={isLgUp ? 11 : 8}
      overflow="hidden"
      {...restProps}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Box display="flex" width={1}>
          {isLgUp && <img alt="cookie" src="/images/cookie.svg" />}
          <Box
            ml={6}
            mr={isLgUp ? 0 : 6}
            width={1}
            display={isLgUp ? 'block' : 'flex'}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h5" color="textSecondary" className={classes.heading}>
              {t('THIS_WEBSITE_USES_COOKIES')}
            </Typography>
            <Box
              display="flex"
              justifyContent={isLgUp ? 'space-between' : 'center'}
              flexDirection={isLgUp ? 'row' : 'column'}
              width={1}
            >
              <Box maxWidth={666} mb={isLgUp ? 0 : 8}>
                <Typography variant="body2" color="textSecondary">
                  {t('WE_USE_COOKIES')}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="a"
                  href={cookieNoticeUrl}
                >
                  {t('COOKIE_POLICY_FILE_NAME')}
                </Typography>
              </Box>
              <Box
                display={isLgUp ? 'block' : 'flex'}
                flexDirection="column-reverse"
                justifyContent="center"
              >
                <Button
                  variant="text"
                  color="invert"
                  disableRipple
                  disableElevation
                  className={classes.rejectButton}
                  onClick={onReject}
                  disabled
                >
                  {t('REJECT_COOKIES')}
                </Button>
                <Button variant="contained" color="primary" onClick={onAccept}>
                  OK
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

CookieConsent.defaultProps = {
  onAccept: () => null,
  onReject: () => null,
};

CookieConsent.propTypes = {
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

export { CookieConsent };

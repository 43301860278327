import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const AccentVariant1 = ({ className, color, opacity }) => (
  <svg
    className={className}
    width="747"
    height="346"
    viewBox="0 0 747 346"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256.4 336.732L387.932 182.268C394.05 176.089 394.05 166.821 390.991 160.643L329.813 12.3571C326.754 3.08929 317.577 0 311.46 0H78.9845C63.69 0 54.5134 15.4464 60.6311 30.8929L115.691 160.643C118.75 166.821 118.75 176.089 112.632 182.268L5.57121 308.929C-6.66433 324.375 2.51233 346 20.8656 346H244.164C247.223 342.911 253.341 339.821 256.4 336.732Z"
      opacity={opacity}
      fill={color}
    />
    <path
      d="M610.4 336.732L741.932 182.268C748.05 176.089 748.05 166.821 744.991 160.643L683.813 12.3571C680.754 3.08928 671.577 0 665.46 0H432.984C417.69 0 408.513 15.4464 414.631 30.8929L469.691 160.643C472.75 166.821 472.75 176.089 466.632 182.268L359.571 308.929C347.336 324.375 356.512 346 374.866 346H598.164C601.223 342.911 607.341 339.821 610.4 336.732Z"
      opacity={opacity}
      fill={color}
    />
  </svg>
);

AccentVariant1.defaultProps = {
  className: undefined,
  color: theme.palette.common.grayishViolet,
  opacity: 0.1,
};

AccentVariant1.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { AccentVariant1 };

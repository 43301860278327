import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';

const useStyles = makeStyles({
  root: {
    fontWeight: 800,
    fontSize: '3.125rem',
    lineHeight: '4.25rem',
    color: theme.palette.common.lightGrayishVioletAlt,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(9),
    },
  },
});

const Index = ({ value, background, ...props }) => {
  const classes = useStyles();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      className={classes.root}
      borderRadius={50}
      bgcolor={background}
      width={isSmDown ? 50 : 70}
      height={isSmDown ? 50 : 70}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {value}
    </Box>
  );
};

Index.defaultProps = {
  background: theme.palette.common.veryLightGray,
};

Index.propTypes = {
  value: PropTypes.number.isRequired,
  background: PropTypes.string,
};

export { Index };

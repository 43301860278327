import React, { forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, TextFieldFormik } from 'components';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactDatePicker from 'react-datepicker';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import { theme } from 'theme';
import {
  generateDate,
  getMidnightDate,
  toLocal,
  toUTC,
  parseISOWithoutTimeZone,
  getToday,
} from 'shared/functions/date';
import { getDateLocale } from 'shared/functions/getDateLocale';
import { useFormikContext } from 'formik';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

const dateSeparator = ' - ';

const CustomInput = forwardRef(
  (
    {
      isRangePicker,
      variant,
      name,
      userInputValue,
      handleOnChangeInput,
      handleOnBlurInput,
      handleOnCalendarIconClick,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <div ref={ref}>
        <TextFieldFormik
          {...restProps}
          placeholder={
            isRangePicker
              ? `${t('DATEPICKER_PLACEHOLDER')}${dateSeparator}${t('DATEPICKER_PLACEHOLDER')}`
              : t('DATEPICKER_PLACEHOLDER')
          }
          variant={variant}
          name={name}
          key={`date_picker_textfield_${name}`}
          onChange={handleOnChangeInput}
          onBlur={handleOnBlurInput}
          InputProps={{
            endAdornment: (
              <InputAdornment className="pointer" position="end">
                <CalendarIcon
                  fill={theme.palette.common.darkCyan}
                  width={15}
                  height={14}
                  onClick={handleOnCalendarIconClick}
                />
              </InputAdornment>
            ),
          }}
          value={userInputValue}
          showErrorLabel
          debounced
        />
      </div>
    );
  },
);

export const DatePicker = ({
  onDateChange,
  isRangePicker,
  startDateName,
  endDateName,
  initialStartDateISO,
  initialEndDateISO,
  disablePast,
  ...restProps
}): ReactElement => {
  const { i18n } = useTranslation();

  const formik = useFormikContext();

  const startDateField = startDateName ? formik.getFieldProps(startDateName) : null;
  const startDateFieldHelpers = startDateName ? formik.getFieldHelpers(startDateName) : null;

  const endDateField = endDateName ? formik.getFieldProps(endDateName) : null;
  const endDateFieldHelpers = endDateName ? formik.getFieldHelpers(endDateName) : null;

  const [startDate, setStartDate] = useState(getMidnightDate(startDateField?.value));
  const [endDate, setEndDate] = useState(getMidnightDate(endDateField?.value));

  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');

  const [dateLocale, setDateLocale] = useState();
  const [today] = useState(getToday());

  useEffect(() => {
    getDateLocale(i18n.language || 'en-GB').then((locale) => setDateLocale(locale));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const ref = useRef(null);

  const resetValues = () => {
    setStartDate(null);
    setEndDate(null);
    setFormattedDate('');
  };

  const handleOnChangeInput = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const re = /^[\d . -]+$/;
    const { value: inputValue } = event.target;
    if (inputValue === '' || re.test(inputValue)) {
      setFormattedDate(inputValue);
    }
    setToggleCalendar(false);
  };

  const validateInput = (datesStr) => {
    try {
      const [start, end] = datesStr
        .split(dateSeparator)
        .map((dateStr) => parse(dateStr, 'dd.MM.yyyy', new Date()));

      if (!isValid(start) || (isRangePicker && end !== undefined && !isValid(end))) {
        throw new Error('invalid date');
      } else if (isRangePicker && start > end) {
        throw new Error('start date is later than the end date');
      } else if (disablePast && start < today) {
        throw new Error('start date is earlier than today');
      }

      setStartDate(toUTC(start) || null);
      setEndDate(toUTC(end) || null);
      setFormattedDate(datesStr);
    } catch (error) {
      resetValues();
    }
  };

  const handleOnBlurInput = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validateInput(event.target.value);
    if (!event.target.value) {
      resetValues();
    }
  };

  const formatDate = (start, end) => {
    if (start && end) {
      const dateStartString = start ? generateDate(start) : '';
      const dateEndString = end ? generateDate(end) : '';

      const datesString = dateEndString
        ? `${dateStartString}${dateSeparator}${dateEndString}`
        : dateStartString;

      setFormattedDate(datesString);
    } else if (start) {
      setFormattedDate(generateDate(start));
    }
  };

  const handleOnChangeDate = (date) => {
    if (date) {
      if (Array.isArray(date)) {
        const [start, end] = date;
        setStartDate(toUTC(start));
        setEndDate(toUTC(end));
      } else {
        setStartDate(toUTC(date));
      }
    }
  };

  useEffect(() => {
    if (onDateChange) {
      onDateChange(startDate, endDate);
    }

    formatDate(startDate, endDate);

    if (startDateName) {
      startDateFieldHelpers.setValue(startDate ? startDate.toISOString() : null);
    }
    if (endDateName) {
      endDateFieldHelpers.setValue(endDate ? endDate.toISOString() : null);
    }

    if (startDate && (!isRangePicker || (endDate && isRangePicker))) {
      setToggleCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (!startDateField?.value && startDate) {
      setStartDate(null);
    }
    if (!endDateField?.value && endDate) {
      setEndDate(null);
    }
    if (!startDateField?.value && !endDateField?.value) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateField?.value, endDateField?.value]);

  useEffect(() => {
    const initialStartDate = initialStartDateISO
      ? parseISOWithoutTimeZone(initialStartDateISO)
      : null;
    const initialEndDate = initialEndDateISO ? parseISOWithoutTimeZone(initialEndDateISO) : null;

    handleOnChangeDate(
      initialStartDate && initialEndDate ? [initialStartDate, initialEndDate] : initialStartDate,
    );
  }, [initialStartDateISO, initialEndDateISO]);

  const renderDayContents = (day: number) => {
    return (
      <div className="calendar-day-wrapper">
        <div className="calendar-day-today-border" />
        <span className="calendar-day">{day}</span>
      </div>
    );
  };

  const onClickOutsideCalendar = () => {
    setToggleCalendar(false);
    validateInput(formattedDate);
  };

  const formatWeekDay = (dayName) => dayName.substr(0, 1);

  const isError =
    !!(startDateName && formik.errors[startDateName]) ||
    !!(endDateName && formik.errors[endDateName]);
  const { t } = useTranslation();
  return (
    <div ref={ref} className="datepicker-range">
      <ReactDatePicker
        locale={dateLocale}
        dateFormat={t('DATEPICKER_PLACEHOLDER')}
        formatWeekDay={formatWeekDay}
        open={toggleCalendar}
        onClickOutside={onClickOutsideCalendar}
        selected={toLocal(startDate)}
        startDate={toLocal(startDate)}
        endDate={toLocal(endDate)}
        customInput={
          <>
            <CustomInput
              {...restProps}
              isRangePicker={isRangePicker}
              handleOnBlurInput={handleOnBlurInput}
              handleOnCalendarIconClick={() => setToggleCalendar(true)}
              handleOnChangeInput={handleOnChangeInput}
              userInputValue={formattedDate}
              error={isError}
              name={startDateName}
            />
          </>
        }
        selectsRange={isRangePicker}
        disabledKeyboardNavigation
        renderDayContents={renderDayContents}
        onChange={handleOnChangeDate}
        popperPlacement="bottom-start"
        popperModifiers={{
          flip: {
            behavior: ['left'], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
        minDate={disablePast ? today : undefined}
      />
    </div>
  );
};

import React, { ReactElement } from 'react';

import MaterialChip from '@material-ui/core/Chip';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useNativeStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.lightGrayishVioletAlt2,
    padding: theme.spacing(0.5, 3),
    marginTo: theme.spacing(-1),
    '&:hover': {
      backgroundColor: theme.palette.common.verySoftViolet,
    },
  },
  label: {
    padding: theme.spacing(0),
    color: theme.palette.common.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
}));

export const Chip = (props): ReactElement => {
  const nativeClasses = useNativeStyles();

  return <MaterialChip classes={nativeClasses} {...props} />;
};

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';
import { generateKey } from 'shared/functions/generateKey';
import clsx from 'clsx';

const useStyles = makeStyles({
  separator: {
    height: '13px',
    position: 'relative',
    borderRight: `1px solid ${theme.palette.common.mostlyDesaturatedDarkViolet}`,
    margin: theme.spacing(0, 2.25),
  },
  language: {
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  selected: {
    fontWeight: 700,
  },
});

const LanguageSwitcher = ({
  className,
  textColor,
  selectedLanguage,
  languages,
  onLanguageChange,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...restProps}>
      {languages.map(({ code: language, label }, index) => (
        <React.Fragment key={generateKey(index, 'language-switcher-item')}>
          <Typography
            variant="body2"
            color={textColor}
            component="p"
            className={clsx(classes.language, language === selectedLanguage && classes.selected)}
            onClick={() => onLanguageChange(language)}
          >
            {label}
          </Typography>
          {index < languages.length - 1 && <div className={classes.separator} />}
        </React.Fragment>
      ))}
    </Box>
  );
};

LanguageSwitcher.defaultProps = {
  className: undefined,
  textColor: 'textSecondary',
};

LanguageSwitcher.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string, label: PropTypes.string }))
    .isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  textColor: PropTypes.oneOf([
    'inherit',
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
  className: PropTypes.string,
};

export { LanguageSwitcher };

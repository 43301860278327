import React, { ReactElement } from 'react';

export const TruckIcon = ({
  width = 16,
  height = 14,
  fill = '#858585',
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.4375 10.3281C14.6667 10.8385 14.7188 11.349 14.5938 11.8594C14.4792 12.3698 14.2031 12.7969 13.7656 13.1406C13.3385 13.4948 12.8594 13.6719 12.3281 13.6719C11.8073 13.6719 11.3281 13.4948 10.8906 13.1406C10.4531 12.7969 10.1719 12.3698 10.0469 11.8594C9.93229 11.349 9.98958 10.8385 10.2188 10.3281H5.78125C6.01042 10.8385 6.0625 11.349 5.9375 11.8594C5.82292 12.3698 5.54688 12.7969 5.10938 13.1406C4.67188 13.4948 4.1875 13.6719 3.65625 13.6719C3.13542 13.6719 2.65625 13.4948 2.21875 13.1406C1.79167 12.7969 1.51562 12.3698 1.39062 11.8594C1.27604 11.349 1.33333 10.8385 1.5625 10.3281H0.671875C0.484375 10.3281 0.322917 10.2656 0.1875 10.1406C0.0625 10.0156 0 9.85938 0 9.67188V1C0 0.8125 0.0625 0.65625 0.1875 0.53125C0.322917 0.40625 0.484375 0.338542 0.671875 0.328125H10.6719C10.8594 0.338542 11.0156 0.40625 11.1406 0.53125C11.2656 0.65625 11.3281 0.8125 11.3281 1V3.67188H13.3281C13.5156 3.67188 13.6719 3.73438 13.7969 3.85938L15.7969 5.85938C15.9323 5.99479 16 6.15104 16 6.32812V9.67188C16 9.85938 15.9323 10.0156 15.7969 10.1406C15.6719 10.2656 15.5156 10.3281 15.3281 10.3281H14.4375ZM10 4.32812V1.67188H1.32812V9H10V4.32812ZM11.3281 5V9H14.6719V6.60938L13.0625 5H11.3281ZM3.67188 12.3281C3.95312 12.3281 4.1875 12.2344 4.375 12.0469C4.5625 11.8594 4.65625 11.625 4.65625 11.3438C4.65625 11.0521 4.5625 10.8125 4.375 10.625C4.1875 10.4375 3.94792 10.3438 3.65625 10.3438C3.375 10.3438 3.14062 10.4375 2.95312 10.625C2.76562 10.8125 2.67188 11.0521 2.67188 11.3438C2.67188 11.625 2.76562 11.8594 2.95312 12.0469C3.14062 12.2344 3.38021 12.3281 3.67188 12.3281ZM12.3281 12.3281C12.6198 12.3281 12.8594 12.2344 13.0469 12.0469C13.2344 11.8594 13.3281 11.625 13.3281 11.3438C13.3281 11.0521 13.2344 10.8125 13.0469 10.625C12.8594 10.4375 12.6198 10.3438 12.3281 10.3438C12.0469 10.3438 11.8125 10.4375 11.625 10.625C11.4375 10.8125 11.3438 11.0521 11.3438 11.3438C11.3438 11.625 11.4375 11.8594 11.625 12.0469C11.8125 12.2344 12.0469 12.3281 12.3281 12.3281Z"
        fill={fill}
      />
    </svg>
  );
};

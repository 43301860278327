import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SwapIcon } from 'components';
import { theme } from 'theme';
import clsx from 'clsx';
import { useCommonStyles } from 'shared/styles/common';
import { Typography } from '../CustomMaterial/Typography/Typography';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.common.slightlyDesaturatedCyanAlt,
    },
  },
});

export const SwapButton = ({ className, ...restProps }): ReactElement => {
  const { t } = useTranslation();
  const customClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <Box
      width={48}
      height={48}
      borderRadius={12}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.common.gray}
      boxShadow={2}
      className={clsx(customClasses.cursorPointer, classes.root, className)}
      {...restProps}
    >
      <SwapIcon />
      <Typography variant="caption">{t('SWAP')}</Typography>
    </Box>
  );
};

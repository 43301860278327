import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';

export const toUTC = (date: Date | null) => {
  return date ? addMinutes(date, -date.getTimezoneOffset()) : null;
};

export const toLocal = (date: Date | null) => {
  return date ? addMinutes(date, date.getTimezoneOffset()) : null;
};

export const parseISOWithoutTimeZone = (date: string): Date | null => {
  return toUTC(parseISO(date));
};

export const hmToMinutes = (hm: string | null) => {
  const [hours = 0, minutes = 0] = (hm ?? '00:00').split(':');
  return +hours * 60 + +minutes;
};

export const validateTimes = (
  earliestDate: string,
  earliestTime: string,
  latestDate: string,
  latestTime: string,
) => {
  const earliest = addMinutes(parseISO(earliestDate), hmToMinutes(earliestTime));
  const latest = addMinutes(parseISO(latestDate), hmToMinutes(latestTime));
  return earliest <= latest;
};

export const generateDate = (date: string | Date, dateFormat = 'dd.MM.yyyy') => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const dateTime = parsedDate ? format(parsedDate, 'HH:mm') : '';

  if (dateTime === '00:00' && dateFormat === 'dd.MM.yyyy HH:mm') dateFormat = 'dd.MM.yyyy';
  return parsedDate ? format(parsedDate, dateFormat) : '';
};

/* export const generateUTCDate = (date: string | Date, dateFormat = 'dd.MM.yyyy'): string =>
  date ? format(new Date(new Date(date).setUTCHours(0, 0, 0, 0)), dateFormat) : ''; */

export const combineDateWithTime = (date: string | null, time: string | null): Date | null => {
  if (!date) {
    return null;
  }

  try {
    const parsedDate = parseISO(date);

    if (!parsedDate) {
      return null;
    }

    // FIXME: Sometimes backend sends time already in date object,
    // sometimes it shows date with midnight time and real time is sent in a separate field,
    // so let's unify this here for now, but it eventually should be done on the backend side.
    parsedDate.setHours(0, 0, 0, 0);

    return addMinutes(parsedDate, hmToMinutes(time));
  } catch {
    return null;
  }
};

export const getMidnightDate = (date) => {
  if (!date) {
    return null;
  }

  const parsedDate = parseISOWithoutTimeZone(date);
  parsedDate.setUTCHours(0, 0, 0, 0);

  return parsedDate;
};

export const getToday = () => {
  return new Date(new Date().setHours(0, 0, 0, 0));
};
export const getTomorrow = () => {
  const today = getToday();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.setHours(0, 0, 0, 0);
};

import { API_URL } from '../constants/apiUrl';

const URL = API_URL;

export const applyOrder = (formData, isPriceRequest) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify(formData),
  };
  if (isPriceRequest) {
    return fetch(`${URL}/api/prices/public-order?isPriceRequest=1`, params);
  }
  return fetch(`${URL}/api/prices/public-order?isPriceRequest=0`, params);
};

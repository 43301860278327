import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Box, Fab } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { generateKey } from 'shared/functions/generateKey';
import { HamburgerIcon, CloseIcon, Button } from 'components';
import { useNavigation } from 'shared/hooks/useNavigation';
import { MobileMenuContext } from 'AppContent';
import { bookingPortalUrl } from 'shared/constants/urls';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { languages } from 'shared/constants/languages';

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.common.lightGrayishViolet,
    clipPath: 'circle(0px at calc(100% - 40px) calc(100% - 50px))',
    transition: '0.5s ease all',
  },
  drawerOpen: {
    clipPath: 'circle(100%)',
  },
  listItem: {
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
  fab: {
    position: 'fixed',
    right: 24,
    bottom: 40,
    width: 64,
    height: 64,
    backgroundColor: theme.palette.common.darkCyan,
    zIndex: 1301 /* above the drawer */,
    '&:hover': {
      backgroundColor: theme.palette.common.darkCyan,
    },
  },
  link: {
    fontSize: '1rem',
    textDecoration: 'none',
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  loginButton: {
    marginBottom: theme.spacing(10),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

export const MobileMenu = ({ isRouteLoaded }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const {
    activeTab,
    activeTabChanged,
    navTabs,
    isMainView: useScrollLink,
  } = useNavigation(isRouteLoaded);

  const { menuExpanded, setMenuExpanded } = useContext(MobileMenuContext);

  const handleDrawerClick = () => setMenuExpanded(!menuExpanded);
  const closeDrawer = () => setMenuExpanded(false);

  const fabIcon = menuExpanded ? <CloseIcon /> : <HamburgerIcon />;

  const goToBookingPortal = () => window.open(bookingPortalUrl, '_blank');

  const onLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Fab
        className={classes.fab}
        aria-label="menu"
        onClick={handleDrawerClick}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      >
        {fabIcon}
      </Fab>
      <Box
        className={clsx(classes.drawer, menuExpanded && classes.drawerOpen)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="fixed"
        top={0}
        zIndex={1300}
        height="100vh"
        width="100vw"
      >
        <LanguageSwitcher
          languages={languages}
          selectedLanguage={i18n.language}
          onLanguageChange={onLanguageChange}
          textColor="primary"
          marginBottom={8}
        />
        <Button
          className={classes.loginButton}
          variant="outlined"
          onClick={goToBookingPortal}
          color="invert"
          id={t('ID_LOGIN_BTN')}
          labelId={t('ID_LOGIN_BTN_LABEL')}
        >
          {t('LOGIN')}
        </Button>
        <List component="nav" aria-labelledby="navbar-list-subheader" className={classes.nav}>
          {navTabs.map((tab, index) => {
            const { label, link, samePage } = tab;

            const externalUrl = link.startsWith('http://') || link.startsWith('https://');

            const linkComponent = externalUrl ? (
              <a href={link} className={classes.link} onClick={closeDrawer}>
                {t(label)}
              </a>
            ) : (
              <Link to={link} onClick={closeDrawer} className={classes.link}>
                {t(label)}
              </Link>
            );

            return (
              <ListItem
                key={generateKey(index, link)}
                button
                className={clsx(
                  classes.listItem,
                  activeTab && label === activeTab.label ? classes.bold : classes.medium,
                )}
                disableRipple
                disableGutters
              >
                {useScrollLink && samePage && !externalUrl ? (
                  <ScrollLink
                    to={link}
                    spy
                    smooth
                    offset={0}
                    duration={500}
                    onClick={closeDrawer}
                    onSetActive={() => activeTabChanged(tab, true)}
                    onSetInactive={() => activeTabChanged(tab, false)}
                  >
                    {t(label)}
                  </ScrollLink>
                ) : (
                  linkComponent
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>
    </>
  );
};

MobileMenu.defaultProps = {
  isRouteLoaded: undefined,
};

MobileMenu.propTypes = {
  isRouteLoaded: PropTypes.bool,
};

import React, {
  FC,
  ReactElement,
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { PlusIcon, IconWrapper, BinIcon } from 'components';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import clsx from 'clsx';
import { generateKey } from 'shared/functions/generateKey';
import { useFiles } from 'shared/services/fileService';
import { uploadShipmentFilesSupportedExt } from 'shared/constants/uploadFilesSupportedExt';
import { useCommonStyles } from 'shared/styles/common';
import { generateDate } from 'shared/functions/date';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  heading: {
    marginBottom: theme.spacing(4),
  },
  dropzone: {
    border: `2px dashed ${theme.palette.common.grayAlt}`,
    borderRadius: 16,
    backgroundColor: theme.palette.common.veryLightGrayAlt2,
    textAlign: 'center',
    display: 'block',
    paddingTop: 31,
    paddingBottom: 30,
    height: '159px',
  },
  bodyLineOne: {
    color: theme.palette.common.veryDarkGray,
    lineHeight: '20px',
  },
  link: {
    fontSize: '0.875rem',
    display: 'block',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
    lineHeight: '17px',
  },
  fileName: {
    lineHeight: '20px',
    wordBreak: 'break-all',
    paddingBottom: theme.spacing(1),
    display: 'block',
  },
  binIcon: {
    minWidth: 15,
  },
  deleteButton: {
    minHeight: 24,
    minWidth: 24,
    marginTop: -3,
  },
  errorMessage: {
    color: theme.palette.common.alertError,
    fontSize: '0.875rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  fileInfo: {
    marginBottom: theme.spacing(1),
  },
  dropzoneActive: {
    backgroundColor: theme.palette.common.lightGray,
  },
});

export const FileDropZone: FC = forwardRef(
  (
    {
      id,
      object,
      hideTitle,
      onFilesStateChanged,
      supportedFilesText,
      onlyOneFileAllowed,
      acceptTypes,
    },
    ref,
  ): ReactElement => {
    const accept = !acceptTypes ? uploadShipmentFilesSupportedExt.join() : acceptTypes.join();
    const { t } = useTranslation();
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const { uploadFiles, fetchFiles, deleteFile } = useFiles();
    const [acceptedFilesState, setAcceptedFilesState] = useState([]);
    const [rejectionFilesState, setRejectionFilesState] = useState([]);

    const deleteDocument = async (deleteFileInfo) => {
      if (deleteFileInfo && deleteFileInfo.allowDelete && id) {
        try {
          await deleteFile({
            objectId: id,
            object,
            fileId: deleteFileInfo.id,
          });
          const newFiles = acceptedFilesState.filter(
            (acceptedFile) => acceptedFile.id !== deleteFileInfo.id,
          );
          setAcceptedFilesState(newFiles);
        } catch (err) {
          // TODO: add error handling
        }
      }
    };

    const removeFile = (file) => () => {
      if (file.id > 0) {
        deleteDocument(file);
      } else {
        const newFiles = acceptedFilesState.filter((acceptedFile) => acceptedFile !== file);
        setAcceptedFilesState(newFiles);
      }
    };

    useImperativeHandle(ref, () => ({
      async getUploadedFiles(objectId): Promise<boolean> {
        try {
          const response = await fetchFiles({
            objectId,
            object,
          });
          if (response?.payload) {
            const files = response.payload.map((item) => ({
              id: item.id,
              fileName: item.name,
              size: item.sizeInBytes,
              url: '/',
              allowDelete: item.allowDelete,
              createdOn: item.createdOn,
              file: undefined,
            }));
            setAcceptedFilesState(files);
          }
        } catch (error) {
          // TODO: Add error handling when messages notification messages are specified
          return false;
        }
        return true;
      },

      async uploadAcceptedFiles(objectId): Promise<any | undefined> {
        const pendingFiles = acceptedFilesState
          .filter((item) => item.file !== undefined)
          .map((item) => {
            return item.file ?? new File([], 'empty');
          });
        try {
          if (object) {
            const response = await uploadFiles({
              objectId,
              object,
              files: pendingFiles,
            });
            if (response?.payload) {
              setAcceptedFilesState([]);
              setRejectionFilesState([]);
            }
          }
          return undefined;
        } catch (error) {
          return undefined;
          // TODO: Add error handling when messages notification messages are specified
        }
      },
    }));

    const onDrop = useCallback(
      (acceptedFiles, fileRejections) => {
        const items = acceptedFilesState;
        for (let i = 0; i < acceptedFiles.length; i += 1) {
          const newFile = acceptedFiles[i];
          const found = items.filter((e) => e.file?.name === newFile.name).length > 0;
          if (!found) {
            items.push({
              fileName: newFile?.name ?? '',
              size: newFile?.size,
              allowDelete: false,
              id: -1,
              url: '',
              createdOn: generateDate(new Date()),
              file: newFile,
            });
          }
        }
        setAcceptedFilesState([...items]);
        setRejectionFilesState(fileRejections);
      },
      [acceptedFilesState],
    );

    /* useEffect(() => {
      if (onFilesStateChanged && acceptedFilesState.length > 0) {
        console.log(acceptedFilesState)
        onFilesStateChanged(acceptedFilesState);
      }
    }, [acceptedFilesState, onFilesStateChanged]); */

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept,
    });

    const errorText = t('DROPZONE.ERROR');
    const rejectedFileNames = rejectionFilesState.map((item) => item.file.name);
    return (
      <>
        {hideTitle === false && (
          <Typography variant="h5" className={classes.heading}>
            {t('ADD_DOCUMENTS_TITLE')}
          </Typography>
        )}
        {acceptedFilesState.map((item, index) => {
          const mbSize = (item.size / 1000000).toFixed(2);
          return (
            <div key={generateKey(index, 'document_item')}>
              <Box display="flex" className={classes.fileInfo}>
                <Typography
                  className={clsx(classes.fileName && commonClasses.fontSize2)}
                  variant="body2"
                  fontWeight="medium"
                >
                  {`${item.fileName} (${mbSize}MB)`}
                </Typography>
                <IconWrapper
                  className={classes.deleteButton}
                  bgcolorHover={theme.palette.common.lightGrayishCyan}
                  marginLeft={2}
                  width={24}
                  height={24}
                >
                  <BinIcon
                    className={classes.binIcon}
                    height={15}
                    width={14}
                    onClick={removeFile(item)}
                  />
                </IconWrapper>
              </Box>
            </div>
          );
        })}
        {rejectionFilesState?.length > 0 && (
          <Typography variant="body2" className={classes.errorMessage}>
            {errorText.replace('{0}', rejectedFileNames.join(', '))}
          </Typography>
        )}

        <Box
          display="flex"
          className={clsx(
            classes.dropzone,
            isDragActive && classes.dropzoneActive,
            rejectionFilesState?.length === 0 && commonClasses.mt4,
            onlyOneFileAllowed && acceptedFilesState.length === 1 && commonClasses.hidden,
          )}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <>
              <PlusIcon width={22} height={22} fill={theme.palette.common.gray} />
              <Typography variant="body2" fontWeight="bold" className={classes.bodyLineOne}>
                {t('DROPZONE.DRAG_AND_DROP')}
              </Typography>
              <Typography link variant="caption" className={classes.link}>
                {t(`DROPZONE.CHOOSE_FROM_COMPUTER`)}
              </Typography>
              <Typography
                variant="body2"
                className={commonClasses.fontSize1}
                customColor={theme.palette.common.veryDarkGrayAlt2}
              >
                {!supportedFilesText ? t(`DROPZONE.SUPPORTED_FILES`) : supportedFilesText}
              </Typography>
            </>
          </div>
        </Box>
      </>
    );
  },
);

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;

export const applyForJob = ({ name, email, linkedin, message, newsletterAgreement }) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      linkedin,
      message,
      newsletterAgreement,
    }),
  };

  return fetch(`${URL}/apply`, params);
};

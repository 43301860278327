import { useEffect, useState, useCallback } from 'react';
import { routes } from 'routes';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { reactScrollTo } from 'shared/functions/reactScrollTo';
import { performInNextCycle } from 'shared/functions/performInNextCycle';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useNavTabs } from './useNavTabs';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'shared/constants/languages';

const useNavigation = (isRouteLoaded) => {
  const navTabs = useNavTabs();
  const { pathname } = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();

  const [isMainView, setIsMainView] = useState(false);
  const [activeTab, setActiveTab] = useState(undefined);
  const [visibleSection, setVisibleSection] = useState(undefined);

  const prevVisibleSection = usePrevious(visibleSection);

  const linkPositionInPath = () => {
    return i18n.language === defaultLanguage ? 1 : 4;
  };

  const activeTabChanged = ({ link: section }, isVisible) => {
    if (isVisible) {
      setVisibleSection(() => section);
    } else {
      setVisibleSection((currentVisibleSection) =>
        currentVisibleSection === section ? undefined : currentVisibleSection,
      );
    }
  };

  const updateHistory = useCallback(
    debounce((prevVisibleElement, visibleElement) => {
      if (visibleElement) {
        history.replace(`/${visibleElement}`);
      } else if (prevVisibleElement) {
        history.replace('/');
      }
    }, 200),
    [],
  );

  useEffect(() => {
    updateHistory(prevVisibleSection, visibleSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleSection]);

  useEffect(() => {
    if (pathname && isRouteLoaded) {
      const path = pathname.slice(linkPositionInPath());
      if (path && visibleSection !== path) {
        performInNextCycle(() => reactScrollTo(path, 0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isRouteLoaded, i18n.language]);

  useEffect(() => {
    const tab = navTabs.find(({ link }) => link === pathname.substring(linkPositionInPath()));
    setActiveTab(tab);

    const mainRoute = routes.find(({ name }) => name === 'main');
    setIsMainView(mainRoute.path.length && mainRoute.path.includes(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navTabs, i18n.language]);

  return { activeTab, activeTabChanged, navTabs, isMainView };
};

export { useNavigation };

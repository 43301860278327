import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';

import { getLocalizedPath } from 'shared/functions/getLocalizedPath';
import { getLanguageFromPath } from 'shared/functions/getLanguageFromPath';
import { Header, MobileMenu } from 'components';
import { theme } from 'theme';

const defaultMobileMenuContextData = {
  menuExpanded: false,
  setMenuExpanded: () => {},
};

export const MobileMenuContext = React.createContext(defaultMobileMenuContextData);

export const AppContent = ({ isRouteLoaded, routes }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const showMobileMenu = !useMediaQuery(theme.breakpoints.up('amazonFire'));

  const [mobileMenuContextValue, setMobileMenuContextValue] = useState(
    defaultMobileMenuContextData,
  );

  const setMenuExpanded = useCallback((expanded) => {
    setMobileMenuContextValue({
      setMenuExpanded,
      menuExpanded: expanded,
    });
  }, []);

  useEffect(() => {
    setMobileMenuContextValue({
      setMenuExpanded,
      menuExpanded: false,
    });
  }, [setMenuExpanded]);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    let languagePath = getLocalizedPath({ i18n, path: currentPath });

    if (currentPath !== languagePath) {
      const pathLang = getLanguageFromPath(currentPath);
      if (pathLang) {
        setTimeout(() => i18n.changeLanguage(pathLang), 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}`;
    let languagePath = getLocalizedPath({ i18n, path: currentPath });

    if (currentPath !== languagePath) {
      history.replace(languagePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location, history]);

  return (
    <>
      <MobileMenuContext.Provider value={mobileMenuContextValue}>
        <Header isRouteLoaded={isRouteLoaded} showNavLinks={!showMobileMenu} />
        {showMobileMenu && <MobileMenu isRouteLoaded={isRouteLoaded} />}
      </MobileMenuContext.Provider>
      {routes}
    </>
  );
};

Header.defaultProps = {
  isRouteLoaded: undefined,
  routes: null,
};

Header.propTypes = {
  isRouteLoaded: PropTypes.bool,
  routes: PropTypes.node,
};

import React, { useEffect, useState } from 'react';
import useInView from 'react-cool-inview';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { Loader } from 'components/Loader/Loader';

const VisibilityTracker = ({
  children,
  onChange,
  threshold,
  enabled,
  lazyLoad,
  placeholderProps,
}) => {
  const [loaded, setLoaded] = useState(!lazyLoad);
  const { ref, unobserve, observe } = useInView({
    threshold,
    rootMargin: '500px 0px 500px 0px',
    delay: 300,
    onChange: ({ inView }) => {
      if (inView && !loaded) {
        setLoaded(true);
      }
      onChange(inView);
    },
  });

  useEffect(() => {
    if (enabled !== undefined) {
      enabled ? observe() : unobserve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <Box
      ref={ref}
      position={lazyLoad && !loaded ? 'relative' : undefined}
      {...(lazyLoad && !loaded ? placeholderProps : {})}
    >
      {lazyLoad && !loaded ? <Loader cover noBackground /> : children}
    </Box>
  );
};

VisibilityTracker.defaultProps = {
  onChange: () => null,
  threshold: 0.0,
  enabled: true,
  placeholderProps: {},
  lazyLoad: true,
};

VisibilityTracker.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  threshold: PropTypes.number,
  enabled: PropTypes.bool,
  placeholderProps: PropTypes.object,
  lazyLoad: PropTypes.bool,
};

export { VisibilityTracker };

import React from 'react';

const CloseIcon = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2969 1.29688L0.296875 13.2969C0.109375 13.5 0.015625 13.7344 0.015625 14C0.015625 14.2656 0.109375 14.5 0.296875 14.7031C0.5 14.8906 0.734375 14.9844 1 14.9844C1.26562 14.9844 1.5 14.8906 1.70312 14.7031L13.7031 2.70312C13.8438 2.57812 13.9375 2.42969 13.9844 2.25781C14.0312 2.08594 14.0312 1.91406 13.9844 1.74219C13.9375 1.55469 13.8516 1.39844 13.7266 1.27344C13.6016 1.14844 13.4453 1.0625 13.2578 1.01562C13.0859 0.96875 12.9141 0.96875 12.7422 1.01562C12.5703 1.0625 12.4219 1.15625 12.2969 1.29688ZM0.296875 2.70312L12.2969 14.7031C12.5 14.8906 12.7344 14.9844 13 14.9844C13.2656 14.9844 13.4922 14.8906 13.6797 14.7031C13.8828 14.5 13.9844 14.2656 13.9844 14C13.9844 13.7344 13.8906 13.5 13.7031 13.2969L1.70312 1.29688C1.5 1.10938 1.26562 1.01562 1 1.01562C0.734375 1.01562 0.5 1.11719 0.296875 1.32031C0.109375 1.50781 0.015625 1.73437 0.015625 2C0.015625 2.26563 0.109375 2.5 0.296875 2.70312Z"
      fill="white"
    />
  </svg>
);

export { CloseIcon };

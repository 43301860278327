import { scroller } from 'react-scroll';

export const reactScrollTo = (elementId, duration = 500) => {
  scroller.scrollTo(elementId, {
    spy: true,
    smooth: true,
    offset: 0,
    duration,
  });
};

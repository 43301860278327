import { languages } from '../constants/languages';

export const getLanguageFromPath = (path) => {
  const pathWithoutQuery = path.split('?')[0];
  const supportedLanguages = Object.values(languages);
  for (const { code: lang } of supportedLanguages) {
    if (pathWithoutQuery.startsWith(`/${lang}/`) || pathWithoutQuery === `/${lang}`) {
      return lang;
    }
  }
  return undefined;
};

import React, { ReactElement } from 'react';
import { theme } from 'theme';

export const MinusIcon = ({
  width = 12,
  height = 12,
  fill = theme.palette.common.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M0.912109 1.33789H9.08789C9.25195 1.32878 9.38867 1.26953 9.49805 1.16016C9.60742 1.05078 9.66211 0.914062 9.66211 0.75C9.66211 0.585938 9.60742 0.449219 9.49805 0.339844C9.38867 0.230469 9.25195 0.171224 9.08789 0.162109H0.912109C0.748047 0.171224 0.611328 0.230469 0.501953 0.339844C0.392578 0.449219 0.337891 0.585938 0.337891 0.75C0.337891 0.914062 0.392578 1.05078 0.501953 1.16016C0.611328 1.26953 0.748047 1.32878 0.912109 1.33789Z"
        fill={fill}
      />
    </svg>
  );
};

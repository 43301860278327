import { ShipmentItemType, ShipmentItemSubType } from 'shared/types/shipments/shipmentItem';

export const shipmentItemTypesToSubTypes: Record<ShipmentItemType, ShipmentItemSubType[]> = {
  [ShipmentItemType.Pallet]: [
    ShipmentItemSubType.EurPallet,
    ShipmentItemSubType.FinPallet,
    ShipmentItemSubType.Pallet,
    ShipmentItemSubType.LongPallet,
    ShipmentItemSubType.HalfPallet,
  ],
  [ShipmentItemType.Box]: [ShipmentItemSubType.Package],
  [ShipmentItemType.Truck]: [ShipmentItemSubType.FullTruckLoad],
  [ShipmentItemType.Other]: [ShipmentItemSubType.LDM],
  [ShipmentItemType.Container]: [
    ShipmentItemSubType.Container20ftDC,
    ShipmentItemSubType.Container40ftDC,
    ShipmentItemSubType.Container40ftHC,
    ShipmentItemSubType.ContainerOther,
  ],
};

export const shipmentItemSubTypesToTypes: Record<ShipmentItemSubType, ShipmentItemType> = {
  [ShipmentItemSubType.EurPallet]: ShipmentItemType.Pallet,
  [ShipmentItemSubType.FinPallet]: ShipmentItemType.Pallet,
  [ShipmentItemSubType.Pallet]: ShipmentItemType.Pallet,
  [ShipmentItemSubType.LongPallet]: ShipmentItemType.Pallet,
  [ShipmentItemSubType.HalfPallet]: ShipmentItemType.Pallet,
  [ShipmentItemSubType.Package]: ShipmentItemType.Box,
  [ShipmentItemSubType.FullTruckLoad]: ShipmentItemType.Truck,
  [ShipmentItemSubType.LDM]: ShipmentItemType.Other,
  [ShipmentItemSubType.Container20ftDC]: ShipmentItemType.Container,
  [ShipmentItemSubType.Container40ftDC]: ShipmentItemType.Container,
  [ShipmentItemSubType.Container40ftHC]: ShipmentItemType.Container,
  [ShipmentItemSubType.ContainerOther]: ShipmentItemType.Container,
};

import React, { ReactElement } from 'react';
export const PalletIcon = ({
  width = 27,
  height = 27,
  fill = '#858585',
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.3956 0.867188L26.0714 8.44141C26.4177 8.67839 26.5909 9.00651 26.5909 9.42578V17.5742C26.5909 17.9935 26.4177 18.3216 26.0714 18.5586L14.3956 26.1328C14.1951 26.2604 13.9854 26.3242 13.7667 26.3242C13.5479 26.3242 13.3383 26.2604 13.1378 26.1328L1.46198 18.5586C1.11562 18.3216 0.942448 17.9935 0.942448 17.5742V9.42578C0.942448 9.00651 1.11562 8.67839 1.46198 8.44141L13.1378 0.867188C13.3383 0.739583 13.5479 0.675781 13.7667 0.675781C13.9854 0.675781 14.1951 0.739583 14.3956 0.867188ZM12.5909 3.98438L4.19635 9.45312L7.94245 12.0781L12.5909 8.79688V3.98438ZM14.9424 3.98438V8.79688L19.5909 12.0781L23.337 9.45312L14.9424 3.98438ZM13.7667 16.1523L17.5674 13.5L13.7667 10.8477L9.96589 13.5L13.7667 16.1523ZM24.2667 15.332V11.668L21.6417 13.5L24.2667 15.332ZM23.337 17.5469L19.5909 14.9219L14.9424 18.2031V23.0156L23.337 17.5469ZM4.19635 17.5469L12.5909 23.0156V18.2031L7.94245 14.9219L4.19635 17.5469ZM3.26667 15.332L5.89167 13.5L3.26667 11.668V15.332Z"
        fill={fill}
      />
    </svg>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { generateKey } from 'shared/functions/generateKey';
import { customerLogos } from 'shared/constants/customerLogos';

const Customers = ({ className }) => (
  <>
    <Box display="flex" mt={18} justifyContent="space-between" maxHeight={60}>
      {customerLogos.map((customerLogo, index) => (
        <img
          key={generateKey(index, 'customer_logo')}
          className={className}
          alt="LaasOne partner"
          src={`/images/customers/${customerLogo}`}
        />
      ))}
    </Box>
  </>
);

Customers.defaultProps = {
  className: undefined,
};

Customers.propTypes = {
  className: PropTypes.string,
};

export { Customers };

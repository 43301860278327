import React, { ReactElement, useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { useFormikError } from 'shared/hooks/useFormikError';
import { Typography } from '../CustomMaterial/Typography/Typography';
import { emptyLoadingContact } from 'shared/constants/shipment/shipmentAddress';
import { NewShipmentContext } from 'scenes/Order/Order';
import { AddressFields } from './AddressFields';

const dummyFunction = () => {};

export const AddressAutocompleteContext = React.createContext(dummyFunction);

export const AddressEditable = ({ type, ...restProps }): ReactElement => {
  const formik = useFormikContext();
  const isError = useFormikError();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);

  const { t } = useTranslation();

  const name = type;
  const nameAlternative = `${type}Alternative`;

  const { country, postCode } = get(formik.values, name) ?? {};

  const [showMoreInformation, setShowMoreInformation] = useState(false);

  const typeTranslation = type === 'sender' ? 'SENDER' : 'RECEIVER';

  const onAddressAutocomplete = useCallback(() => {
    setShowMoreInformation(true);
  }, [setShowMoreInformation]);

  const clearAllFields = () => {
    formik.setFieldValue(name, emptyLoadingContact);
    formik.setFieldValue(nameAlternative, emptyLoadingContact);
  };

  useEffect(() => {
    setAutomaticPricesState('InProgress');
  }, [setAutomaticPricesState, country, postCode]);

  useEffect(() => {
    if (isError(name) && !showMoreInformation) {
      setShowMoreInformation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik]);

  return (
    <Box
      width="100%"
      borderRadius={16}
      paddingTop={8}
      paddingX={6}
      paddingBottom={10}
      bgcolor={theme.palette.common.lightGrayishViolet}
      {...restProps}
    >
      <Typography variant="subtitle2" fontWeight="extraBold">
        {t(
          type === 'sender'
            ? 'SHIPMENT.ADDRESSES.WHERE_TO_PICK_UP_FROM'
            : 'SHIPMENT.ADDRESSES.WHERE_TO_DELIVER',
        )}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
        marginTop={6}
      >
        <Typography variant="h6">{t('SHIPMENT.ADDRESSES.ADD_MANUALLY')}:</Typography>
        <Typography link variant="caption" onClick={clearAllFields}>
          {t('SHIPMENT.ADDRESSES.CLEAR_ALL_FIELDS')}
        </Typography>
      </Box>

      <AddressAutocompleteContext.Provider value={onAddressAutocomplete}>
        <AddressFields
          name={name}
          type={type}
          typeTranslation={typeTranslation}
          showMoreInformation
        />
      </AddressAutocompleteContext.Provider>
    </Box>
  );
};

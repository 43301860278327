import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

import { makeStyles } from '@material-ui/styles';

import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 15,
    height: 36,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
}));

const BulletPoint = ({ className, color, opacity }) => {
  const classes = useStyles();

  return (
    <svg
      className={clsx(classes.root, className)}
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78625 12.6518L14.8066 6.84821C15.0401 6.61607 15.0401 6.26786 14.9233 6.03571L12.5883 0.464286C12.4715 0.116071 12.1213 0 11.8878 0H3.01467C2.43092 0 2.08066 0.580357 2.31417 1.16071L4.41569 6.03571C4.53244 6.26786 4.53244 6.61607 4.29894 6.84821L0.212642 11.6071C-0.254364 12.1875 0.0958903 13 0.796398 13H9.31925C9.436 12.8839 9.6695 12.7679 9.78625 12.6518Z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};

BulletPoint.defaultProps = {
  className: undefined,
  color: theme.palette.common.darkCyan,
  opacity: 1.0,
};

BulletPoint.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
};

export { BulletPoint };

export const careersData = {
  /*
    en: [
      {
        id: 'full-stack',
        title: 'Full-stack developer (.NET Core, ReactJs)',
        description:
          'You will be part of a building automation platform that is remodeling modern freight logistics to make transportation service more accessible and transparent to millions of people',
        paragraphs: [
          {
            heading: 'Why to join Speys?',
            bulletPoints: [
              'We are an innovative and rapidly developing company',
              'You have the opportunity to participate in the development of information systems that will change the logistics industry',
              'An international team with great talents in their field is waiting for you',
              'Our strengths are excellent knowledge of logistics and the ability to digitize supply chains',
              'The values of our service are transparency, speed, analytics, convenience and excellent UX / UI',
              'We offer flexible working hours and the possibility of teleworking',
              'We believe that the logistics service must be easy to use for everyone and you have the opportunity to be a part of this way of thinking',
            ],
          },
          {
            heading: 'You can use your talent while:',
            bulletPoints: [
              'Contributing to the creation and development of a digital logistics product',
              'Combining today\'s existing systems harmoniously with new developing systems',
              'Participating in the creation of new solutions and technical processes',
              'Supporting your knowledge teammates and helping to build a development team',
              'Supporting the company\'s business goals through the application of processes and technical skills',
            ],
          },
          {
            heading: 'Expectations for you:',
            bulletPoints: [
              'You have at least five years of work experience as a developer',
              'You have developed web applications and information systems using .NET Core and ReactJs and technologies',
              'You have hands-on experience with cloud technologies (preferably AWS), developing microservices, and creating integrations with external systems',
              'You are interested in new technologies',
              'You are an open communicator and enjoy working with a team',
              'You can communicate fluently in both Estonian and English',
            ],
          },
          {
            heading: 'What is beneficial:',
            bulletPoints: [
              'Professional higher education',
              'Performed the role of an architect in previous projects',
              'Knowledge of agile development methodologies',
            ],
          },
        ],
        workingTime: 'full-time',
        workingRemote: 'remote work',
        location: 'Tallinn, Estonia',
        link: '',
      },
    ],
    et: [
      {
        id: 'full-stack',
        title: 'Full-stack arendaja (.NET Core, ReactJs)',
        description:
          'Sul on võimalus olla osaline automatiseeritud logistika platvormi loomisel, mis muudab tänaseid logistikaprotsesse nii, et transporditeenus oleks lihtne ja läbipaistvam miljonitele inimestele.',
        paragraphs: [
          {
            heading: 'Miks ühineda Speysiga?',
            bulletPoints: [
              'Oleme innovaatiline ja kiiresti arenev ettevõte',
              'Sul on võimalus osaleda infosüsteemide arendamisel, mis muudavad logistika tööstust',
              'Sind ootab rahvusvaheline tiim suurepäraste oma ala talentidega',
              'Meie tugevuseks on suurepärased teadmised logistikast ja oskus tarneahelaid digitaliseerida',
              'Meie pakutava teenuse väärtusteks on läbipaistvus, kiirus, analüütilisus, mugavus ja suurepärane UX/UI',
              'Pakume paindlikku tööaega ja kaugtöö võimalust',
              'Me usume, et logistikateenus peab olema lihtsasti kasutatav kõigile ja sul on võimalus olla osa selle mõtteviisi elluviimisest',
            ],
          },
          {
            heading: 'Sa saad oma oskusi rakendada:',
            bulletPoints: [
              'Panustades logistika digitoote loomisesse ja arendamisse',
              'Ühendades tänaseid olemasolevaid süsteeme harmooniliselt uute arendavate süsteemidega',
              'Osaledes uute lahenduste ja tehniliste protsesside loomises',
              'Toetades oma teadmistega meeskonnakaaslasi ja aidata ehitada arendusmeeskonda',
              'Toetades ettevõtte ärilisi eesmärke läbi protsesside ja tehniliste oskuste rakendamise',
            ],
          },
          {
            heading: 'Ootused Sulle:',
            bulletPoints: [
              'Omad vähemalt viieaastast töökogemust arendajana',
              'Oled arendanud veebirakendusi ja infosüsteeme kasutades .NET Core ja ReactJs ja tehnoloogiaid',
              'Sul on praktiline kogemus pilvetehnoloogiatega (eelistatult AWS), mikroteenuste arendamisel ja väliste süsteemidega integratsioonide loomisel',
              'Sul on huvi uute tehnoloogiate vastu',
              'Oled avatud suhtleja ja naudid töötamist koos meeskonnaga',
              'Suhtled vabalt nii eesti kui inglise keeles',
            ],
          },
          {
            heading: 'Kasuks tuleb:',
            bulletPoints: [
              'Erialane kõrgharidus',
              'Arhitekti rolli täitmine varasemates projektides',
              'Agiilsete arendusmetoodikate tundmine',
            ],
          },
        ],
        workingTime: 'täistööaeg',
        workingRemote: 'kaugtöö',
        location: 'Tallinn',
        link: '',
      },
    ],*/
};

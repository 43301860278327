import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const CheckboxIcon = ({ checked, variant, ...restProps }) => {
  let icon = theme.palette.common.white;
  if (!checked) {
    icon = (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="3.5"
          stroke={
            variant === 'light'
              ? theme.palette.common.lightGrayishVioletAlt
              : theme.palette.common.mostlyDesaturatedDarkViolet
          }
        />
      </svg>
    );
  } else {
    icon = (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="3.5"
          fill={
            variant === 'light'
              ? theme.palette.common.lightGrayishVioletAlt
              : theme.palette.common.mostlyDesaturatedDarkViolet
          }
          stroke={
            variant === 'light'
              ? theme.palette.common.lightGrayishVioletAlt
              : theme.palette.common.mostlyDesaturatedDarkViolet
          }
        />
        <path
          d="M23.2891 10.2891L13 20.5781L8.71094 16.2891C8.50781 16.1016 8.26562 16.0078 7.98438 16.0078C7.71875 16.0078 7.49219 16.1016 7.30469 16.2891C7.10156 16.4922 7 16.7344 7 17.0156C7 17.2812 7.10156 17.5078 7.30469 17.6953L12.2969 22.7109C12.4844 22.8984 12.7188 22.9922 13 22.9922C13.2812 22.9922 13.5156 22.8984 13.7031 22.7109L24.6953 11.6953C24.8984 11.5078 25 11.2812 25 11.0156C25 10.7344 24.8984 10.4922 24.6953 10.2891C24.5078 10.1016 24.2734 10.0078 23.9922 10.0078C23.7266 10.0078 23.4922 10.1016 23.2891 10.2891Z"
          fill={
            variant === 'light' ? theme.palette.common.veryDarkGray : theme.palette.common.white
          }
        />
      </svg>
    );
  }

  return icon;
};

CheckboxIcon.defaultProps = {
  checked: false,
  variant: 'light',
};

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
};

export { CheckboxIcon };

import React from 'react';
import PropTypes from 'prop-types';

import MaterialCheckbox from '@material-ui/core/Checkbox';

import { CheckboxIcon } from 'components';

const Checkbox = ({ variant, ...props }) => {
  return (
    <MaterialCheckbox
      checkedIcon={<CheckboxIcon variant={variant} checked />}
      icon={<CheckboxIcon variant={variant} checked={false} />}
      {...props}
    />
  );
};

Checkbox.defaultProps = {
  variant: 'light',
};

Checkbox.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export { Checkbox };

import { languages } from '../constants/languages';
import { API_URL } from '../constants/apiUrl';
const URL = API_URL;

export const getLanguageByCode = (languageCode) =>
  languages.find(({ code }) => code === languageCode);

export const useClassifiers = () => {
  const langIso = (language) => {
    const lang = getLanguageByCode(language);
    return lang?.code || 'en';
  };

  const fetchCountries = async ({ language = 'en' }) => {
    const response = await fetch(
      `${URL}/api/classifier/classifier-items/countries/${langIso(language)}`,
    );
    const { payload = [] } = await response.json();

    const countries = payload.map((item) => ({
      id: item.id,
      label: item.value,
      value: item.code,
      tCode: item.code,
    }));

    return countries;
  };

  const fetchCharacterization = async ({ language = 'en' }) => {
    const response = await fetch(
      `${URL}/api/classifier/classifier-items/characterization/${langIso(language)}`,
    );
    const { payload = [] } = await response.json();

    return payload;
  };

  return {
    fetchCountries,
    fetchCharacterization,
  };
};

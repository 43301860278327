import { Field, FastField } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { validateDelay } from 'shared/constants/inputDelay';
import { useDebouncedCallback } from 'use-debounce/lib';

const FieldContent = ({
  Component,
  field,
  meta: { error },
  form: { values, validateForm },
  ...restProps
}) => {
  const doValidate = useCallback((vals) => {
    validateForm(vals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const doValidateDebounced = useDebouncedCallback(doValidate, validateDelay);

  useEffect(() => {
    if (error) {
      doValidateDebounced.callback(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return <Component {...field} value={field.value ?? ''} {...restProps} />;
};

export const withFormikFastField = (Component) => {
  return ({ name = '', showErrorLabel = false, ...restProps }) => {
    return (
      <>
        <FastField name={name}>
          {(fieldProps) => <FieldContent Component={Component} {...fieldProps} {...restProps} />}
        </FastField>
      </>
    );
  };
};

export const withFormikField = (Component) => {
  return ({ name = '', showErrorLabel = false, ...restProps }) => {
    return (
      <>
        <Field name={name}>
          {(fieldProps) => <FieldContent Component={Component} {...fieldProps} {...restProps} />}
        </Field>
      </>
    );
  };
};

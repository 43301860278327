import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from 'theme';
import Box from '@material-ui/core/Box';
import { useFormikContext } from 'formik';
import { NewShipmentContext } from 'scenes/Order/Order';
import Typography from '@material-ui/core/Typography';
import { DatesEditable } from '../DatesEditable';

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.common.darkCyan,
  },
});

export const DateStep = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);

  const {
    earliestArrivalDate,
    earliestArrivalTime,
    earliestPickupDate,
    earliestPickupTime,
    isFixedArrivalDate,
    isFixedPickupDate,
    latestArrivalDate,
    latestArrivalTime,
    latestPickupDate,
    latestPickupTime,
  } = formik.values;

  useEffect(() => {
    setAutomaticPricesState('InProgress');
  }, [
    setAutomaticPricesState,
    earliestArrivalDate,
    earliestArrivalTime,
    earliestPickupDate,
    earliestPickupTime,
    isFixedArrivalDate,
    isFixedPickupDate,
    latestArrivalDate,
    latestArrivalTime,
    latestPickupDate,
    latestPickupTime,
  ]);
  return (
    <Box>
      <Typography variant="body2" fontWeight="medium">
        {t('SHIPMENT.ADDRESSES.REQUIRED_FIELDS_ARE_MARKED_WITH')}{' '}
        <span className={classes.asterisk}>*</span>
      </Typography>
      <Box width="100%" display="flex" marginTop={4} position="relative">
        <DatesEditable type="Pickup" marginRight={6} />
        <DatesEditable type="Arrival" />
      </Box>
    </Box>
  );
};

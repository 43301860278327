import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { useHistory } from 'react-router-dom';

import { reactScrollTo } from 'shared/functions/reactScrollTo';
import { languages } from 'shared/constants/languages';
import { bookingPortalUrl } from 'shared/constants/urls';

import { LanguageSwitcher, Logo, Button } from 'components';

import { NavTabs } from './NavTabs/NavTabs';

const useStyles = makeStyles((theme) => ({
  shadow: {
    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  logo: {
    cursor: 'pointer',
  },
  rightSection: {
    marginLeft: 'auto',
  },
  offset: {
    minHeight: 80,
    [theme.breakpoints.down('md')]: {
      minHeight: 60,
    },
  },
  toolbar: {
    padding: theme.spacing(0, 10),
    minHeight: 80,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
      minHeight: 60,
    },
  },
  colorRevert: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: theme.spacing(0),
    marginRight: theme.spacing(6),
    borderRadius: 0,
  },
  requestDemoButton: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
      textDecoration: 'underline',
      '&:hover': {
        background: 'none',
      },
    },
  },
  leftArrowImage: {
    marginRight: theme.spacing(2),
  },
  goToSpeysHomepage: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  loginButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}));

const NavBar = ({
  id,
  tabs,
  activeTab,
  activeTabChanged,
  languageSwitcher,
  useScrollLink,
  showNavLinks,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  let logoSize = 'full';
  if (isMdUp && !isLgUp) {
    logoSize = 'medium';
  } else if (!isMdUp) {
    logoSize = 'small';
  }

  const goToTop = () => (useScrollLink ? reactScrollTo('root') : history.push('/'));

  const goToBookingPortal = () => window.open(bookingPortalUrl, '_blank');

  const onLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  React.useEffect(() => {
    const onScroll = (e) => {
      setIsScrolledToTop(e.target.documentElement.scrollTop === 0);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <>
      <nav id={id}>
        <AppBar
          elevation={0}
          color="primary"
          className={!isScrolledToTop || showNavLinks ? classes.shadow : undefined}
        >
          <Container maxWidth="xl" className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={showNavLinks ? undefined : 'center'}
                width={showNavLinks ? undefined : 1}
              >
                <Logo size={logoSize} onClick={goToTop} className={classes.logo} />
              </Box>
              {showNavLinks && tabs && (
                <NavTabs
                  tabs={tabs}
                  activeTab={activeTab}
                  activeTabChanged={activeTabChanged}
                  useScrollLink={useScrollLink}
                />
              )}
              {!tabs && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={isLgUp ? 'flex-start' : 'flex-end'}
                    maxWidth={896}
                    flexGrow={1}
                  >
                    <img
                      className={classes.leftArrowImage}
                      src="/images/left-arrow.svg"
                      alt="Go to LaasOne homepage"
                    />
                    <Typography
                      className={classes.goToSpeysHomepage}
                      color="textSecondary"
                      component="a"
                      href="/"
                    >
                      {t('GO_TO_LAASONE_HOMEPAGE')}
                    </Typography>
                  </Box>
                  {/* empty placeholder with size the same as logo, just for flex centering of the link container */}
                  {isLgUp && <Box width={120} />}
                </>
              )}
              <Box display="flex" alignItems="center" position="relative">
                {languageSwitcher && showNavLinks && (
                  <LanguageSwitcher
                    languages={languages}
                    selectedLanguage={i18n.language}
                    onLanguageChange={onLanguageChange}
                    position="absolute"
                    left={-148}
                  />
                )}
                {showNavLinks && (
                  <Button
                    className={classes.loginButton}
                    variant="outlined"
                    onClick={goToBookingPortal}
                    color="invert"
                    id={t('ID_LOGIN_BTN')}
                    labelId={t('ID_LOGIN_BTN_LABEL')}
                  >
                    {t('LOGIN')}
                  </Button>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </nav>
      <div className={classes.offset} />
    </>
  );
};

NavBar.defaultProps = {
  activeTab: undefined,
  useScrollLink: true,
  languageSwitcher: false,
  activeTabChanged: () => null,
  tabs: undefined,
};

NavBar.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  activeTab: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  languageSwitcher: PropTypes.bool,
  useScrollLink: PropTypes.bool,
  activeTabChanged: PropTypes.func,
  showNavLinks: PropTypes.bool,
};

export { NavBar };

import React, { ReactElement } from 'react';

import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { QuestionMarkIcon, RadioOptionIcon } from 'components';
import { useCommonStyles } from 'shared/styles/common';
import { Radio } from '@mui/material';
import Typography from '@material-ui/core/Typography';

const useNativeStyles = makeStyles({
  root: ({ padding }) => ({
    padding,
  }),
});

export const RadioOption = ({
  classes: nativeCustomClasses,
  label,
  tooltip,
  padding = 9,
  name,
  checked,
  ...props
}): ReactElement => {
  const commonClasses = useCommonStyles();
  const nativeClasses = useNativeStyles({ padding });

  const radioComponent = (
    <Radio
      color="secondary"
      {...props}
      name={name}
      checkedIcon={<RadioOptionIcon variant="dark" size="small" checked />}
      icon={<RadioOptionIcon variant="lightAlt" size="small" />}
      classes={nativeClasses}
      checked={checked}
    />
  );
  return !label ? (
    radioComponent
  ) : (
    <Box display="flex" alignItems="center">
      {radioComponent}
      <Typography variant="body2" marginLeft={2}>
        {label}
      </Typography>
      {tooltip ? (
        <Tooltip title={tooltip} arrow>
          <div>
            <QuestionMarkIcon className={commonClasses.ml1} />
          </div>
        </Tooltip>
      ) : null}
    </Box>
  );
};

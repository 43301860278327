import { API_URL } from '../constants/apiUrl';
const URL = API_URL;

export const applyForPrices = ({
  from,
  to,
  weight,
  amount,
  type,
  length,
  width,
  height,
  fromCountry,
  toCountry,
  fromZip,
  toZip,
}) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    headers,
    method: 'POST',
    body: JSON.stringify({
      from,
      to,
      weight,
      amount,
      type,
      length,
      width,
      height,
      fromCountry,
      toCountry,
      fromZip,
      toZip,
    }),
  };

  return fetch(`${URL}/api/prices/public`, params);
};

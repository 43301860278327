import get from 'lodash/get';
import { useFormikContext } from 'formik';

export const useFormikError = () => {
  const formik = useFormikContext();

  const isFormikError = (fieldName): boolean => !!get(formik.errors, fieldName);

  return isFormikError;
};

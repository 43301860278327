import qs from 'qs';

const URL = process.env.REACT_APP_BLOG_API_URL;
const BLOG_API_KEY = process.env.REACT_APP_BLOG_API_KEY;

export const fetchPosts = async ({ pageSize, page, slug, exclude }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${BLOG_API_KEY}`,
  };

  const params = {
    _embed: true,
    per_page: pageSize,
    page,
    slug,
    exclude,
  };

  const response = await fetch(`${URL}/posts?${qs.stringify(params)}`, { headers });
  return response.json();
};

export const fetchTags = async () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${BLOG_API_KEY}`,
  };

  const response = await fetch(`${URL}/tags`, { headers });
  return response.json();
};

import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { generateKey } from 'shared/functions/generateKey';
import { theme } from 'theme';

const useStyles = makeStyles(() => ({
  groupValue: {
    padding: theme.spacing(2.625, 4),
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&:hover': {
      backgroundColor: ({ hoverColor }) => hoverColor,
    },
  },
}));

const OptionsGroup = ({
  onChange,
  value,
  values = [],
  className,
  color,
  hoverColor,
  textColor,
}) => {
  const classes = useStyles({ hoverColor });

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      className={className}
      display="flex"
      justifyContent={isLgUp ? 'space-between' : 'normal'}
      flexWrap="wrap"
      mt={-4}
    >
      {values.map((v, index) => (
        <Box
          mt={4}
          border={1}
          borderColor={color}
          borderRadius={8}
          bgcolor={value === v ? color : 'transparent'}
          className={classes.groupValue}
          key={generateKey(index, 'options_group_value')}
          textAlign="center"
          onClick={() => value !== v && onChange(v)}
        >
          <Typography variant="body2" color={textColor}>
            {v}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

OptionsGroup.defaultProps = {
  className: undefined,
  color: theme.palette.common.mostlyDesaturatedDarkViolet,
  hoverColor: theme.palette.common.veryDarkDesaturatedViolet,
  textColor: theme.palette.common.white,
};

OptionsGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  textColor: PropTypes.oneOf([
    'inherit',
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
};

export { OptionsGroup };

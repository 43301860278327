import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { careersData } from 'shared/mocked/careersData';

export const useNavTabs = () => {
  const { i18n } = useTranslation();

  const [navTabs, setNavTabs] = useState([]);

  useEffect(() => {
    setNavTabs([
      {
        label: 'OUR_PEOPLE',
        link: 'our-people',
        samePage: false,
      },
      {
        label: 'CONTACT',
        link: 'offices',
        samePage: false,
      },
      {
        label: 'CAREERS',
        link: 'careers',
        samePage: false,
        extraData: (careersData && careersData.en && careersData.en.length) || undefined,
      },
      /*{
        label: 'BLOG',
        link: `https://blog.speys.com/${
          i18n.language && i18n.language !== 'en' ? i18n.language : ''
        }`,
        samePage: false,
      },*/
    ]);
  }, [i18n.language]);

  return navTabs;
};

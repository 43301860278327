import { ApiResponse } from '../types/api';
import { API_URL } from '../constants/apiUrl';

const URL = API_URL;

export const useFiles = () => {
  const uploadFiles = async ({ objectId, object, files }): Promise<ApiResponse<boolean>> => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      formData.append(`files[${i}]`, files[i]);
    }

    const response = fetch(
      `${URL}/api/prices/${objectId}/upload-s3-files/${object}`,
      {
        method: 'POST',
        body: formData,
      },
      ['Content-Type'],
    );

    return response.json();
  };

  return {
    uploadFiles,
  };
};

import React, { ReactElement } from 'react';
import { theme } from 'theme';

const sizeMap = {
  tiny: 16,
  small: 24,
  big: 32,
};

const uncheckedColorsMap = {
  light: theme.palette.common.lightGrayishVioletAlt,
  lightAlt: theme.palette.common.grayAlt,
  dark: theme.palette.common.mostlyDesaturatedDarkViolet,
};

const checkedColorsMap = {
  light: theme.palette.common.lightGrayishVioletAlt,
  lightAlt: theme.palette.common.lightGrayishVioletAlt,
  dark: theme.palette.common.veryDarkDesaturatedViolet,
};

export const RadioOptionIcon = ({
  checked = false,
  variant = 'light',
  size = 'small',
  ...restProps
}): ReactElement => {
  const sizeValue = sizeMap[size];

  let icon = null;
  if (!checked) {
    icon = (
      <svg
        width={sizeValue}
        height={sizeValue}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          stroke={uncheckedColorsMap[variant]}
        />
      </svg>
    );
  } else {
    icon = (
      <svg
        width={sizeValue}
        height={sizeValue}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          stroke={uncheckedColorsMap[variant]}
        />
        <rect x="4" y="4" width="16" height="16" rx="8" fill={checkedColorsMap[variant]} />
      </svg>
    );
  }

  return icon;
};

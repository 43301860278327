import React, { ReactElement } from 'react';
import { BoxIcon, OtherIcon, PalletIcon, TruckIcon, ContainerIcon } from 'components';
import { theme } from 'theme';
import { ShipmentItemType } from 'shared/types/shipments/shipmentItem';

const ShipmentItemIcon = ({
  width = 27.5,
  height = 30,
  active,
  itemType = ShipmentItemType.Pallet,
  ...restProps
}): ReactElement | null => {
  const iconProps = {
    width,
    height,
    fill: active ? theme.palette.common.darkCyan : theme.palette.common.gray,
    ...restProps,
  };

  let itemIcon = null;
  switch (itemType) {
    case ShipmentItemType.Pallet:
      itemIcon = <PalletIcon {...iconProps} />;
      break;
    case ShipmentItemType.Box:
      itemIcon = <BoxIcon {...iconProps} />;
      break;
    case ShipmentItemType.Truck:
      itemIcon = <TruckIcon {...iconProps} />;
      break;
    case ShipmentItemType.Container:
      itemIcon = <ContainerIcon {...iconProps} />;
      break;
    case ShipmentItemType.Other:
      itemIcon = <OtherIcon {...iconProps} />;
      break;
    default:
      itemIcon = null;
  }

  return itemIcon;
};

export default ShipmentItemIcon;

import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';

export const Indicator = ({
  active,
  activeColor,
  inactiveColor,
  icon,
  size = 32,
  children,
  ...restProps
}): ReactElement => {
  const colors = {
    active: activeColor || theme.palette.common.darkCyan,
    inactive: inactiveColor || theme.palette.common.darkCyan,
  };

  const main = (margin: boolean) => (
    <Box
      borderRadius="100%"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      bgcolor={active ? colors.active : colors.inactive}
      marginRight={margin ? 2 : 0}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {icon}
    </Box>
  );

  return children ? (
    <Box display="flex" alignItems="center" {...restProps}>
      {main(true)}
      {children}
    </Box>
  ) : (
    main(false)
  );
};

import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import PropTypes from 'prop-types';
import Headroom from 'react-headroom';

import { NavBar } from 'components';

import { useNavigation } from 'shared/hooks/useNavigation';

import { theme } from 'theme';

const Header = ({ isRouteLoaded, showNavLinks }) => {
  const { activeTab, activeTabChanged, navTabs, isMainView } = useNavigation(isRouteLoaded);

  const showTabs = !useRouteMatch({ path: ['/blog'] });

  return (
    <Headroom
      style={{ zIndex: 100 }}
      wrapperStyle={{
        backgroundColor: theme.palette.primary.main,
      }}
      calcHeightOnResize
    >
      <NavBar
        id="back-to-top-anchor"
        activeTab={activeTab}
        activeTabChanged={activeTabChanged}
        tabs={showTabs ? navTabs : undefined}
        useScrollLink={isMainView}
        languageSwitcher={showTabs}
        showNavLinks={showNavLinks}
      />
    </Headroom>
  );
};

Header.defaultProps = {
  isRouteLoaded: undefined,
};

Header.propTypes = {
  isRouteLoaded: PropTypes.bool,
  showNavLinks: PropTypes.bool,
};

export { Header };

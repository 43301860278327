import { withStyles } from '@material-ui/styles';
import MaterialAccordion from '@material-ui/core/Accordion';

import { theme } from 'theme';

const Accordion = withStyles({
  root: {
    backgroundColor: theme.palette.common.lightGrayishViolet,
    borderRadius: 12,
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    padding: 2,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
    '&:before': {
      height: 0,
      backgroundColor: 'unset',
    },
    '&.Mui-expanded': {
      backgroundColor: theme.palette.common.lightGrayishVioletAlt,
    },
    '& .MuiCollapse-container': {
      backgroundColor: theme.palette.common.white,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.common.lightGrayishVioletAlt,
      },
    },
    '& .MuiCollapse-wrapper': {
      padding: theme.spacing(12, 8),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
    '& .MuiAccordionDetails-root': {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
  },
})(MaterialAccordion);

export { Accordion };

import React, { ReactElement, useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { SelectFormik, TextFieldFormik } from 'components';
import { theme } from 'theme';

import { simplePhoneRegExp, nameRegExp, emailRegExp, cityRegExp } from 'shared/constants/regExp';
import { useFormikError } from 'shared/hooks/useFormikError';
import { Labeled } from '../CustomMaterial/Labeled/Labeled';
import { AddressLine1OrLookup } from './AddressLine1OrLookup';
import { NewShipmentContext } from '../../scenes/Order/Order';

export const AddressFields = ({
  name,
  type,
  typeTranslation,
  isAlternative = false,
  showMoreInformation = true,
}): ReactElement | null => {
  const { t } = useTranslation();
  const { countries } = useContext(NewShipmentContext);

  const formik = useFormikContext();

  const address = formik.values[name];

  const isError = useFormikError();

  if (!address) {
    return null;
  }

  return (
    <>
      <AddressLine1OrLookup
        name={name}
        typeTranslation={typeTranslation}
        isAlternative={isAlternative}
      />
      <Box display="flex" marginTop={3.75}>
        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.COUNTRY')}
          error={isError(`${name}.country`)}
          required
          marginRight={6}
          width="100%"
        >
          <SelectFormik
            name={`${name}.country`}
            error={isError(`${name}.country`)}
            borderColor={theme.palette.common.lightGray}
            background="opaque"
            fullWidth
            displayEmpty
            defaultValue=""
            defaultLabel={t('SELECT_COUNTRY')}
            data={countries}
            value={address.country || ''}
            showErrorLabel
          />
        </Labeled>

        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.CITY')}
          error={isError(`${name}.city`)}
          required
          width="100%"
        >
          <TextFieldFormik
            name={`${name}.city`}
            error={isError(`${name}.city`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
            allowedChars={cityRegExp}
          />
        </Labeled>
      </Box>

      <Box display="flex" marginTop={3.75}>
        <Box width="50%" marginRight={6}>
          <Labeled
            variant="variant2"
            text={t('SHIPMENT.ADDRESSES.POSTCODE')}
            error={isError(`${name}.postCode`)}
            required
            width="100%"
          >
            <TextFieldFormik
              name={`${name}.postCode`}
              error={isError(`${name}.postCode`)}
              size="small"
              variant="variant6"
              fullWidth
              showErrorLabel
              debounced
            />
          </Labeled>
        </Box>

        <Box width="50%">
          {showMoreInformation && (
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.COMPANY_NAME')}
              error={isError(`${name}.companyName`)}
              required
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.companyName`}
                error={isError(`${name}.companyName`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
              />
            </Labeled>
          )}
        </Box>
      </Box>

      {showMoreInformation && (
        <>
          <Box display="flex" marginTop={3.75}>
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.CONTACT_PERSON')}
              error={isError(`${name}.contactPersonName`)}
              required
              marginRight={6}
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.contactPersonName`}
                error={isError(`${name}.contactPersonName`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
                allowedChars={nameRegExp}
              />
            </Labeled>

            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.CONTACT_NUMBER')}
              error={isError(`${name}.phone`)}
              required
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.phone`}
                error={isError(`${name}.phone`)}
                size="small"
                variant="variant6"
                fullWidth
                placeholder="+372 55555555"
                showErrorLabel
                debounced
                allowedChars={simplePhoneRegExp}
              />
            </Labeled>
          </Box>

          <Box display="flex" marginY={3.75}>
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.EMAIL')}
              error={isError(`${name}.email`)}
              width="100%"
              required
            >
              <TextFieldFormik
                name={`${name}.email`}
                error={isError(`${name}.email`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
                allowedChars={emailRegExp}
              />
            </Labeled>

            {!isAlternative && (
              <Labeled
                variant="variant2"
                text={t(
                  type === 'sender'
                    ? 'SHIPMENT.ADDRESSES.LOADING_REFERENCE'
                    : 'SHIPMENT.ADDRESSES.UNLOADING_REFERENCE',
                )}
                error={isError(`${name}.reference`)}
                tooltip={t(
                  type === 'sender'
                    ? 'SHIPMENT.ADDRESSES.LOADING_REFERENCE_TOOLTIP'
                    : 'SHIPMENT.ADDRESSES.UNLOADING_REFERENCE_TOOLTIP',
                )}
                marginLeft={6}
                width="100%"
              >
                <TextFieldFormik
                  name={`${name}.reference`}
                  error={isError(`${name}.reference`)}
                  size="small"
                  variant="variant6"
                  fullWidth
                  showErrorLabel
                  debounced
                />
              </Labeled>
            )}
          </Box>

          <Labeled
            variant="variant2"
            error={isError(`${name}.notes`)}
            text={t(
              !isAlternative
                ? `SHIPMENT.ADDRESSES.${typeTranslation}.ADDRESS_DETAILS`
                : 'SHIPMENT.ADDRESSES.ADDRESS_DETAILS',
            )}
            tooltip={t(`SHIPMENT.ADDRESSES.${typeTranslation}.ADDRESS_DETAILS`)}
          >
            <TextFieldFormik
              name={`${name}.notes`}
              error={isError(`${name}.notes`)}
              variant="variant6"
              size="small"
              multiline
              fullWidth
              rows={5}
              showErrorLabel
              debounced
            />
          </Labeled>
        </>
      )}
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { theme, defaultTheme } from 'theme';
import { Customers, Spacer, Button, Accent } from 'components';
import { bookDemo } from 'shared/functions/bookDemo';
import { OrderForm } from '../OrderForm/OrderForm';

const useStyles = makeStyles(() => ({
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
  },
  accent: {
    position: 'absolute',
    top: -55,
    left: -270,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  title: {
    fontWeight: 800,
    fontSize: '3.125rem',
    lineHeight: '4.25rem',
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
  },
  description: {
    maxWidth: 480,
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  bookDemo: {
    marginLeft: theme.spacing(6),
    textDecoration: 'underline',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(10),
    },
  },
  heroImage: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: 45,
    },
    maxWidth: '100%',
    objectFit: 'cover',
  },
}));

const Hero = ({ ...restProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      width={1}
      pb={12}
      pt={isLgUp ? 26 : 12}
      {...restProps}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Box display="flex" flexDirection={isLgUp ? 'row' : 'column'}>
          <Box width={isLgUp ? 575 : 1}>
            <Box
              className={classes.heroTextWrapper}
              position="relative"
              display="flex"
              justifyContent="center"
              height={1}
            >
              <Hidden smDown>
                <Accent className={classes.accent} />
              </Hidden>
              <div>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                  {t('HERO_TITLE')}
                </Typography>
                <Spacer variant="xs" />
                <Typography variant="body1" color="textSecondary" className={classes.description}>
                  {t('HERO_DESC')}
                </Typography>
                <Spacer variant="xs" />
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={bookDemo}
                  id={t('ID_BOOK_A_DEMO_BTN')}
                  labelId={t('ID_BOOK_A_DEMO_BTN_LABEL')}
                >
                  {t('BOOK_A_DEMO')}
                </Button>
              </div>
            </Box>
          </Box>
          <Hidden smDown>
            <Box width={isLgUp ? undefined : 1} flexGrow={isLgUp ? 1 : 0}>
              <Box
                w={1}
                height={isLgUp ? 410 : 'auto'}
                position="relative"
                textAlign={isLgUp ? 'left' : 'center'}
              >
                <img alt="LaasOne" className={classes.heroImage} src="/images/hero-image.svg" />
              </Box>
            </Box>
          </Hidden>
        </Box>
        <Hidden smDown>
          <Box width={isLgUp ? undefined : 1} flexGrow={isLgUp ? 1 : 0}>
            <OrderForm />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Customers />
        </Hidden>
      </Container>
    </Box>
  );
};

export { Hero };

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { OutboundIcon } from 'components';

const useStyles = makeStyles((theme) => ({
  rest: {
    padding: theme.spacing(12, 8, 8, 8),
  },
  restCategory: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  strong: {
    fontWeight: 700,
  },
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  textLink: {
    lineHeight: '2.25rem',
  },
  grow: {
    flexGrow: 1,
  },
  marginBottom: {
    marginBottom: theme.spacing(9),
  },
}));

const Post = ({ category, title, link, square, outbound, className, ...restProps }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper
      elevation={0}
      className={clsx(classes.rest, classes.grow, square && classes.marginBottom, className)}
      square={square}
      {...restProps}
    >
      <Typography variant="body2" className={classes.restCategory}>
        {category}
      </Typography>
      <Typography variant="body1" className={`${classes.strong} ${classes.lineClamp}`}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="a"
        href={link}
        target={outbound ? '_blank' : '_self'}
        className={classes.textLink}
      >
        {t('READ_MORE')}
        {outbound && <OutboundIcon variant="dark" />}
      </Typography>
    </Paper>
  );
};

Post.defaultProps = {
  square: false,
  outbound: false,
  className: undefined,
};

Post.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  square: PropTypes.bool,
  outbound: PropTypes.bool,
  className: PropTypes.string,
};

export { Post };

import React, { ReactElement, FC } from 'react';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from 'theme';
import { useCommonStyles } from 'shared/styles/common';
import { BinIcon, IconWrapper, EditIcon } from 'components';
import clsx from 'clsx';

const useStyles = makeStyles({
  actionIcon: ({ variant }) => ({
    position: 'absolute',
    top: theme.spacing(variant === 'variant1' ? 6 : 2.5),
    cursor: 'pointer',
  }),
  binIcon: {
    right: theme.spacing(3.75),
  },
  editIcon: {
    right: theme.spacing(11.5),
  },
});

const useNativeStyles = makeStyles({
  root: ({ showDelete, showEdit, variant }) => ({
    padding: variant === 'variant1' ? theme.spacing(0, 6) : theme.spacing(4, 6),
    minHeight: variant === 'variant1' ? 72 : 'unset',

    '&.Mui-focused': {
      background: 'transparent',
    },
    '&.Mui-expanded': {
      minHeight: 'unset',
      ...(variant === 'variant1' && { padding: theme.spacing(5, 6) }),
      [theme.breakpoints.down(1440)]: {
        padding: theme.spacing(5, 2),
      },
    },
    '& .MuiAccordionSummary-expandIcon': {
      position: 'absolute',
      right: theme.spacing(showDelete ? (showEdit && 23) || 16 : 8.5),
      top: theme.spacing(variant === 'variant1' ? 8 : 4.5),
      ...(variant === 'variant2' && { margin: 0 }),
      padding: 0,
    },
  }),
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
});

const AccordionSummary: FC = ({
  onDelete,
  onEdit,
  children,
  variant = 'variant1',
  IconButtonProps,
  noAccordion,
  ...restProps
}): ReactElement => {
  const styleProps = { showDelete: !!onDelete, showEdit: !!onEdit, variant };

  const classes = useStyles(styleProps);
  const commonClasses = useCommonStyles();
  const nativeClasses = useNativeStyles(styleProps);

  const actionIconColor =
    variant === 'variant1' ? theme.palette.common.darkCyan : theme.palette.common.grayishViolet;

  const actionIconHoverColor =
    variant === 'variant1'
      ? theme.palette.common.darkCyan
      : theme.palette.common.mostlyDesaturatedDarkViolet;

  const actionIconHoverFill =
    variant === 'variant1'
      ? theme.palette.common.white
      : theme.palette.common.lightGrayishVioletAlt;

  const iconButtonProps = { disableRipple: true, ...IconButtonProps };

  return (
    <MaterialAccordionSummary
      classes={nativeClasses}
      className={clsx((onDelete || onEdit || noAccordion) && commonClasses.cursorDefault)}
      IconButtonProps={iconButtonProps}
      {...restProps}
    >
      {onDelete ? (
        <IconWrapper
          bgcolorHover={actionIconHoverColor}
          iconFillHover={actionIconHoverFill}
          className={clsx(classes.binIcon, classes.actionIcon)}
          onClick={onDelete}
        >
          <BinIcon width={17} height={16} fill={actionIconColor} />
        </IconWrapper>
      ) : null}
      {onEdit ? (
        <IconWrapper
          bgcolorHover={actionIconHoverColor}
          iconFillHover={actionIconHoverFill}
          className={clsx(classes.editIcon, classes.actionIcon)}
          onClick={onEdit}
        >
          <EditIcon width={17} height={16} fill={actionIconColor} />
        </IconWrapper>
      ) : null}
      {children}
    </MaterialAccordionSummary>
  );
};
export { AccordionSummary };

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  name: {
    textTransform: 'uppercase',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  image: {
    borderRadius: 24,
    marginBottom: theme.spacing(8),
    objectFit: 'cover',
    opacity: 0.9,
  },
  block: {
    display: 'block',
  },
}));

const TeamItemCard = ({ image, name, title, phone, email, linkedin, width }) => {
  const classes = useStyles();

  function createMarkup() {
    return { __html: '&nbsp;' };
  }

  return (
    <Box
      bgcolor={theme.palette.common.veryLightGray}
      pb={8}
      mx={3}
      borderRadius={24}
      width="fit-content"
      textAlign="center"
    >
      <img
        alt={`${name} - ${title}`}
        src={`/images/team/${image}`}
        width={width || 264}
        height={232}
        className={classes.image}
      />
      <Typography variant="h6" className={classes.name}>
        {title}
      </Typography>

      <Typography variant="body2" className={classes.block} component="p" color="textPrimary">
        {phone.length > 0 ? phone : <span dangerouslySetInnerHTML={createMarkup()} />}
      </Typography>

      {email && (
        <Typography
          variant="body2"
          className={classes.block}
          component="a"
          href={`mailto:${email}`}
          color="textPrimary"
        >
          {email}
        </Typography>
      )}
      {linkedin && (
        <Typography
          variant="body2"
          className={classes.block}
          component="a"
          href={linkedin}
          target="_blank"
          color="textPrimary"
        >
          LinkedIn
        </Typography>
      )}
    </Box>
  );
};

TeamItemCard.defaultProps = {
  email: undefined,
  phone: undefined,
  linkedin: undefined,
  width: undefined,
};

TeamItemCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  linkedin: PropTypes.string,
  width: PropTypes.number,
};

export { TeamItemCard };

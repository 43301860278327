export const emptyLoadingContact = {
  addressLine1: '',
  city: '',
  companyName: '',
  contactPersonName: '',
  country: '',
  email: '',
  notes: '',
  postCode: '',
  phone: '',
  addressName: '',
  saveAddress: false,
};

export const emptyAddressData = {
  address: { ...emptyLoadingContact },
  alternativeAddress: undefined,
};

export const emptyPlacePrediction = {
  placeId: '',
  description: '',
  source: 'None',
  sourceTranslation: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';
import MaterialTextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: ({ color }) => color,
    },
    '& .MuiInput-underline:after': {
      borderColor: ({ color }) => color,
    },
    '& .MuiOutlinedInput-root': {
      color: ({ textColor }) => textColor,
      '& fieldset': {
        borderColor: ({ color }) => color,
      },
      '&:hover fieldset': {
        borderColor: ({ color }) => color,
      },
      '&.Mui-focused fieldset': {
        borderColor: ({ color }) => color,
      },
    },
    '& .Mui-error.MuiOutlinedInput-root': {
      color: ({ textColor }) => textColor,
      '&:hover fieldset': {
        borderColor: '#f44336',
      },
    },
  },
});

const useNativeStyles = makeStyles({
  input: {
    '&:-internal-autofill-selected': {
      backgroundColor: 'unset !important',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: ({ autocompleteColor }) => `0 0 0 1000px ${autocompleteColor} inset`,
      '-webkit-text-fill-color': ({ textColor }) => `${textColor} !important`,
    },
  },
});

const TextField = ({ variant, ...props }) => {
  const { mostlyDesaturatedDarkViolet, lightGrayishVioletAlt, grayishViolet, veryLightGray } =
    theme.palette.common;

  const colors = {
    variant1: mostlyDesaturatedDarkViolet,
    variant2: lightGrayishVioletAlt,
    variant3: grayishViolet,
    variant4: '#F1CC4E',
  };

  const textColors = {
    variant1: theme.palette.text.secondary,
    variant2: theme.palette.text.primary,
    variant3: theme.palette.text.primary,
    variant4: '#333333',
  };

  const autocompleteColors = {
    variant1: theme.palette.primary.main,
    variant2: veryLightGray,
    variant3: lightGrayishVioletAlt,
    variant4: '#333333',
  };

  const color = colors[variant];
  const textColor = textColors[variant];
  const autocompleteColor = autocompleteColors[variant];

  const classes = useStyles({ color, textColor });
  const nativeClasses = useNativeStyles({ autocompleteColor, textColor });

  return <MaterialTextField classes={classes} InputProps={{ classes: nativeClasses }} {...props} />;
};

TextField.defaultProps = {
  variant: 'variant1',
};

TextField.propTypes = {
  variant: PropTypes.oneOf(['variant1', 'variant2', 'variant3', 'variant4']),
};

export { TextField };

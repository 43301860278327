import React, { ReactElement, useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { SelectFormik, TextFieldFormik } from 'components';
import { theme } from 'theme';

import { simplePhoneRegExp, nameRegExp, emailRegExp, cityRegExp } from 'shared/constants/regExp';
import { useFormikError } from 'shared/hooks/useFormikError';
import { AddressLine1OrLookup } from '../AddressLine1OrLookup';
import { Labeled } from '../../CustomMaterial/Labeled/Labeled';
import { NewShipmentContext } from 'scenes/Order/Order';

export const CompanyAddressFields = ({ name, typeTranslation }): ReactElement | null => {
  const { t } = useTranslation();
  const { countries } = useContext(NewShipmentContext);
  const formik = useFormikContext();

  const address = formik.values[name];

  const isError = useFormikError();

  if (!address) {
    return null;
  }

  return (
    <>
      <AddressLine1OrLookup name={name} typeTranslation={typeTranslation} />
      <Box display="flex" marginTop={3.75}>
        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.COMPANY_NAME')}
          error={isError(`${name}.companyName`)}
          required
          width="100%"
          marginRight={6}
        >
          <TextFieldFormik
            name={`${name}.companyName`}
            error={isError(`${name}.companyName`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
          />
        </Labeled>
        <Labeled
          variant="variant2"
          text={t('ORDER.ADDRESS.COMPANY_REG')}
          error={isError(`${name}.regNr`)}
          required
          width="100%"
        >
          <TextFieldFormik
            name={`${name}.regNr`}
            error={isError(`${name}.regNr`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
          />
        </Labeled>
      </Box>
      <Box display="flex" marginTop={3.75}>
        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.COUNTRY')}
          error={isError(`${name}.country`)}
          required
          marginRight={6}
          width="100%"
        >
          <SelectFormik
            name={`${name}.country`}
            error={isError(`${name}.country`)}
            borderColor={theme.palette.common.lightGray}
            background="opaque"
            fullWidth
            displayEmpty
            defaultValue=""
            defaultLabel={t('SELECT_COUNTRY')}
            data={countries}
            value={address.country || ''}
            showErrorLabel
          />
        </Labeled>

        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.CITY')}
          error={isError(`${name}.city`)}
          required
          width="100%"
        >
          <TextFieldFormik
            name={`${name}.city`}
            error={isError(`${name}.city`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
            allowedChars={cityRegExp}
          />
        </Labeled>
      </Box>
      <Box display="flex" marginTop={3.75}>
        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.POSTCODE')}
          error={isError(`${name}.postCode`)}
          required
          marginRight={6}
          width="100%"
        >
          <TextFieldFormik
            name={`${name}.postCode`}
            error={isError(`${name}.postCode`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
          />
        </Labeled>
        <Labeled variant="variant2" text="" width="100%" />
      </Box>
      <Box>
        <Box display="flex" marginTop={3.75}>
          <Labeled
            variant="variant2"
            text={t('SHIPMENT.ADDRESSES.CONTACT_PERSON')}
            error={isError(`${name}.contactPersonName`)}
            required
            marginRight={6}
            width="100%"
          >
            <TextFieldFormik
              name={`${name}.contactPersonName`}
              error={isError(`${name}.contactPersonName`)}
              size="small"
              variant="variant6"
              fullWidth
              showErrorLabel
              debounced
              allowedChars={nameRegExp}
            />
          </Labeled>
          <Labeled
            variant="variant2"
            text={t('SHIPMENT.ADDRESSES.CONTACT_NUMBER')}
            error={isError(`${name}.phone`)}
            required
            width="100%"
          >
            <TextFieldFormik
              name={`${name}.phone`}
              error={isError(`${name}.phone`)}
              size="small"
              variant="variant6"
              fullWidth
              placeholder="+372 55555555"
              showErrorLabel
              debounced
              allowedChars={simplePhoneRegExp}
            />
          </Labeled>
        </Box>
        <Box display="flex" marginTop={3.75}>
          <Labeled
            variant="variant2"
            text={t('SHIPMENT.ADDRESSES.EMAIL')}
            error={isError(`${name}.email`)}
            marginRight={6}
            required
            width="100%"
          >
            <TextFieldFormik
              name={`${name}.email`}
              error={isError(`${name}.email`)}
              size="small"
              variant="variant6"
              fullWidth
              marginRight={6}
              showErrorLabel
              debounced
              allowedChars={emailRegExp}
            />
          </Labeled>
          <Labeled variant="variant2" text="" width="100%" />
        </Box>
      </Box>
    </>
  );
};

export const scrollToFirstInvalid = (className = 'Mui-error', offset = -300): void => {
  const invalidElements = document.getElementsByClassName(className);
  if (invalidElements.length) {
    const invalidElement =
      !invalidElements[0].offsetParent && invalidElements.length > 1
        ? invalidElements[1]
        : invalidElements[0];

    const top = invalidElement.getBoundingClientRect().top + window.pageYOffset + offset;

    window.scrollTo({ top, behavior: 'smooth' });
  }
};

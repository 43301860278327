import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 700,
  },
  companyName: {
    marginBottom: theme.spacing(8),
    textTransform: 'uppercase',
  },
  heading: {
    marginBottom: theme.spacing(6),
  },
  text: {
    fontWeight: 500,
    marginBottom: theme.spacing(6),
  },
  link: {
    color: theme.palette.text.primary,
  },
  author: {
    fontWeight: 500,
  },
  logo: {
    opacity: 0.6,
  },
  textWrapper: {
    paddingLeft: theme.spacing(12.25),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      padding: theme.spacing(0, 6, 10, 6),
    },
  },
}));

const CaseStudiesItem = ({
  companyName,
  heading,
  text,
  readMoreLink,
  readMoreText,
  author,
  logo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const firstCommaIndex = author ? t(author).indexOf(',') : 0;
  const [authorName, authorTitle] = author
    ? [author.slice(0, firstCommaIndex), author.slice(firstCommaIndex + 1)]
    : [];

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      bgcolor={theme.palette.common.lightGrayishVioletAlt}
      width={1}
      pt={isLgUp ? 18 : 14}
      pb={isLgUp ? 18 : 14}
      px={isLgUp ? 14 : 0}
      mb={isLgUp ? 16 : 8}
      borderRadius={16}
      height={isLgUp ? 404 : undefined}
      display="flex"
      alignItems="center"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={isLgUp ? 'row' : 'column-reverse'}
      >
        {isLgUp ? (
          <img alt={companyName} src={logo} className={classes.logo} />
        ) : (
          <Box px={6}>
            <img width="100%" alt={companyName} src={logo} className={classes.logo} />
          </Box>
        )}
        <Box item="true" xs={12} lg={9} className={classes.textWrapper}>
          {companyName && (
            <Typography className={classes.companyName} variant="h5">
              {companyName}
            </Typography>
          )}
          {heading && (
            <Typography className={`${classes.bold} ${classes.heading}`} variant="body1">
              {t(heading)}
            </Typography>
          )}
          {text && (
            <Typography className={classes.text} variant="body1">
              {text}
            </Typography>
          )}
          {readMoreText && readMoreLink && (
            <Typography className={classes.text} variant="body1">
              <a href={readMoreLink} className={classes.link}>
                {t('READ_MORE_ABOUT')}
              </a>
              , {readMoreText}
            </Typography>
          )}
          {author && (
            <Typography className={classes.author} variant="body2" component="span">
              <Typography className={classes.bold} variant="body2" component="span">
                {authorName}
              </Typography>
              , {authorTitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CaseStudiesItem.defaultProps = {
  companyName: undefined,
  heading: undefined,
  text: undefined,
  readMoreText: undefined,
  readMoreLink: undefined,
  author: undefined,
  logo: undefined,
};

CaseStudiesItem.propTypes = {
  companyName: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  readMoreText: PropTypes.string,
  readMoreLink: PropTypes.string,
  author: PropTypes.string,
  logo: PropTypes.string,
};

export { CaseStudiesItem };

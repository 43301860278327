import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { StatsAccent } from 'components/Icons/StatsAccent/StatsAccent';
import clsx from 'clsx';

const useStyles = makeStyles({
  title: {
    marginBottom: theme.spacing(4),
  },
  description: {
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'pre-line',
    },
  },
  value: {
    color: theme.palette.common.darkCyan,
    fontWeight: 800,
    fontSize: '3.125rem',
    lineHeight: '4.25rem',
  },
  accent: {
    position: 'absolute',
  },
  accentLeft: {
    left: -30,
    top: -15,
  },
  accentRight: {
    right: -30,
    top: -15,
    transform: 'scaleX(-1)',
  },
});

const Feature = ({ value, accentPosition, title, description, boxProps, valueProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" {...boxProps}>
      <Box marginLeft="auto" marginRight="auto" position="relative" mb={4}>
        {accentPosition && (
          <StatsAccent
            className={clsx(
              classes.accent,
              accentPosition === 'left' ? classes.accentLeft : classes.accentRight,
            )}
          />
        )}
        <Typography component="p" className={classes.value} {...valueProps}>
          {value}
        </Typography>
      </Box>
      <Typography className={classes.title} variant="h5" align="center">
        {t(title)}
      </Typography>
      <Typography variant="body2" align="center" className={classes.description}>
        {t(description)}
      </Typography>
    </Box>
  );
};

Feature.defaultProps = {
  boxProps: {},
  valueProps: {},
  accentPosition: undefined,
};

Feature.propTypes = {
  value: PropTypes.string.isRequired,
  accentPosition: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  boxProps: PropTypes.object,
  valueProps: PropTypes.object,
};

export { Feature };

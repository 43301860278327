import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';

export const useCommonStyles = makeStyles({
  dFlex: {
    display: 'flex',
  },
  // Spacing
  py1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  py2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  py3: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  px1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  px2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  px3: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pl1: {
    paddingLeft: theme.spacing(1),
  },
  pl2: {
    paddingLeft: theme.spacing(2),
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  pr1: {
    paddingRight: theme.spacing(1),
  },
  pr2: {
    paddingRight: theme.spacing(2),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pr12: {
    paddingRight: theme.spacing(12),
  },
  mAuto: {
    margin: 'auto',
  },
  ml1: {
    marginLeft: `${theme.spacing(1)}px !important`,
  },
  ml2: {
    marginLeft: `${theme.spacing(2)}px !important`,
  },
  ml3: {
    marginLeft: `${theme.spacing(3)}px !important`,
  },
  ml4: {
    marginLeft: `${theme.spacing(4)}px !important`,
  },
  ml5: {
    marginLeft: `${theme.spacing(5)}px !important`,
  },
  ml6: {
    marginLeft: `${theme.spacing(6)}px !important`,
  },
  ml9: {
    marginLeft: `${theme.spacing(9)}px !important`,
  },
  ml10: {
    marginLeft: `${theme.spacing(10)}px !important`,
  },
  mr1: {
    marginRight: `${theme.spacing(1)}px !important`,
  },
  mr2: {
    marginRight: `${theme.spacing(2)}px !important`,
  },
  mr3: {
    marginRight: `${theme.spacing(3)}px !important`,
  },
  mr4: {
    marginRight: `${theme.spacing(4)}px !important`,
  },
  mr5: {
    marginRight: `${theme.spacing(5)}px !important`,
  },
  mr6: {
    marginRight: `${theme.spacing(6)}px !important`,
  },

  mt1: {
    marginTop: `${theme.spacing(1)}px !important`,
  },
  mt2: {
    marginTop: `${theme.spacing(2)}px !important`,
  },
  mt3: {
    marginTop: `${theme.spacing(3)}px !important`,
  },
  mt4: {
    marginTop: `${theme.spacing(4)}px !important`,
  },
  mt5: {
    marginTop: `${theme.spacing(5)}px !important`,
  },
  mt6: {
    marginTop: `${theme.spacing(6)}px !important`,
  },
  mt7: {
    marginTop: `${theme.spacing(7)}px !important`,
  },
  mt10: {
    marginTop: `${theme.spacing(10)}px !important`,
  },
  mt12: {
    marginTop: `${theme.spacing(12)}px !important`,
  },
  mb1: {
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  mb2: {
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  mb3: {
    marginBottom: `${theme.spacing(3)}px !important`,
  },
  mb4: {
    marginBottom: `${theme.spacing(4)}px !important`,
  },
  mb10: {
    marginBottom: `${theme.spacing(10)}px !important`,
  },

  pt1: {
    paddingTop: theme.spacing(1),
  },

  pt0: {
    paddingTop: `${0}px !important`,
  },

  mt0: {
    marginTop: `${0}px !important`,
  },
  pb0: {
    paddingBottom: `${0}px !important`,
  },

  mb0: {
    marginBottom: `${0}px !important`,
  },
  // Borders
  noBorderSelect: {
    '& fieldset': {
      border: 'none',
    },
  },
  noBorder: {
    border: 'none !important',
  },
  radius5: {
    borderRadius: 5,
  },
  roundedBottom: {
    borderBottomRightRadius: '1rem',
    borderBottomLeftRadius: '1rem',
  },
  // Text
  fontSize1: {
    fontSize: '0.75rem',
  },
  fontSize2: {
    fontSize: '0.875rem',
  },
  fontSize3: {
    fontSize: '1rem',
  },
  leading3: {
    lineHeight: '0.75rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  semibold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },

  // Shadows
  shadowSmall: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  },

  // General
  defaultMenuItem: {
    color: theme.palette.common.veryDarkGray,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorDefault: {
    cursor: 'default !important',
  },
  hidden: {
    display: 'none !important',
  },
  invisible: {
    visibility: 'hidden',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
  placeHolder: {
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.common.gray,
      opacity: 1,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  textUpperCase: {
    textTransform: 'uppercase',
  },
  alertText: {
    color: theme.palette.common.alertError,
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
});

import { ShipmentItemSubType } from '../types/shipments/shipmentItem';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { phoneRegExp } from 'shared/constants/regExp';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';

export const sizeIsRequired = (typeId) => {
  return (
    typeId.toString() !== ShipmentItemSubType.FullTruckLoad.toString() &&
    typeId.toString() !== ShipmentItemSubType.LDM.toString()
  );
};

export const ldmIsRequired = (typeId) => {
  return typeId.toString() === ShipmentItemSubType.LDM.toString();
};

export const hmToMinutes = (hm: string | null): number => {
  const [hours = 0, minutes = 0] = (hm ?? '00:00').split(':');
  return +hours * 60 + +minutes;
};

export const validateTimes = (
  earliestDate: string,
  earliestTime: string,
  latestDate: string,
  latestTime: string,
): boolean => {
  const earliest = addMinutes(parseISO(earliestDate), hmToMinutes(earliestTime));
  const latest = addMinutes(parseISO(latestDate), hmToMinutes(latestTime));
  return earliest <= latest;
};

export const useNewShipmentValidation = () => {
  const { t } = useTranslation();

  const companyContactValidationSchema = Yup.object({
    addressLine1: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    city: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    companyName: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    contactPersonName: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    country: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    email: Yup.string()
      .email(t('VALIDATION.INVALID_EMAIL'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    notes: Yup.string().nullable(),
    phone: Yup.string()
      .matches(phoneRegExp, t('VALIDATION.INVALID_PHONE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    postCode: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    regNr: Yup.string()
      .min(6, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
  })
    .notRequired()
    .default(undefined);

  const loadingContactValidationSchema = Yup.object({
    addressLine1: Yup.string()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    city: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    companyName: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    contactPersonName: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    country: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    email: Yup.string()
      .email(t('VALIDATION.INVALID_EMAIL'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    notes: Yup.string().nullable(),
    phone: Yup.string()
      .matches(phoneRegExp, t('VALIDATION.INVALID_PHONE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    postCode: Yup.string().trim().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
  })
    .notRequired()
    .default(undefined);

  const dangerousGoodsInfoValidationSchema = Yup.array().of(
    Yup.object().shape({
      classVal: Yup.string().required(),
      pg: Yup.string().nullable(),
      properShippingName: Yup.string().nullable(),
      un: Yup.string().required(),
    }),
  );

  const shipmentDetailsRowValidationSchema = Yup.object().shape({
    dangerousGoods: Yup.boolean().nullable(),
    dangerousGoodsInfo: dangerousGoodsInfoValidationSchema.when('dangerousGoods', {
      is: true,
      then: dangerousGoodsInfoValidationSchema,
      otherwise: dangerousGoodsInfoValidationSchema.nullable(),
    }),
    height: Yup.string()
      .nullable()
      .when('typeId', {
        is: () => true,
        then: Yup.string().required(),
      }),
    ldm: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => ldmIsRequired(typeId),
        then: Yup.string().required(),
      }),
    length: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => sizeIsRequired(typeId),
        then: Yup.string().required(),
      }),
    productDescription: Yup.string().nullable(),
    quantityId: Yup.string().required(),
    temperature: Yup.string()
      .nullable()
      .when('coldFrozen', {
        is: (value: string) => !!value,
        then: Yup.string().required(),
      }),
    temperatureMax: Yup.string()
      .nullable()
      .when('coldFrozen', {
        is: (value: string) => !!value,
        then: Yup.string().required(),
      }),
    typeId: Yup.string().required(),
    weight: Yup.string().required(),
    width: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => sizeIsRequired(typeId),
        then: Yup.string().required(),
      }),
  });

  const transportValidationSchema = Yup.object().shape({
    earliestArrivalDate: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    earliestPickupDate: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    earliestArrivalTime: Yup.string().nullable(),
    earliestPickupTime: Yup.string().nullable(),
    latestArrivalDate: Yup.string().when('latestArrivalTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupDate: Yup.string().when('latestPickupTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupTime: Yup.string().nullable(),
    latestArrivalTime: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    receiver: loadingContactValidationSchema,
    sender: loadingContactValidationSchema,
    shipmentDetailsRows: Yup.array()
      .of(shipmentDetailsRowValidationSchema)
      .min(1, t('VALIDATION.AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT')),
    selectedPriceOffer: Yup.string().when('offeredPrices', {
      is: (value?) => value?.length,
      then: Yup.string().required(t('SHIPMENT.PICK_ONE_PRICE_ERROR')),
      otherwise: Yup.string().nullable(),
    }),
  });

  const confirmValidationSchema = Yup.object().shape({
    earliestArrivalDate: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    earliestPickupDate: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    earliestArrivalTime: Yup.string().nullable(),
    earliestPickupTime: Yup.string().nullable(),
    latestArrivalDate: Yup.string().when('latestArrivalTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupDate: Yup.string().when('latestPickupTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupTime: Yup.string().nullable(),
    latestArrivalTime: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    company: companyContactValidationSchema,
    receiver: loadingContactValidationSchema,
    sender: loadingContactValidationSchema,
    shipmentDetailsRows: Yup.array()
      .of(shipmentDetailsRowValidationSchema)
      .min(1, t('VALIDATION.AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT')),
    selectedPriceOffer: Yup.string().when('offeredPrices', {
      is: (value?) => value?.length,
      then: Yup.string().required(t('SHIPMENT.PICK_ONE_PRICE_ERROR')),
      otherwise: Yup.string().nullable(),
    }),
  });

  return {
    transportValidationSchema,
    confirmValidationSchema,
    loadingContactValidationSchema,
    shipmentDetailsRowValidationSchema,
    dangerousGoodsInfoValidationSchema,
  };
};

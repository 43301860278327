import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { ReadMore } from 'components';
import { theme } from 'theme';
import { Typography } from '../CustomMaterial/Typography/Typography';

const useStyles = makeStyles({
  selectable: {
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    cursor: 'pointer',
  },
  chip: {
    backgroundColor: `${theme.palette.common.veryLightGrayAlt} !important`,
  },
  chipSelected: {
    backgroundColor: `${theme.palette.common.verySoftViolet} !important`,
    fontWeight: 700,
  },
});

export const PriceOffer = ({
  offeredPrice,
  selected,
  onSelected,
  showError,
  showFullComment = false,
  ...boxProps
}): ReactElement => {
  const { id, price, comment, transitTime, onTimeDelivery } = offeredPrice;
  const classes = useStyles();
  const { t } = useTranslation();

  const onPriceOfferSelected = useCallback(() => {
    if (onSelected) {
      onSelected(id);
    }
  }, [id, onSelected]);

  let boxBorderColor = selected
    ? theme.palette.common.strongViolet
    : theme.palette.common.lightGray;
  if (showError) boxBorderColor = theme.palette.common.alertError;

  const commentBgColor = onSelected
    ? theme.palette.common.lightGrayishVioletAlt3
    : theme.palette.common.veryLightGrayAlt2;

  return (
    <Box
      border={1}
      borderColor={boxBorderColor}
      bgcolor={selected ? theme.palette.common.lightGrayishVioletAlt4 : undefined}
      borderRadius={16}
      boxShadow={selected ? 3 : undefined}
      paddingTop={8}
      paddingBottom={!comment ? 9 : 0}
      width={288}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      onClick={selected ? undefined : onPriceOfferSelected}
      className={onSelected ? classes.selectable : undefined}
      {...boxProps}
    >
      <Box marginTop={4} display="flex" alignItems="baseline">
        <Typography
          variant="h3"
          fontWeight={selected ? 'black' : 'regular'}
          customColor={theme.palette.common.black}
        >
          {price}
        </Typography>
        <Typography marginLeft={1} variant="body1">
          €
        </Typography>
      </Box>
      <Box marginTop={2} marginBottom={6} textAlign="center">
        <Typography variant="caption" fontWeight="bold" marginBottom={0.5} component="p">
          {transitTime && transitTime !== '0'
            ? `${transitTime} ${t('PRICE_REQUEST.DAYS_DELIVERY')}`
            : ''}
          {transitTime && transitTime === '0' ? `${t('PRICE_REQUEST.SAME_DAY_DELIVERY')}` : ''}
        </Typography>
        <Typography variant="caption" fontWeight="bold" component="p">
          {onTimeDelivery ? `${t('PRICE_REQUEST.ON_TIME_DELIVERY')} >${onTimeDelivery}%` : ''}
        </Typography>
      </Box>
      {onSelected && (
        <Typography
          variant="caption"
          component="p"
          link={!selected}
          marginBottom={comment ? 0 : 10}
        >
          {t(selected ? 'PRICE_REQUEST.THIS_OPTION_IS_CHOSEN' : 'PRICE_REQUEST.CHOOSE_THIS_OPTION')}
        </Typography>
      )}
      {comment && (
        <Box
          bgcolor={comment ? commentBgColor : undefined}
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={5.5}
          paddingX={4}
          paddingBottom={5}
          marginTop={4}
          width="100%"
        >
          <Typography variant="caption" textAlign="center">
            {showFullComment ? (
              comment
            ) : (
              <ReadMore dialogTitle={t('PRICE_REQUEST.COMMENT_RELATED_TO_PRICE')} lines={2}>
                {comment}
              </ReadMore>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

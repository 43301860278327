import { defaultLanguage, languages } from '../constants/languages';

const ensureNoDoubleSlash = (languagePath) => {
  return languagePath.startsWith('//') ? languagePath.replace('//', '/') : languagePath;
};

export const getLocalizedPath = ({ i18n, path }) => {
  const supportedLanguages = Object.values(languages);

  let languagePath = path;
  let languagePrefix = i18n.language !== defaultLanguage ? `/${i18n.language}` : '/';

  if (path === '/') {
    return languagePrefix;
  } else {
    for (const { code: lang } of supportedLanguages) {
      const languagePathWithoutQuery = languagePath.split('?')[0];
      if (
        languagePathWithoutQuery.startsWith(`/${lang}/`) ||
        languagePathWithoutQuery === `/${lang}`
      ) {
        if (lang !== i18n.language) {
          languagePath = languagePath.replace(`/${lang}`, languagePrefix);
        }
        return ensureNoDoubleSlash(languagePath);
      }
    }

    // ensure that path is prefixed with slash
    if (!languagePath.startsWith('/')) {
      languagePath = `/${languagePath}`;
    }

    return ensureNoDoubleSlash(`${languagePrefix}${languagePath}`);
  }
};

import React, { ReactElement, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { MinusIcon, PlusIcon } from 'components';
import { theme } from 'theme';
import { useCommonStyles } from 'shared/styles/common';
import Typography from '@material-ui/core/Typography';

export const QuantityEditor = ({
  step = 1,
  initialValue = 0,
  min = 0,
  max = 999,
  valueChanged,
  ...restProps
}): ReactElement => {
  const commonClasses = useCommonStyles();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (valueChanged) {
      valueChanged(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const changeValue = (newValue: number) => {
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  return (
    <Box
      width={70}
      height={32}
      bgcolor={theme.palette.common.darkCyan}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingY={1.5}
      paddingX={2}
      borderRadius={100}
      {...restProps}
    >
      <MinusIcon
        className={commonClasses.cursorPointer}
        onClick={() => changeValue(value - step)}
      />
      <Typography variant="body2" fontWeight="bold" color="textSecondary">
        {value}
      </Typography>
      <PlusIcon className={commonClasses.cursorPointer} onClick={() => changeValue(value + step)} />
    </Box>
  );
};

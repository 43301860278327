import { flatten } from 'lodash';
//
// Google Places API
//
export const usePlaces = () => {
  const mapGooglePlaceResultToLoadingContact = (placeResult: google.maps.places.PlaceResult) => {
    try {
      const result = {};

      if (!placeResult.address_components) {
        return null;
      }

      let streetNumber: string | null = null;
      let route: string | null = null;
      let administrativeAreaLevel1: string | null = null;

      placeResult.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name ?? component.short_name;
        } else if (component.types.includes('route')) {
          route = component.long_name ?? component.short_name;
        } else if (component.types.includes('locality')) {
          result.city = component.long_name ?? component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          administrativeAreaLevel1 = component.long_name ?? component.short_name;
        } else if (component.types.includes('country')) {
          result.country = component.short_name;
        } else if (component.types.includes('postal_code')) {
          result.postCode = (component.long_name ?? component.short_name).replace(' ', '');
        }
      });
      result.addressLine1 = `${route ?? ''} ${streetNumber ?? ''}`;

      // city isn't always present e.g. "Keri saar" - fall back to higher level(s) in this case.
      if (!result.city && administrativeAreaLevel1) {
        result.city = administrativeAreaLevel1;
      }

      const isCompany =
        placeResult.types?.includes('establishment') ||
        placeResult.types?.includes('point_of_interest');

      if (isCompany) {
        result.companyName = placeResult.name;
        result.phone = placeResult.international_phone_number;
      }

      if (placeResult.geometry?.location) {
        result.lat = `${placeResult.geometry?.location.lat() ?? ''}`;
        result.lng = `${placeResult.geometry?.location.lng() ?? ''}`;
      }

      return { ...result };
    } catch (_) {
      // Not a blocker issue, can be ignored. Would be good to log in the future to be able to improve parsing.
      return null;
    }
  };

  const fetchAddressFromGoogle = (placeId: string): Promise => {
    const poweredByGoogleDiv = document.getElementById('Logtech HoldingPoweredByGoogleDiv');
    if (!poweredByGoogleDiv) {
      throw Error(
        'Maps attribution needed: https://developers.google.com/places/web-service/policies',
      );
    }

    const placeDetails = new google.maps.places.PlacesService(poweredByGoogleDiv);

    return new Promise((resolve, reject) => {
      placeDetails.getDetails({ placeId }, (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error(`Google places API returned status=[${status}]`));
        } else if (!result) {
          resolve(null);
        } else {
          resolve(mapGooglePlaceResultToLoadingContact(result));
        }
      });
    });
  };

  const fetchLoadingContactByPlaceId = (prediction): Promise => {
    if (prediction.source === 'Google') {
      return fetchAddressFromGoogle(prediction.placeId);
    }
    throw Error('Unknown address prediction search');
  };

  const fetchLoadingAddressSuggestionsFromGoogle = (query) => {
    if (!query) {
      return Promise.resolve([]);
    }
    const autocomplete = new google.maps.places.AutocompleteService();

    const request: google.maps.places.AutocompletionRequest = {
      input: query,
      // Tallinn:
      location: new google.maps.LatLng(59.4034465747615, 24.734107909974963),
      // ~Nordics:
      radius: 350000,
      types: ['establishment', 'geocode'],
    };

    return new Promise((resolve, reject) => {
      autocomplete.getPlacePredictions(request, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error(`Google places API getPlacePredictions returned status=[${status}]`));
        } else if (!predictions) {
          resolve([]);
        } else {
          resolve(
            predictions
              .filter((prediction) => prediction.place_id && prediction.description)
              .map((prediction) => ({
                placeId: prediction.place_id,
                description: prediction.description,
                source: 'Google',
                sourceTranslation: '',
              })),
          );
        }
      });
    });
  };

  const fetchLoadingAddressSuggestions = (query: string) => {
    return Promise.all([fetchLoadingAddressSuggestionsFromGoogle(query)])
      .then((arrayOfArrays) => {
        return flatten(arrayOfArrays);
      })
      .catch(() => {
        return [];
      });
  };
  return {
    fetchLoadingAddressSuggestionsFromGoogle,
    fetchLoadingContactByPlaceId,
    fetchLoadingAddressSuggestions,
  };
};

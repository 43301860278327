import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { theme } from 'theme';
import isNil from 'lodash/isNil';
import { makeStyles } from '@material-ui/styles';
import MaterialSelect from '@material-ui/core/Select';
import { MenuProps as MenuPropsType } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DropdownIcon } from 'components';
import { useCommonStyles } from 'shared/styles/common';
import { generateKey } from 'shared/functions/generateKey';
import { withFormikField } from 'shared/hocs/formik';
import { SelectItem } from '../MultiSelect/MultiSelect';

type StyleProps = {
  defaultSelected?: boolean,
  size?: 'small' | 'default',
  background?: 'opaque' | 'transparent',
  noBorder?: boolean,
  borderColor?: string,
};

const useNativeStyles = makeStyles({
  root: {
    color: ({ defaultSelected }: StyleProps) =>
      defaultSelected ? theme.palette.common.gray : theme.palette.common.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.06rem',
  },
  outlined: ({ noBorder, borderColor }: StyleProps) => ({
    paddingRight: '24px !important',
    '& ~ fieldset': {
      borderColor: `${borderColor} !important`,
      borderWidth: `${noBorder ? 0 : 1}px !important`,
    },
    '&:hover ~ fieldset': {
      borderColor: `${borderColor} !important`,
    },
  }),
  icon: {
    fill: theme.palette.common.white,
  },
});

const useStyles = makeStyles({
  dropDownIcon: {
    marginRight: theme.spacing(2.25),
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  select: ({ size, background }: StyleProps) => ({
    height: size === 'small' ? 27 : 32,
    backgroundColor: background === 'opaque' ? theme.palette.common.white : 'transparent',
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.common.alertError} !important`,
    },
  }),
  rotated: {
    transform: 'rotate(180deg)',
  },
  paper: {
    boxShadow: theme.shadows[1],
    maxHeight: '300px',
    width: '200px',
  },
});

const defaultValueFallback = -1;

const SelectComponent = ({
  data,
  variant = 'dark',
  background = 'transparent',
  borderColor = theme.palette.common.grayAlt,
  noBorder,
  className,
  classes: nativeCustomClasses,
  value,
  defaultValue = defaultValueFallback,
  defaultLabel,
  size = 'default',
  MenuProps,
  onOpen: onOpenCallback,
  onClose: onCloseCallback,
  children,
  ...props
}): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const [finalData, setFinalData] = useState([]);

  const defaultSelected = !isNil(defaultValue) && defaultValue === value;

  const nativeClasses = useNativeStyles({ defaultSelected, borderColor, noBorder });
  const classes = useStyles({ size, background });
  const commonClasses = useCommonStyles();

  const buildMenuProps = (): Partial<MenuPropsType> | undefined => {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 0,
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      PaperProps: {
        elevation: 0,
        className: clsx(classes.paper),
      },
      disableScrollLock: true,
      ...MenuProps,
    };
  };

  const [menuProps, setMenuProps] = useState(buildMenuProps());

  const onOpen = useCallback(
    (event: React.ChangeEvent<Record<string, unknown>>) => {
      if (!expanded) {
        setExpanded(true);
      }
      if (onOpenCallback) {
        onOpenCallback(event);
      }
    },
    [expanded, setExpanded, onOpenCallback],
  );

  const onClose = useCallback(
    (event: React.ChangeEvent<Record<string, unknown>>) => {
      if (expanded) {
        setExpanded(false);
      }
      if (onCloseCallback) {
        onCloseCallback(event);
      }
    },
    [expanded, setExpanded, onCloseCallback],
  );

  useEffect(() => {
    if (data) {
      const defaultItem: SelectItem = {
        label: defaultLabel || '',
        value: defaultValue,
      };
      setFinalData(
        defaultValue !== undefined && defaultValue !== defaultValueFallback
          ? [defaultItem, ...data]
          : data,
      );
    }
  }, [data, defaultValue, defaultLabel]);

  useEffect(() => {
    setMenuProps(buildMenuProps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MenuProps]);

  const iconComponent = useCallback(
    () => (
      <DropdownIcon
        variant={variant}
        className={clsx(classes.dropDownIcon, expanded && classes.rotated)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant, expanded],
  );

  return (
    <MaterialSelect
      classes={{ ...nativeClasses, ...nativeCustomClasses }}
      className={clsx(className, classes.select)}
      IconComponent={iconComponent}
      MenuProps={menuProps}
      value={value}
      onOpen={onOpen}
      onClose={onClose}
      {...props}
    >
      {finalData
        ? finalData.map(({ label, value: menuItemValue }, index) => (
            <MenuItem
              key={generateKey(index, `${label}_menu_item`)}
              value={menuItemValue}
              className={clsx(menuItemValue === defaultValue && commonClasses.defaultMenuItem)}
            >
              {label}
            </MenuItem>
          ))
        : children}
    </MaterialSelect>
  );
};

const SelectFormik = withFormikField(SelectComponent);
export { SelectFormik };

import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import { Indicator } from '../../Indicator/Indicator';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  indicatorWrapper: {
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(4),
    },
  },
});

export const OrderStep = ({ step, allSteps, hideBorder, children, ...restProps }): ReactElement => {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%">
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        marginRight={12.5}
        className={classes.indicatorWrapper}
      >
        <Indicator
          active
          size={40}
          icon={
            <Typography variant="h6" color="textSecondary">
              {step}/{allSteps}
            </Typography>
          }
        />
        {!hideBorder && (
          <Box
            bgcolor={theme.palette.common.strongViolet}
            height="100%"
            width={3}
            color={theme.palette.common.strongViolet}
          />
        )}
      </Box>
      <Box width="100%" paddingTop={2} paddingBottom={5} {...restProps}>
        {children}
      </Box>
    </Box>
  );
};
